import { apiEndpoint } from "./";

const api = {
  login: apiEndpoint + "auth/login",
  register: apiEndpoint + "auth/register",

  getUser: apiEndpoint + "users/",
  getCurrentUser: apiEndpoint + "users/me",
  followUser: apiEndpoint + "users/followUser",
  updateUser: apiEndpoint + "users/update",

  uploadAvatar: apiEndpoint + "profile/uploadAvatar",
  deleteAvatar: apiEndpoint + "profile/deleteAvatar",
  uploadBanner: apiEndpoint + "profile/uploadBanner",
  deleteBanner: apiEndpoint + "profile/deleteBanner",

  newArt: apiEndpoint + "posts/art",
  getPost: apiEndpoint + "posts/",
  deletePost: apiEndpoint + "posts/deletePost",
  //likePost: apiEndpoint + "posts/likePost",

  getArt: apiEndpoint + "arts/",
  likeArt: apiEndpoint + "arts/like",
  deleteArt: apiEndpoint + "arts/deleteArt",
  updateArt: apiEndpoint + "arts/update",

  getTimelinePosts: apiEndpoint + "timeline/",

  addComment: apiEndpoint + "comments/",
  deleteComment: apiEndpoint + "comments/deleteComment",
};

export default api;
