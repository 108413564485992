import React from "react";
import styled from "styled-components";
import { siteAddress } from "../../config";

const TopArtistsCard = ({ name, img }) => {
  return (
    <div className="col-6 mx-auto px-1" style={{ width: "180px" }}>
      <Card className="card">
        <CardImage>
          <img
            src={siteAddress + "images/userImages/" + img}
            className="card-img-top"
            alt="..."
          />
        </CardImage>
        <div className="card-body px-0 py-1">
          <Cardtext className="card-text">@{name}</Cardtext>
        </div>
      </Card>
    </div>
  );
};
const Card = styled.div`
  border-color: var(--color-gold);
`;
const CardImage = styled.div`
  width: 100%;
  height: 180px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0.25rem 0.25rem 0 0;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
  }
`;
const Cardtext = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export default TopArtistsCard;
