import React from "react";
import styled from "styled-components";

const About = ({ user }) => {
  return (
    <div className="row m-0 p-0 px-2">
      <div className="col-12 mt-2 shadow">
        {user.bio && <BioText>{user.bio}</BioText>}
      </div>
    </div>
  );
};

const BioText = styled.p`
  white-space: pre-line;
`;
export default About;
