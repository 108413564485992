import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../config/styles/colors";

const ProfileNavitem = ({ title, subtitle, to }) => {
  return (
    <Navitem to={to} subtitle={subtitle} exact={true}>
      {subtitle ? <span>{subtitle}</span> : <span></span>}
      <span>{title}</span>
      <span></span>
    </Navitem>
  );
};

const Navitem = styled(NavLink)`
  position: relative;
  padding: 0.4rem 1.5rem;
  border: none;
  background-color: initial;
  border-radius: 0.2rem;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: #dee2e6a1;
    color: ${colors.purple};
  }
  span {
    display: block;
  }
  span:first-child {
    font-size: 10pt;
    font-weight: 600;
    margin-bottom: -5px;
    height: 15pt;
  }

  span:last-child {
    width: 0;
    margin-left: 50%;
    transition: width 0.5s ease-in, margin 0.5s ease-in;
  }
  &.active > span:last-child {
    height: 2pt;
    width: 100%;
    background-color: ${colors.purple};
    position: absolute;
    left: 0;
    margin-left: 0;
    bottom: -0.4rem;
  }
`;
export default ProfileNavitem;
