import React from "react";
import styled from "styled-components";

const ProfliePageContainer = ({ children }) => {
  return (
    <Container className="row m-0 mt-2" id="profile_container">
      {children}
    </Container>
  );
};

const Container = styled.div`
  min-height: 400px;
  box-shadow: 0 -0.2rem 0.5rem -0.4rem rgba(0, 0, 0, 0.89) !important;
`;

export default ProfliePageContainer;
