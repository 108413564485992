import React, { Fragment } from "react";
import { siteAddress } from "../../config";
import Alert from "./alert";

const ImageRadio = ({
  name,
  id,
  label,
  error,
  imageUrl,
  required = false,
  ...rest
}) => {
  return (
    <Fragment>
      <input
        className="btn-check"
        type="radio"
        id={id}
        name={name}
        autoComplete="off"
        {...rest}
      />
      <label className="image-check" htmlFor={id}>
        <img
          className="img-fluid shadow-hard"
          src={siteAddress + "assets/" + imageUrl}
          alt="img"
        />
        <span className="image-check-label text-center">{label}</span>
      </label>
      {error && <Alert title="Oops" type="danger" message={error} />}
    </Fragment>
  );
};

export default ImageRadio;
