import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import Form from "../../form/form";
import { toast } from "react-toastify";
import { Redirect } from "react-router";
import http from "../../../services/httpService";
import api from "../../../config/api";
import Page from "../../common/Page";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import auth from "../../../services/authService";
import Loading from "../../common/loading";
import schemas from "../../../config/schemas";
import AppBody from "../../common/AppBody";
import Alert from "../../form/alert";

class register extends Form {
  state = {
    currentPage: 1,
    data: {
      accountType: "",
      categories: [],
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      gender: "",
    },
    errors: {},
    loading: false,
  };
  schema = {
    accountType: schemas.accountType,
    categories: schemas.categories,
    username: schemas.username,
    email: schemas.email,
    gender: schemas.gender,
    password: schemas.password,
    confirmPassword: schemas.confirmPassword,
  };
  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      const formData = { ...this.state.data };
      delete formData.confirmPassword;

      const { headers } = await http.post(api.register, formData);
      //auth.loginWithJwt(headers["x-auth-token"]);
      this.setState({ loading: false });

      //window.location = "/posts";
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        if (ex.response.data instanceof Object) {
          this.setState({ errors: ex.response.data });
        } else {
          toast.error("Error: " + ex.response.data);
        }
      }
      this.setState({ loading: false });
    }
  };
  customHandleChange(input, prevErrors) {
    if (input.name === "confirmPassword") {
      const errors = { ...prevErrors };
      if (input.value !== this.state.data.password)
        errors[input.name] = "Passwords do not match!";
      else delete errors[input.name];
      this.setState({ errors: errors });
    }
    if (input.name === "password") {
      const errors = { ...prevErrors };
      if (errors["password"]) return;
      if (
        input.value !== this.state.data.confirmPassword &&
        this.state.data.confirmPassword !== ""
      ) {
        errors["confirmPassword"] = "Passwords do not match!";
      } else delete errors["confirmPassword"];
      this.setState({ errors: errors });
    }
  }
  changePage(pageNumber) {
    if (this.state.loading) return;
    if (pageNumber < 1 || pageNumber > 3) return;
    if (pageNumber > 1 && !this.validFirstPage()) return;
    if (pageNumber === 3 && this.state.data.categories.length < 1) return;

    const indicators = document.querySelectorAll(
      ".registeStepindicator.active"
    );
    if (indicators.length > 0) {
      for (let i = 0; i < indicators.length; i++) {
        indicators[i].classList.remove("active");
      }
    }
    const nextIndicator = document.getElementById("rSi" + pageNumber);
    nextIndicator.classList.add("active");
    this.setState({ currentPage: pageNumber });
  }
  validFirstPage() {
    return !this.validateProperty({
      name: "accountType",
      value: this.state.data.accountType,
    });
  }
  render() {
    if (this.props.currentUser) return <Redirect to="/" />;
    const page = this.state.currentPage;
    return (
      <Page title="Register">
        <Helmet></Helmet>
        <AppBody>
          <Loading
            visible={this.state.loading}
            showLottie={false}
            style={{ opacity: "0.4" }}
          />

          <section>
            <div className="row m-0 mt-2 px-2 justify-content-center text-center">
              <div
                id="rSi1"
                className="my-auto registeStepindicator col-4 col-md-3 col-lg-2 p-2 active"
                onClick={(e) => this.changePage(1)}
              >
                Lets get started...
              </div>
              <div
                id="rSi2"
                className="my-auto registeStepindicator col-4 col-md-3 col-lg-2 p-2"
                onClick={(e) => this.changePage(2)}
              >
                Categories
              </div>
              <div
                id="rSi3"
                className="my-auto registeStepindicator col-4 col-md-3 col-lg-2 p-2"
                onClick={(e) => this.changePage(3)}
              >
                Details
              </div>
            </div>
          </section>
          <section>
            <div className="container p-0">
              <form
                onSubmit={this.handleSubmit}
                className="row m-0 px-2 mx-auto mt-3 justify-content-center"
              >
                <div className="col-12 col-md-6 d-none">
                  <Alert
                    type="success"
                    title="Success"
                    message="Your account has been created, check your email for confirmation"
                  />
                </div>
                <div className="container p-0">
                  {page === 1 && <Page1 form={this} />}
                  {page === 2 && <Page2 form={this} />}
                  {page === 3 && <Page3 form={this} />}
                </div>
              </form>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});
export default connect(mapStateToProps)(register);
