import React from "react";
import styled from "styled-components";
import colors from "../../config/styles/colors";
import * as buttonTypes from "../../config/styles/buttonTypes";
import * as buttonTypesOutlined from "../../config/styles/buttonTypesOutline";

const AppButton = ({
  title,
  type = "button",
  className,
  children,
  ...rest
}) => {
  const classList = className ? "btn px-3 py-2 " + className : "btn px-3 py-2";
  return (
    <Button type={type} className={classList} {...rest}>
      {children ? children : title}
    </Button>
  );
};
const Button = styled.button`
  text-transform: uppercase !important;
  border-radius: ${({ rounded }) =>
    rounded ? ".25rem !important" : "0 !important"};
  border-width: 2px;
  box-shadow: none !important;
  width: ${({ filled }) => (filled ? "inherit" : "max-content")};
  height: ${({ filled }) => (filled ? "" : "max-content")};
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  color: ${colors.primaryContra};
  ${({ color, outlined }) =>
    outlined
      ? color && buttonTypesOutlined[color]
      : color && buttonTypes[color]}
`;

export default AppButton;
