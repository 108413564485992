import React from "react";
import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router";
import Form from "../form/form";
import Page from "../common/Page";
import Loading from "../common/loading";
import schemas from "../../config/schemas";
import Alert from "../form/alert";
import auth from "../../services/authService";
import AppBody from "../common/AppBody";
import { connect } from "react-redux";

class login extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    loading: false,
  };
  schema = {
    email: schemas.email,
    password: schemas.password,
  };
  doSubmit = async () => {
    try {
      this.setState({ loading: true });

      const formData = { ...this.state.data };
      await auth.login(formData);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.all = ex.response.data;
        this.setState({ errors: errors, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  };
  render() {
    if (this.props.currentUser) return <Redirect to="/" />;
    const buttonLabel = this.state.loading ? (
      <i className="fas fa-spinner fa-spin"></i>
    ) : (
      "Login"
    );
    const error = this.state.errors.all || false;
    return (
      <Page title="Login">
        <Helmet></Helmet>

        <AppBody>
          <Loading
            visible={this.state.loading}
            showLottie={false}
            style={{ opacity: "0.4" }}
          />

          <section>
            <div className="container-fluid">
              <div className="row m-0">
                <div className="col"></div>
                <div className="col-auto">
                  <form
                    onSubmit={this.handleSubmit}
                    className="row m-0 px-2 mt-3 justify-content-center"
                    style={{ maxWidth: "710px" }}
                  >
                    <div className="col-12">
                      {error && (
                        <Alert title="Oops" type="danger" message={error} />
                      )}
                    </div>
                    <div className="col-12">
                      {this.renderFloatingInput("email", "Email", true)}
                    </div>

                    <div className="col-12">
                      {this.renderFloatingInput(
                        "password",
                        "Password",
                        true,
                        "password"
                      )}
                    </div>
                    <div className="col-12 ">
                      {this.rendersubmit(buttonLabel)}
                    </div>
                  </form>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});
export default connect(mapStateToProps)(login);
