import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import httpService from "../../services/httpService";
import api from "../../config/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

const EditDropDown = ({
  item,
  deleteAction,
  deleteinProgress,
  type = "post",
  editLink = "",
}) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  if (!currentUser) return null;
  if (!item) return null;
  if (!item.author) return null;
  if (!item.author._id) return null;
  if (item.author._id !== currentUser._id) return null;

  const deleteItem = async () => {
    if (!window.confirm(`Are you sure to delete this ${type}?`)) return;
    try {
      deleteinProgress(true);
      let response = false;
      if (type === "art") {
        const artId = item._id;
        response = await httpService.post(api.deleteArt, {
          artId: artId,
        });
      } else {
        const postId = item._id;
        response = await httpService.post(api.deletePost, {
          postId: postId,
        });
      }

      if (response.data !== "ok") {
        console.log("error code: 18563E");
        deleteinProgress(false);
        return;
      }
      deleteAction(true);
      deleteinProgress(false);
    } catch (ex) {
      toast.error("Error: " + ex.response.data);
      deleteinProgress(false);
    }
  };
  return (
    <EditDropDownBox className="dropdown">
      <EditDropDownBtn
        className="btn btn-light dropdown-toggle"
        type="button"
        id={`dropdownMenuButton-${item._id}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      ></EditDropDownBtn>
      <ul
        className="dropdown-menu"
        aria-labelledby={`dropdownMenuButton-${item._id}`}
      >
        <li>
          <Link to={editLink}>
            <span className="dropdown-item cursor-pointer">Edit {type}</span>
          </Link>
        </li>
        <li>
          <span className="dropdown-item" onClick={deleteItem}>
            Delete {type}
          </span>
        </li>
      </ul>
    </EditDropDownBox>
  );
};
const EditDropDownBox = styled.div`
  position: absolute;
  top: -1.2rem;
  right: -1rem;
  > * {
    cursor: pointer !important;
  }
`;
const EditDropDownBtn = styled.button`
  background-color: transparent !important;
  border: none;

  :hover {
    background-color: transparent !important;
  }
`;
const Link = styled(NavLink)`
  text-decoration: none;
`;
export default EditDropDown;
