import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../../../features/userSlice";
import api from "../../../config/api";
import schemas from "../../../config/schemas";
import http from "../../../services/httpService";
import Form from "../../form/form";
import Loading from "../../common/loading";

class categorySetting extends Form {
  state = {
    data: { categories: [] },
    errors: {},
    loading: false,
  };

  schema = {
    categories: schemas.categories,
  };
  source = http.source();
  unmounting = false;

  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }
  componentDidMount() {
    const currentUser = this.props.currentUser;
    if (currentUser.categories && !this.state.emptyData) {
      const categories = [...currentUser.categories];
      this.setData({ categories: categories });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentUser !== prevProps.currentUser) {
      if (!this.state.emptyData && this.props.currentUser.categories) {
        const categories = [...this.props.currentUser.categories];
        this.setData({ categories: categories });
      }
    }
  }

  setData(d) {
    const emptyData = JSON.parse(JSON.stringify(d));
    const data = JSON.parse(JSON.stringify(d));
    this.setState({ data, emptyData });
  }

  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      const categories = [...this.state.data.categories];

      const data = {
        field: "categories",
        value: categories,
      };
      const { data: user } = await http.put(api.updateUser, data, {
        cancelToken: this.source.token,
      });

      this.props.updateUser(user);
      this.setState({ emptyData: { categories: categories }, loading: false });
    } catch (ex) {
      if (this.unmounting) return;
      this.setState({
        firstLoad: false,
      });
    }
  };
  render() {
    if (!this.state.emptyData)
      return (
        <Loading
          visible={true}
          style={{ backgroundColor: "rgba(255,255,255,0)" }}
        />
      );
    const { data } = this.state;
    return (
      <section className="position-relative">
        <Loading
          fixed={false}
          visible={this.state.loading}
          style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
        />
        <form
          onSubmit={this.handleSubmit}
          className="row m-0 justify-content-center mx-auto"
        >
          <h2>Edit Categories</h2>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Painting",
              "Painting",
              true,
              "cat-painting.png"
            )}
          </div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Drawing",
              "Drawing",
              true,
              "cat-drawing.png"
            )}
          </div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Sculpture",
              "Sculpture",
              true,
              "cat-scupture.png"
            )}
          </div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Photography",
              "Photography",
              true,
              "cat-photography.png"
            )}
          </div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Prints",
              "Prints",
              true,
              "cat-prints.png"
            )}
          </div>
          <div className="w-100 d-none d-lg-block d-xl-block"></div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Digital Art",
              "Digital Art",
              true,
              "cat-digitalart.png"
            )}
          </div>
          <div className="col-4 col-md-3 col-lg-2 p-0">
            {this.renderImageCheckbox(
              "categories",
              "Installation",
              "Installation",
              true,
              "cat-installation.jpg"
            )}
          </div>
          <div className="col-12"></div>
          <div className="col-8">
            {this.rendersubmit(
              "Save",
              !data.categories.length || this.changed()
            )}
          </div>
        </form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});
const mapDispatchToProps = { updateUser };
export default connect(mapStateToProps, mapDispatchToProps)(categorySetting);
