import React, { Fragment } from "react";
import Footer from "./Footer";
import NavBar from "../navigation/navBar";

const Page = ({ title, children, ...rest }) => {
  document.title = title + " | Artisle";
  return (
    <Fragment>
      <NavBar />
      <div className="row m-0" style={{ minWidth: "0", flexGrow: 1 }} {...rest}>
        {children}
      </div>

      <Footer />
    </Fragment>
  );
};

export default Page;
