import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import InfiniteScroll from "../../common/infiniteScroll";
import AppButton from "../../common/AppButton";
import PostCard from "../../common/postCard";

const Posts = ({ posts, userId }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const history = useHistory();

  function renderPosts(posts) {
    if (!posts) return "No posts to display";
    if (posts.length < 1)
      return (
        <div className="col-12 order-3">
          <h5 className="text-center text-muted">No posts to display</h5>
        </div>
      );
    return (
      <InfiniteScroll
        items={posts}
        chunkLength={9}
        display={(post) => {
          return <PostCard key={post} postId={post} />;
        }}
        end={() => {
          return (
            <div className="col-12 order-3">
              <h5 className="text-center text-muted">No more posts</h5>
            </div>
          );
        }}
      />
    );
  }

  return (
    <>
      {currentUser && currentUser._id === userId && (
        <div className="col col-md-8 col-xl-6 text-center mt-2 d-none">
          <AppButton
            color="dimGrey"
            title="New Post"
            filled
            outlined
            onClick={() => history.push("/new-art")}
          />
        </div>
      )}
      <div className="col-12"></div>
      <div className="col col-md-8 col-xl-6">{renderPosts(posts)}</div>
    </>
  );
};

export default Posts;
