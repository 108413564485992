import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import AppBody from "../../common/AppBody";
import Page from "../../common/Page";
import CategorySetting from "./categorySetting";
import BioSetting from "./bioSetting";

class settings extends Component {
  render() {
    return (
      <Page title="Setting">
        <AppBody>
          <div className="container p-0 shadow">
            <Container className="row m-0 justify-content-center shadow">
              <div className="col-12 pt-2">
                <CategorySetting />
              </div>
              <hr />
              <div className="col-12 pt-2">
                <BioSetting />
              </div>
            </Container>
          </div>
        </AppBody>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});

const Container = styled.div`
  box-shadow: 0 -0.2rem 0.5rem -0.4rem rgba(0, 0, 0, 0.15),
    0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin-top: 0.8rem !important;
`;
export default connect(mapStateToProps)(settings);
