import React, { useState } from "react";

const Alert = ({ title, message, type = "info" }) => {
  const [visible, setVisible] = useState(true);

  const dismiss = (e) => {
    const target = e.target.parentNode;
    target.classList.toggle("show");
    setTimeout(() => {
      setVisible(false);
    }, 500);
  };

  if (!visible) return null;
  return (
    <div
      className={"alert alert-" + type + " alert-dismissible fade show"}
      role="alert"
    >
      <strong>{title}</strong> {message}
      <button
        type="button"
        className="btn-close"
        onClick={dismiss}
        aria-label="Close"
      ></button>
    </div>
  );
};

export default Alert;
