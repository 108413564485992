import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { siteAddress } from "../../config";
import { device } from "../../config/styles/devicesStyle";
import EditDropDown from "./editDropDown";
import Avatar from "./Avatar";
import Carousel from "./Carousel";

import { useSelector } from "react-redux";
import httpService from "../../services/httpService";
import api from "../../config/api";
import { socketFeatures, useSocket } from "../../features/socket/socket";
import { likeFunction } from "../../libs/likeFunction";
import { Helmet } from "react-helmet-async";
import { getPrettyDate } from "../../libs/dateFunctions";
import Loading from "./loading";
import { css } from "styled-components";
import ShareButton from "./shareButton";
const ArtCard = ({ artId, full = false }) => {
  const [currentArt, setCurrentArt] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.currentUser.data);
  const mounted = useRef(false);
  const socket = useSocket();

  useEffect(() => {
    getArt();
    mounted.current = true;
    return () => {
      source.cancel();
      if (currentUser && currentArt) {
        socketFeatures("UNSUBTODOC", "art-" + artId);
      }
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, [artId]);

  const prettyDate = useMemo(
    () => getPrettyDate(currentArt.createdAt),
    [currentArt.createdAt]
  );
  const source = httpService.source();

  const getArt = async () => {
    try {
      const { data: art } = await httpService.get(api.getArt + artId, {
        cancelToken: source.token,
      });

      if (currentUser && socket) {
        socketFeatures("SUBTODOC", "art-" + artId);

        socket.on("docChange", (data) => {
          dataUpdate(data);
        });
      }

      setCurrentArt(art);
      setLoading(false);
    } catch (ex) {
      if (!mounted.current) return;

      setCurrentArt(false);
      setLoading(false);
    }
  };
  const dataUpdate = (response) => {
    if (!mounted.current) return;
    if (response.document === "art" && response.documentId === artId) {
      if (response.updateAction === "updateDocumentTo") {
        setCurrentArt(response.updateDocumentTo);
      }
    }

    return;
  };

  const renderImages = (images) => {
    let imagesSrc = [];
    for (let index = 0; index < images.length; index++) {
      const image = images[index];

      imagesSrc.push(
        siteAddress + "images/posts/" + currentArt._id + "/" + image
      );
    }
    const element = document.getElementById("profile_container");
    if (full && element) {
      element.scrollIntoView();
    }
    return imagesSrc;
  };

  if (deleted)
    return (
      <Container className="shadow card">
        <CardText className="my-auto">Art deleted</CardText>
      </Container>
    );
  if (!currentArt && !loading)
    return (
      <Container className="shadow card">
        <CardText className="my-auto">Deleted</CardText>
      </Container>
    );
  if (!currentArt && loading)
    return (
      <Container className="shadow card">
        <Loading
          style={InProgress}
          visible={true}
          fixed={false}
          lottieStyle={LottieStyle}
        />
      </Container>
    );

  const likePost = async () => {
    try {
      await likeFunction(currentUser, currentArt, "art");
    } catch (ex) {}
  };

  const liked = () => {
    if (!currentUser || !currentArt) return false;

    if (!currentArt.likes) return false;

    if (!currentArt.likes.includes(currentUser._id)) return false;
    return true;
  };
  const getSize = () => {
    if (!currentArt?.width || !currentArt?.height) return;
    const dimension = currentArt?.dimension || "cm";
    const { width, height } = currentArt;
    return `${width} x ${height} ${dimension}`;
  };
  const ownPost = currentUser
    ? currentUser._id === currentArt.author._id
    : false;
  return (
    <>
      {full && currentArt && (
        <Helmet>
          <meta
            property="og:url"
            content={`${siteAddress}api/arts/preview/${currentArt._id}`}
          />
          <meta
            property="og:title"
            content={
              currentArt.title +
              "by " +
              currentArt.author.username +
              " | Artisle"
            }
          />
          <meta
            property="og:description"
            content={
              "Check out this beautiful piece of art by " +
              currentArt.author.username
            }
          />
          <meta
            property="og:image"
            content={
              siteAddress +
              "images/posts/" +
              currentArt._id +
              "/" +
              currentArt.pictures[0]
            }
          />
          <meta property="og:image:type" content="image/webp" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
        </Helmet>
      )}

      <Container className="shadow card" full={full}>
        {loading && (
          <Loading
            style={InProgress}
            visible={true}
            fixed={false}
            lottieStyle={LottieStyle}
          />
        )}
        {currentArt && (
          <>
            <Header>
              <Box>
                <Avatar
                  img={currentArt.author.avatar}
                  alt={currentArt.author.username}
                />
              </Box>
              <Box>
                <UserName to={"/" + currentArt.author.username}>
                  {currentArt.author.username}
                </UserName>
                <DateText>{prettyDate}</DateText>
              </Box>

              <Box>
                <mark>{currentArt.category}</mark>

                <MediumText title={currentArt.medium}>
                  {currentArt.medium}
                </MediumText>
                <EditDropDown
                  type="art"
                  item={currentArt}
                  deleteAction={setDeleted}
                  deleteinProgress={setLoading}
                  editLink={`/edit-art/${currentArt._id}`}
                />
              </Box>
            </Header>
            <hr className="m-0" />
            {currentArt.title && (
              <>
                <CardText>{currentArt.title}</CardText>
              </>
            )}

            <Carousel
              id={
                "art-" +
                currentArt.author.username +
                "-" +
                currentArt._id.toString()
              }
              images={renderImages(currentArt.pictures)}
            />

            <ActionButtons>
              <ActionButton
                outlined
                color="rebeccaPurple"
                onClick={!ownPost ? likePost : null}
              >
                <>
                  <span>{currentArt.likes.length}</span>{" "}
                  <i
                    className={
                      liked() || ownPost ? "fas fa-heart" : "far fa-heart"
                    }
                  ></i>
                  <span>{liked() ? "Liked" : "Like"}</span>
                </>
              </ActionButton>

              <ShareButton id={currentArt._id} />

              <ActionButton>
                <i className="fas fa-star text-gold"></i> <span>Stars</span>
              </ActionButton>
            </ActionButtons>
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 150px;
  //margin-bottom: 1rem;

  display: flex;
  height: 100%;
  .carousel {
    flex-grow: 1;
  }
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
  padding: 0.1rem;

  border: 1px solid #dee2e6;
  img {
    width: 100%;
    object-fit: cover !important;
    max-height: ${({ full }) => (full ? "unset" : "400px !important")};
    height: 100%;
  }
  @media ${device.mobileM} {
    img {
      object-fit: cover !important;
      max-height: unset !important;
    }
  }
`;
const Header = styled.div`
  padding: 0.4rem 0.6rem;
  display: flex;
`;
const Box = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  :last-child {
    font-size: 13pt;
    min-width: 1px;
    min-height: 32px;
    margin: auto 0;
    margin-left: auto;
  }
  mark {
    color: rebeccapurple;
    font-weight: 600 !important;
  }
`;
const UserName = styled(NavLink)`
  font-size: 14pt;
  font-weight: 300 !important;
  margin-left: 0.4rem;
  color: inherit;
  text-decoration: none;
`;
const DateText = styled.span`
  font-size: 9pt;
  color: grey;
  margin-left: 0.4rem;
`;
const MediumText = styled.span`
  font-size: 9pt;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150%;
  position: absolute;
  bottom: -9pt;
  right: 0;
`;

const CardText = styled.p`
  padding: 0.4rem 70px;
  padding-bottom: 0;
  text-align: center;
  color: #6c757d !important;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #bfc2c6;
  padding: 0.1rem 0;
  font-size: 1rem;
`;
const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  margin: 0 !important;

  color: dimgray;
  a {
    color: inherit !important;
    text-decoration: none;
  }
  :hover {
    background-color: #ecf0f4;
    color: SlateGrey;
  }
  i {
    margin: auto;
    margin-right: 2px;
  }
  @media ${device.mobileM} {
    span:last-child {
      display: none;
    }
    padding: 0.4rem 1.2rem;
  }
`;

const InProgress = css`
  background-color: #ffffff98 !important;
  min-height: auto !important;
`;
const LottieStyle = css`
  top: 0 !important;
  height: 100% !important;
  width: auto !important;
  margin-top: 0 !important;
`;
export default ArtCard;
