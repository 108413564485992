import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../config/api";
import http from "../../services/httpService";
import Textarea from "../form/textarea";
import CommentItem from "./commentItem";
import InfiniteRevereScroll from "./infiniteReverseScroll";

const Comments = ({ postId = false, commentsData = [] }) => {
  const [comments, setComments] = useState([]);
  const [input, setInput] = useState("");
  const [showCommets, setShowComments] = useState(false);

  let unMounting = useRef(false);

  useEffect(() => {
    unMounting.current = false;
    if (commentsData.length > 0) setComments(commentsData.reverse());
    if (commentsData.length === 0) setComments([]);
    return () => {
      unMounting.current = true;
      source.cancel();
    };
    // eslint-disable-next-line
  }, [commentsData]);

  const source = http.source();

  const handleClick = async () => {
    try {
      if (!postId || input.length < 3) return;

      const data = {
        postId,
        comment: input,
      };

      const { data: response } = await http.post(api.addComment, data, {
        cancelToken: source.token,
      });

      if (response === "ok") {
        toast.success("Comment added");
        setInput("");
      }
      if (unMounting.current) return;
    } catch (ex) {
      if (unMounting.current) return;
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    if (input.value.length > 300) return;
    setInput(input.value);
  };
  return (
    <>
      <span className="p-2" onClick={() => setShowComments(!showCommets)}>
        {comments.length > 0 && `view comments (${comments.length})`}
      </span>
      {comments.length > 0 && (
        <InfiniteRevereScroll
          items={comments}
          id={postId + "infRevScroll"}
          chunkLength={8}
          display={(comment) => {
            return (
              <CommentItem
                key={comment._id}
                comment={comment}
                postId={postId}
              />
            );
          }}
          parent={(content) => {
            return (
              <CommentsBox
                className={showCommets ? "active" : ""}
                id={postId + "infRevScroll"}
              >
                {content}
              </CommentsBox>
            );
          }}
          end={() => {
            return (
              <div className="col-12 order-3">
                <h5 className="text-center text-muted">No more comments</h5>
              </div>
            );
          }}
        />
      )}
      <CommentInput
        type="text"
        name="comment"
        placeholder="Write your comment.."
        value={input}
        onChange={handleChange}
      />
      <Button onClick={handleClick}>
        <i className="fas fa-paper-plane"></i> Send
      </Button>
    </>
  );
};
const CommentInput = styled(Textarea)`
  margin-bottom: 0.4rem !important;
  textarea {
    height: 3.2rem !important;
    border-radius: 1.5rem;
    padding: 0.8rem 1rem !important;
    padding-right: 60px !important;
    transition: height 0.4s ease-in;
    resize: none;
  }
  textarea:focus {
    height: 6rem !important;
  }
`;
const Button = styled.button`
  background: none;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  margin: 0 !important;

  color: dimgray;
  :hover {
    background-color: #ecf0f4;
  }
  i {
    margin: auto;
    margin-right: 2px;
  }
`;
const CommentsBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.8rem;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  padding: 0 1rem;
  transition: 400ms max-height ease-out;
  &.active {
    padding: 1.2rem 1rem;
    max-height: 400px;
    overflow-y: auto;
    transition: 400ms max-height ease-in;
  }
`;

export default Comments;
