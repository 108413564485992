import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1070;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
function imgWrapperClick() {
  const wrapper = document.getElementById("imageViewWrapper");
  !wrapper.classList.contains("d-none") && wrapper.classList.add("d-none");
}

const ImageWrapper = (props) => {
  return (
    <Wrapper
      className="p-1 d-none"
      onClick={() => imgWrapperClick()}
      id="imageViewWrapper"
    >
      <Image src="" alt="" id="imageView" />
    </Wrapper>
  );
};

export default ImageWrapper;
