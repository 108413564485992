import React, { Fragment } from "react";

const Page3 = ({ form }) => {
  const pwDontMatch = !(
    form.state.data.password === form.state.data.confirmPassword
  );
  const registerButton = form.state.loading ? (
    <i className="fas fa-spinner fa-spin"></i>
  ) : (
    "Register"
  );
  return (
    <div className="row m-0 justify-content-center mx-auto">
      <h2 className="text-center">Your details:</h2>

      <div className="w-100 d-none d-lg-block d-xl-block"></div>
      <div className="col-12 col-md-6 col-lg-4">
        {form.renderFloatingInput("email", "Email", true)}
      </div>
      <div className="col-12 my-1"></div>
      <div className="col-12 col-md-6 col-lg-4">
        {form.renderFloatingInput("username", "Username", true)}
      </div>

      <div className="col-12 my-1"></div>
      <div className="col-12 col-md-6 col-lg-4">
        {form.renderFloatingInput("password", "Password", true, "password")}
      </div>

      <div className="col-12 my-1"></div>
      <div className="col-12 col-md-6 col-lg-4">
        {form.renderFloatingInput(
          "confirmPassword",
          "Confirm password",
          true,
          "password"
        )}
      </div>
      <div className="col-12 my-1"></div>
      <div className="col-12 col-md-6 col-lg-4 ">
        {form.renderSelect(
          "gender",
          "Gender",
          true,

          <Fragment>
            <option value="" disabled>
              Select gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Trans">Trans</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </Fragment>
        )}
      </div>
      <div className="col-12 my-1"></div>
      <div className="col-3 col-md-3 col-lg-3">
        <button
          type="button"
          className="btn btn-purple px-2 py-1 mb-4 mt-4 float-end"
          onClick={() => form.changePage(2)}
        >
          <i className="fas fa-caret-left"></i> prev
        </button>
      </div>
      <div className="col-8 col-md-6 col-lg-3">
        {form.rendersubmit(
          registerButton,
          pwDontMatch,
          "btn btn-purple px-5 py-3"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-3"></div>
    </div>
  );
};

export default Page3;
