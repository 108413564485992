import React from "react";
import styled from "styled-components";
import badgesStyles from "../../config/styles/badges";
import colors from "../../config/styles/colors";

const Badge = ({
  type = false,
  title = false,
  bg = "",
  children,
  className = "",
  popular = false,
}) => {
  const badgeStyle = badgesStyles?.[type] || false;
  return (
    <Container
      className={"badge rounded-pill ms-1 bg-" + bg + " " + className}
      title={title || badgeStyle?.title}
      bgColor={badgeStyle?.bgColor}
    >
      {badgeStyle?.content}
      {popular && (
        <Badge bg="danger" title="Popular">
          <i className="fas fa-fire"></i>
        </Badge>
      )}
      {children}
    </Container>
  );
};

const Container = styled.span`
  background-color: ${({ bgColor }) =>
    bgColor && colors[bgColor] && colors[bgColor]} !important;
`;
export default Badge;
