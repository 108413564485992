import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import { Prompt } from "react-router-dom";
import Select from "./select";
import Textarea from "./textarea";
import ImageRadio from "./imageRadio";
import ImageCheckInput from "./imageCheckInput";
import Switch from "./switch";

class Form extends Component {
  state = { emptyData: false, data: {}, errors: {} };

  validate = () => {
    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(this.state.data, this.schema, options);
    if (!result.error) return null;

    const errors = {};
    for (let data of result.error.details) errors[data.path[0]] = data.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    if (!this.state.emptyData) {
      const currentData = JSON.parse(JSON.stringify(this.state.data));
      this.setState({ emptyData: currentData });
    }
    let arrayValue = false;
    const data = { ...this.state.data };
    if (input.type === "checkbox") {
      if (input.classList.contains("arrayImgCheckbox")) {
        if (input.checked) {
          if (!data[input.name].includes(input.id)) {
            data[input.name].push(input.id);
          }
        } else {
          if (data[input.name].includes(input.id)) {
            data[input.name] = data[input.name].filter(
              (item) => item !== input.id
            );
          }
        }

        arrayValue = data[input.name];
      } else {
        input.value = input.checked;
      }
    }
    if (input.type === "radio" && input.classList.contains("btn-check")) {
      input.value = input.id;
    }
    const errors = { ...this.state.errors };
    const errorMessage = arrayValue
      ? this.validateProperty({ name: input.name, value: arrayValue })
      : this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    if (arrayValue) data[input.name] = arrayValue;
    this.setState({ data, errors });
    if (this.customHandleChange) {
      this.customHandleChange(input, errors);
    }
  };

  renderInput(
    name,
    label,
    required = false,
    type = "text",
    capitalise = false,
    disabled = false
  ) {
    const { data, errors } = this.state;
    return (
      <input
        name={name}
        label={label}
        value={capitalise ? data[name].toUpperCase() : data[name]}
        placeholder={label}
        id={data[name]}
        autoComplete="off"
        className="form-control"
        aria-label={label}
        aria-describedby={name}
        onChange={this.handleChange}
        error={errors[name]}
        type={type}
        required={required}
        disabled={disabled}
      />
    );
  }
  renderFloatingInput(
    name,
    label,
    required = false,
    type = "text",
    capitalise = false,
    disabled = false
  ) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        value={capitalise ? data[name].toUpperCase() : data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        type={type}
        required={required}
        disabled={disabled}
      />
    );
  }
  renderSwitch(name, label, disabled = false) {
    const { data } = this.state;
    return (
      <Switch
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        checked={data[name] === "true"}
        disabled={disabled}
      />
    );
  }
  renderTextarea(name, label, required = false, height = "100px", placeholder) {
    const { data, errors } = this.state;
    return (
      <Textarea
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        style={{ height: height }}
        placeholder={placeholder}
      />
    );
  }

  renderSelect(name, label, required = false, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        required={required}
      >
        {options}
      </Select>
    );
  }
  renderImageRadio(name, id, label, required = false, imgUrl) {
    const { data, errors } = this.state;
    const checked = data[name] === id ? true : false;
    return (
      <ImageRadio
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        required={required}
        onChange={this.handleChange}
        id={id}
        imageUrl={imgUrl}
        checked={checked}
      />
    );
  }
  renderImageCheckbox(name, id, label, required = false, imgUrl) {
    const { data, errors } = this.state;
    const checked = data[name].includes(id);
    return (
      <ImageCheckInput
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        required={required}
        onChange={this.handleChange}
        id={id}
        imageUrl={imgUrl}
        checked={checked}
      />
    );
  }

  rendersubmit(
    label,
    isChanged = false,
    className = "btn btn-purple px-5 py-3 mb-4"
  ) {
    let disabled = this.validate();
    if (!disabled && isChanged) {
      disabled = true;
    }
    return (
      <div className="form-group row mx-0 mt-2 justify-content-center">
        <button type="submit" disabled={disabled} className={className}>
          {label}
        </button>
      </div>
    );
  }

  handleLeave() {
    const emptyData = this.state.emptyData;
    let modified = emptyData ? true : false;
    if (emptyData) {
      modified =
        JSON.stringify(emptyData) === JSON.stringify(this.state.data)
          ? false
          : true;
    }
    return (
      <Prompt
        when={modified}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    );
  }

  renderOptions(options) {
    return options.map((obj, key) => (
      <option key={obj.id} value={obj.id}>
        {obj.name}
      </option>
    ));
  }

  changed() {
    if (!this.state.emptyData) return true;

    if (
      JSON.stringify(this.state.emptyData) === JSON.stringify(this.state.data)
    )
      return true;
    else return false;
  }
  encodeName(name) {
    //urlRegex = "^[a-zA-Z0-9-]{5,20}$";
    let encodedName = name.replace(/[^a-zA-Z0-9/\s-]/g, "");
    encodedName = encodedName.replace(/ +(?= )/g, " ");
    encodedName = encodedName.replace(/\//g, "-");
    encodedName = encodedName.replace(/\s/g, "-");
    return encodedName;
  }
}

export default Form;
