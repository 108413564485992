import http from "./httpService";
import api from "../config/api";
import jwtDecode from "jwt-decode";

const tokenKey = "userToken-artisle";
http.setJwt(localStorage.getItem(tokenKey));

export async function login(data) {
  const { data: jwt } = await http.post(api.login, data);
  localStorage.setItem(tokenKey, jwt);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const decoded = jwtDecode(jwt);
    //const iat = dayjs.unix(decoded.iat);
    //const ago = dayjs().diff(iat, "minute");
    //if (ago > 60) throw new Error();
    return decoded;
  } catch (error) {
    localStorage.removeItem(tokenKey);
    http.setJwt(null);
    return null;
  }
}

// eslint-disable-next-line
export default {
  login,
  logout,
  getUser,
  loginWithJwt,
};
