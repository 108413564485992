import { transform, isEqual, isObject } from "lodash";

export const siteAddress = "https://old.artisle-online.com/";
export const socketAddress = "https://old.artisle-online.com/";
export const apiEndpoint = "https://old.artisle-online.com/api/";
//export const siteAddress = "http://192.168.0.8:3000/";
//export const socketAddress = "http://192.168.0.8:3000/";
//export const apiEndpoint = "http://192.168.0.8:3000/api/";
export const onImgError = ({ currentTarget: img }) => {
  img.src = siteAddress + "public/assets/thumbnail/noImg.png";
};
export const chunkArray = (myArray, chunk_size) => {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
};
export const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? this.difference(value, base[key])
          : value;
    }
  });
};
