import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import Form from "../../form/form";
import Page from "../../common/Page";
import AppBody from "../../common/AppBody";
import Loading from "../../common/loading";
import http from "../../../services/httpService";
import api from "../../../config/api";
import ImageUpload from "./ImageUpload";
import Joi from "joi-browser";
import styled from "styled-components";
import AppButton from "../../common/AppButton";
import Alert from "../../form/alert";
import PostCardPreview from "../../common/postCardPreview.";

class newArt extends Form {
  state = {
    currentPage: 1,
    data: {
      category: "",
      type: "",
      title: "",
      medium: "",
      width: "",
      height: "",
      dimension: "cm",
      posting: "true",
      commentsEnabled: "true",
      message: "",
    },
    images: [],
    errors: {},
    responseError: false,
    loading: false,
  };
  schema = {
    category: Joi.string().required(),
    title: Joi.string().min(3).max(30).required(),
    medium: Joi.string().min(3).max(60).allow(""),
    width: Joi.number().min(0.001).allow(""),
    height: Joi.number().min(0.001).allow(""),
    dimension: Joi.string().valid("cm", "inch"),
    posting: Joi.string().valid("true", "false"),
    commentsEnabled: Joi.string().valid("true", "false"),
    message: Joi.string().min(3).max(500).allow(""),
  };
  componentDidMount() {
    document.getElementById("mainContainer").scrollIntoView();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      document.getElementById("mainContainer").scrollIntoView();
    }
  }
  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      const data = { ...this.state.data };
      const formData = new FormData();

      this.state.images.forEach((image, key) => {
        formData.append("image-" + key, image);
      });
      formData.append("type", "art");
      formData.append("category", data.category);
      formData.append("title", data.title);
      formData.append("medium", data.medium);
      formData.append("width", data.width);
      formData.append("height", data.height);
      formData.append("dimension", data.dimension);
      formData.append("posting", data.posting);
      formData.append("commentsEnabled", data.commentsEnabled);
      formData.append("message", data.message);

      await http.post(api.newArt, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      this.setState({ loading: false, responseError: false });
      window.location = "/" + this.props.currentUser.username;
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response.status === 400) {
        this.setState({ responseError: ex.response.data });
        document.getElementById("mainContainer").scrollIntoView();
      }
      console.log(ex.response);
    }
  };

  renderCategories() {
    const user = this.props.currentUser;
    if (!user || !user.categories) return "";
    const options = user.categories.map((category, key) => {
      return (
        <option value={category} key={key + 1}>
          {category}
        </option>
      );
    });
    return options;
  }
  renderPreviewData() {
    const data = { ...this.state.data };
    const user = this.props.currentUser;
    const previewData = {
      _id: "test",
      author: {
        username: user.username,
        avatar: user.avatar,
      },
      createdAt: "Now",
      art: {
        category: data.category,
        title: data.title,
        medium: data.medium,
        width: data.width,
        height: data.height,
        dimension: data.dimension,
        pictures: this.state.images,
      },
      message: data.message,
      commentsEnabled:
        data.posting === "false"
          ? false
          : data.commentsEnabled === "true" || false,
    };
    return previewData;
  }
  render() {
    const page = this.state.currentPage;
    if (this.props.currentUser.accountType !== "artist")
      return (
        <Page title="New Post">
          <AppBody>
            <section>
              <div className="container p-0 shadow">
                <h2 className="text-center p-2">You aren`t an artist</h2>
              </div>
            </section>
          </AppBody>
        </Page>
      );
    return (
      <Page title="New Post">
        <Helmet></Helmet>
        <AppBody>
          <Loading
            visible={this.state.loading}
            style={{ backgroundColor: "rgba(255,255,255,0)" }}
          />
          {this.state.responseError && (
            <Alert
              title="Error"
              message={this.state.responseError}
              type="danger"
            />
          )}
          <section>
            <div className="container p-0 shadow">
              <form
                onSubmit={this.handleSubmit}
                className="row m-0 px-2 mt-3 justify-content-center mx-auto"
              >
                <h2 className="text-center">Upload a new art</h2>
                {page === 1 && <ImageUpload form={this} />}
                {page === 2 && (
                  <>
                    <div className="col-md-10">
                      {this.renderFloatingInput("title", "Title", true)}
                    </div>
                    <div className="col-md-6">
                      {this.renderSelect(
                        "category",
                        "Category",
                        true,
                        <>
                          <option value="" disabled>
                            Select Category
                          </option>
                          {this.renderCategories()}
                        </>
                      )}
                    </div>

                    <div className="col-md-4">
                      {this.renderFloatingInput("medium", "Medium")}
                    </div>
                    <div className="col-12"></div>
                    <div className="col-md-3 col-lg-2">
                      {this.renderFloatingInput("width", "Width")}
                    </div>
                    <div className="col-md-3 col-lg-2">
                      {this.renderFloatingInput("height", "Height")}
                    </div>
                    <div className="col-md-3 col-lg-3">
                      {this.renderSelect(
                        "dimension",
                        "Dimension",
                        true,
                        <>
                          <option value="cm">cm</option>
                          <option value="inch">inch</option>
                        </>
                      )}
                    </div>
                    <div className="col-12 p-0"></div>
                    <BackBtn
                      title="Change Images"
                      color="rebeccaPurple"
                      outlined
                      onClick={() => {
                        this.setState({ currentPage: 1 });
                      }}
                    />
                    <h4>Options:</h4>
                    <hr />
                    <div className="col-auto">
                      {this.renderSwitch("posting", "Post this art")}
                    </div>
                    <div className="col-auto me-auto">
                      {this.state.data.posting === "true" &&
                        this.renderSwitch(
                          "commentsEnabled",
                          "Comments enabled"
                        )}
                    </div>
                    {this.state.data.posting === "true" && (
                      <div className="col-md-10 mt-3">
                        {this.renderFloatingInput("message", "Post message")}
                      </div>
                    )}
                    <h4 className="mt-2">Preview:</h4>
                    <hr />

                    <div className="col col-md-8" style={{ maxWidth: "400px" }}>
                      <PostCardPreview post={this.renderPreviewData()} />
                    </div>

                    <div className="col-12">{this.rendersubmit("Upload")}</div>
                  </>
                )}
              </form>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}
const BackBtn = styled(AppButton)`
  margin: 0 auto;
  display: flex;
`;

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});

export default connect(mapStateToProps)(newArt);
