import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import AppBody from "../common/AppBody";
import Page from "../common/Page";

class aboutUs extends Component {
  state = {};
  render() {
    return (
      <Page>
        <Helmet></Helmet>
        <AppBody></AppBody>
      </Page>
    );
  }
}

export default aboutUs;
