import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { device } from "../../config/styles/devicesStyle";

import Avatar from "./Avatar";
import Carousel from "./Carousel";
import Textarea from "../form/textarea";

const PostCardPreview = ({ post, edit }) => {
  const author = (post && post.author) || false;

  const renderImages = (images) => {
    let imagesSrc = [];
    for (let index = 0; index < images.length; index++) {
      const image = images[index];

      imagesSrc.push(edit ? image : URL.createObjectURL(image));
    }
    return imagesSrc;
  };

  return (
    <Container className="shadow card">
      {post && post.art && (
        <>
          <Header>
            <Box>
              <Avatar img={author.avatar} alt={author.username} />
            </Box>
            <Box>
              <UserName to={"/" + author.username}>{author.username}</UserName>
              <DateText>{post.createdAt}</DateText>
            </Box>

            <Box>
              <mark>{post.art.category}</mark>
              <MediumText title={post.art.medium}>{post.art.medium}</MediumText>
            </Box>
          </Header>
          {post.message && <MessageBox>{post.message}</MessageBox>}
          <hr className="m-0" />
          {post.art.title && <CardText>{post.art.title}</CardText>}

          <Carousel
            id={"post-" + author.username + "-" + post._id.toString()}
            images={renderImages(post.art.pictures)}
          />

          <ActionButtons>
            <LikeButton outlined color="rebeccaPurple">
              <>
                <i className="far fa-heart"></i>
                <span>Like</span>
              </>
            </LikeButton>

            <LikeButton>
              <i className="fas fa-share"></i> <span>Share</span>
            </LikeButton>
            <LikeButton>
              <i className="fas fa-star text-gold"></i> <span>Stars</span>
            </LikeButton>
          </ActionButtons>

          {post.commentsEnabled && (
            <PostCardButtom>
              <span className="p-2"> view comments (4)</span>
              <CommentInput
                type="text"
                name="comment"
                placeholder="Write your comment.."
              />
              <LikeButton>
                <i className="fas fa-paper-plane"></i> Send
              </LikeButton>
            </PostCardButtom>
          )}
        </>
      )}
      {!post.art && (
        <>
          <Header>
            <Box>
              <Avatar img={author.avatar} alt={author.username} />
            </Box>
            <Box>
              <UserName to={"/" + author.username}>{author.username}</UserName>
              <DateText>{post.createdAt}</DateText>
            </Box>

            <Box></Box>
          </Header>
          <CardText className="my-auto">This Art has been deleted</CardText>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  :first-child {
    margin-top: 1rem !important;
  }
  width: 100%;
  min-height: 150px;
  margin-bottom: 1rem;
  padding: 0.1rem;

  border: 1px solid #dee2e6;
  img {
    width: 100%;
  }
`;
const Header = styled.div`
  padding: 0.4rem 0.6rem;
  display: flex;
`;
const Box = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  :last-child {
    font-size: 13pt;
    min-width: 1px;
    min-height: 32px;
    margin: auto 0;
    margin-left: auto;
  }
  mark {
    color: rebeccapurple;
    font-weight: 600 !important;
  }
`;
const UserName = styled(NavLink)`
  font-size: 14pt;
  font-weight: 300 !important;
  margin-left: 0.4rem;
  color: inherit;
  text-decoration: none;
`;
const DateText = styled.span`
  font-size: 9pt;
  color: grey;
  margin-left: 0.4rem;
`;
const MediumText = styled.span`
  font-size: 9pt;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150%;
  position: absolute;
  bottom: -9pt;
  right: 0;
`;
const MessageBox = styled.p`
  padding: 0.4rem;
  margin: 0;
`;
const CardText = styled.p`
  padding: 0.4rem;
  padding-bottom: 0;
  text-align: center;
  color: #6c757d !important;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #bfc2c6;
  padding: 0.1rem 0;
  font-size: 1rem;
`;
const PostCardButtom = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #bfc2c6;
`;
const LikeButton = styled.button`
  background: none;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  margin: 0 !important;

  color: dimgray;
  :hover {
    background-color: #ecf0f4;
  }
  i {
    margin: auto;
    margin-right: 2px;
  }
  @media ${device.mobileM} {
    span {
      display: none;
    }
    padding: 0.4rem 1.2rem;
  }
`;
const CommentInput = styled(Textarea)`
  margin-bottom: 0.4rem !important;
  textarea {
    height: 3.2rem !important;
    border-radius: 1.5rem;
    padding: 0.8rem 1rem !important;
    padding-right: 60px !important;
    transition: height 0.4s ease-in;
    resize: none;
  }
  textarea:focus {
    height: 6rem !important;
  }
`;

export default PostCardPreview;
