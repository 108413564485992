import React, { Fragment } from "react";
import Alert from "./alert";

const Textarea = ({
  name,
  label,
  error,
  prepend,
  append,
  className,
  col = "col-md-9 col-8",
  required = false,
  ...rest
}) => {
  const classList = className ? " mb-3 " + className : " mb-3";
  return (
    <Fragment>
      <div className={classList}>
        <textarea className="form-control" id={name} name={name} {...rest} />
        {label && (
          <label htmlFor={name} className="visually-hidden">
            {label}
          </label>
        )}
        {error && <Alert title="Oops" type="danger" message={error} />}
      </div>
    </Fragment>
  );
};

export default Textarea;
