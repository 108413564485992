import React from "react";
import styled from "styled-components";

const Carousel = ({ id, images }) => {
  if (!images || images.length < 1) return "";

  const renderIndicators = () => {
    let indicators = [];
    for (let index = 0; index < images.length; index++) {
      if (index > 0)
        indicators.push(
          <button
            key={index}
            type="button"
            data-bs-target={"#" + id}
            data-bs-slide-to={index}
            aria-label={"Slide " + index}
          ></button>
        );
      else
        indicators.push(
          <button
            key={index}
            type="button"
            data-bs-target={"#" + id}
            data-bs-slide-to={index}
            className="active"
            aria-current="true"
            aria-label={"Slide " + index}
          ></button>
        );
    }
    return indicators;
  };

  const renderItems = () => {
    let items = [];
    for (let index = 0; index < images.length; index++) {
      const image = images[index];
      let element = "";

      if (index > 0) {
        element = (
          <div className="carousel-item" key={index}>
            <img src={image} alt={image} className="d-block w-100" />
          </div>
        );
      } else {
        element = (
          <div className="carousel-item active" key={index}>
            <img src={image} alt={image} className="d-block w-100" />
          </div>
        );
      }
      items.push(element);
    }
    return items;
  };
  const multiple = (images && images.length > 1) || false;
  return (
    <Container
      data-bs-ride="carousel"
      data-bs-touch="true"
      multiple={multiple}
      id={id}
      className="carousel slide"
      data-bs-interval="false"
    >
      {multiple && (
        <Indicators className="carousel-indicators">
          {renderIndicators()}
        </Indicators>
      )}
      <div className="carousel-inner">{renderItems()}</div>
      {multiple && (
        <>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={"#" + id}
            data-bs-slide="prev"
          >
            <ControlButtons
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></ControlButtons>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={"#" + id}
            data-bs-slide="next"
          >
            <ControlButtons
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></ControlButtons>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${({ multiple }) => (multiple ? "1.5rem" : 0)};
  position: relative;
`;
const Indicators = styled.div`
  position: absolute;
  bottom: -1.5rem;
  margin: 0;
  [data-bs-target] {
    background-color: rebeccapurple;
  }
`;
const ControlButtons = styled.span`
  filter: drop-shadow(1px 4px 1px #000);
`;
export default Carousel;
