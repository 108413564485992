import { css } from "styled-components";

export const aliceBlue = css`
  color: aliceBlue;
  background-color: seagreen;
  border-color: aliceBlue;
  :hover {
    color: white;
    background-color: aliceBlue;
    border-color: aliceBlue;
  }
  :active {
    color: white;
    background-color: aliceBlue;
    border-color: aliceBlue;
  }
  :disabled {
    color: white;
    background-color: aliceBlue;
    border-color: aliceBlue;
  }
`;
export const antiqueWhite = css`
  color: antiqueWhite;
  background-color: white;
  border-color: antiqueWhite;
  :hover {
    color: white;
    background-color: antiqueWhite;
    border-color: antiqueWhite;
  }
  :active {
    color: white;
    background-color: antiqueWhite;
    border-color: antiqueWhite;
  }
  :disabled {
    color: white;
    background-color: antiqueWhite;
    border-color: antiqueWhite;
  }
`;
export const aqua = css`
  color: aqua;
  background-color: white;
  border-color: aqua;
  :hover {
    color: white;
    background-color: aqua;
    border-color: aqua;
  }
  :active {
    color: white;
    background-color: aqua;
    border-color: aqua;
  }
  :disabled {
    color: white;
    background-color: aqua;
    border-color: aqua;
  }
`;
export const aquamarine = css`
  color: aquamarine;
  background-color: white;
  border-color: aquamarine;
  :hover {
    color: white;
    background-color: aquamarine;
    border-color: aquamarine;
  }
  :active {
    color: white;
    background-color: aquamarine;
    border-color: aquamarine;
  }
  :disabled {
    color: white;
    background-color: aquamarine;
    border-color: aquamarine;
  }
`;
export const azure = css`
  color: azure;
  background-color: white;
  border-color: azure;
  :hover {
    color: white;
    background-color: azure;
    border-color: azure;
  }
  :active {
    color: white;
    background-color: azure;
    border-color: azure;
  }
  :disabled {
    color: white;
    background-color: azure;
    border-color: azure;
  }
`;
export const beige = css`
  color: beige;
  background-color: white;
  border-color: beige;
  :hover {
    color: white;
    background-color: beige;
    border-color: beige;
  }
  :active {
    color: white;
    background-color: beige;
    border-color: beige;
  }
  :disabled {
    color: white;
    background-color: beige;
    border-color: beige;
  }
`;
export const bisque = css`
  color: bisque;
  background-color: white;
  border-color: bisque;
  :hover {
    color: white;
    background-color: bisque;
    border-color: bisque;
  }
  :active {
    color: white;
    background-color: bisque;
    border-color: bisque;
  }
  :disabled {
    color: white;
    background-color: bisque;
    border-color: bisque;
  }
`;
export const black = css`
  color: black;
  background-color: white;
  border-color: black;
  :hover {
    color: white;
    background-color: black;
    border-color: black;
  }
  :active {
    color: white;
    background-color: black;
    border-color: black;
  }
  :disabled {
    color: white;
    background-color: black;
    border-color: black;
  }
`;
export const blanchedAlmond = css`
  color: blanchedAlmond;
  background-color: white;
  border-color: blanchedAlmond;
  :hover {
    color: white;
    background-color: blanchedAlmond;
    border-color: blanchedAlmond;
  }
  :active {
    color: white;
    background-color: blanchedAlmond;
    border-color: blanchedAlmond;
  }
  :disabled {
    color: white;
    background-color: blanchedAlmond;
    border-color: blanchedAlmond;
  }
`;
export const blue = css`
  color: blue;
  background-color: white;
  border-color: blue;
  :hover {
    color: white;
    background-color: blue;
    border-color: blue;
  }
  :active {
    color: white;
    background-color: blue;
    border-color: blue;
  }
  :disabled {
    color: white;
    background-color: blue;
    border-color: blue;
  }
`;
export const blueViolet = css`
  color: blueViolet;
  background-color: white;
  border-color: blueViolet;
  :hover {
    color: white;
    background-color: blueViolet;
    border-color: blueViolet;
  }
  :active {
    color: white;
    background-color: blueViolet;
    border-color: blueViolet;
  }
  :disabled {
    color: white;
    background-color: blueViolet;
    border-color: blueViolet;
  }
`;
export const brown = css`
  color: brown;
  background-color: white;
  border-color: brown;
  :hover {
    color: white;
    background-color: brown;
    border-color: brown;
  }
  :active {
    color: white;
    background-color: brown;
    border-color: brown;
  }
  :disabled {
    color: white;
    background-color: brown;
    border-color: brown;
  }
`;
export const burlyWood = css`
  color: burlyWood;
  background-color: white;
  border-color: burlyWood;
  :hover {
    color: white;
    background-color: burlyWood;
    border-color: burlyWood;
  }
  :active {
    color: white;
    background-color: burlyWood;
    border-color: burlyWood;
  }
  :disabled {
    color: white;
    background-color: burlyWood;
    border-color: burlyWood;
  }
`;
export const cadetBlue = css`
  color: cadetBlue;
  background-color: white;
  border-color: cadetBlue;
  :hover {
    color: white;
    background-color: cadetBlue;
    border-color: cadetBlue;
  }
  :active {
    color: white;
    background-color: cadetBlue;
    border-color: cadetBlue;
  }
  :disabled {
    color: white;
    background-color: cadetBlue;
    border-color: cadetBlue;
  }
`;
export const chartreuse = css`
  color: chartreuse;
  background-color: white;
  border-color: chartreuse;
  :hover {
    color: white;
    background-color: chartreuse;
    border-color: chartreuse;
  }
  :active {
    color: white;
    background-color: chartreuse;
    border-color: chartreuse;
  }
  :disabled {
    color: white;
    background-color: chartreuse;
    border-color: chartreuse;
  }
`;
export const chocolate = css`
  color: chocolate;
  background-color: white;
  border-color: chocolate;
  :hover {
    color: white;
    background-color: chocolate;
    border-color: chocolate;
  }
  :active {
    color: white;
    background-color: chocolate;
    border-color: chocolate;
  }
  :disabled {
    color: white;
    background-color: chocolate;
    border-color: chocolate;
  }
`;
export const coral = css`
  color: coral;
  background-color: white;
  border-color: coral;
  :hover {
    color: white;
    background-color: coral;
    border-color: coral;
  }
  :active {
    color: white;
    background-color: coral;
    border-color: coral;
  }
  :disabled {
    color: white;
    background-color: coral;
    border-color: coral;
  }
`;
export const cornflowerBlue = css`
  color: cornflowerBlue;
  background-color: white;
  border-color: cornflowerBlue;
  :hover {
    color: white;
    background-color: cornflowerBlue;
    border-color: cornflowerBlue;
  }
  :active {
    color: white;
    background-color: cornflowerBlue;
    border-color: cornflowerBlue;
  }
  :disabled {
    color: white;
    background-color: cornflowerBlue;
    border-color: cornflowerBlue;
  }
`;
export const cornsilk = css`
  color: cornsilk;
  background-color: white;
  border-color: cornsilk;
  :hover {
    color: white;
    background-color: cornsilk;
    border-color: cornsilk;
  }
  :active {
    color: white;
    background-color: cornsilk;
    border-color: cornsilk;
  }
  :disabled {
    color: white;
    background-color: cornsilk;
    border-color: cornsilk;
  }
`;
export const crimson = css`
  color: crimson;
  background-color: white;
  border-color: crimson;
  :hover {
    color: white;
    background-color: crimson;
    border-color: crimson;
  }
  :active {
    color: white;
    background-color: crimson;
    border-color: crimson;
  }
  :disabled {
    color: white;
    background-color: crimson;
    border-color: crimson;
  }
`;
export const cyan = css`
  color: cyan;
  background-color: white;
  border-color: cyan;
  :hover {
    color: white;
    background-color: cyan;
    border-color: cyan;
  }
  :active {
    color: white;
    background-color: cyan;
    border-color: cyan;
  }
  :disabled {
    color: white;
    background-color: cyan;
    border-color: cyan;
  }
`;
export const darkBlue = css`
  color: darkBlue;
  background-color: white;
  border-color: darkBlue;
  :hover {
    color: white;
    background-color: darkBlue;
    border-color: darkBlue;
  }
  :active {
    color: white;
    background-color: darkBlue;
    border-color: darkBlue;
  }
  :disabled {
    color: white;
    background-color: darkBlue;
    border-color: darkBlue;
  }
`;
export const darkCyan = css`
  color: darkCyan;
  background-color: white;
  border-color: darkCyan;
  :hover {
    color: white;
    background-color: darkCyan;
    border-color: darkCyan;
  }
  :active {
    color: white;
    background-color: darkCyan;
    border-color: darkCyan;
  }
  :disabled {
    color: white;
    background-color: darkCyan;
    border-color: darkCyan;
  }
`;
export const darkGoldenRod = css`
  color: darkGoldenRod;
  background-color: white;
  border-color: darkGoldenRod;
  :hover {
    color: white;
    background-color: darkGoldenRod;
    border-color: darkGoldenRod;
  }
  :active {
    color: white;
    background-color: darkGoldenRod;
    border-color: darkGoldenRod;
  }
  :disabled {
    color: white;
    background-color: darkGoldenRod;
    border-color: darkGoldenRod;
  }
`;
export const darkGray = css`
  color: darkGray;
  background-color: white;
  border-color: darkGray;
  :hover {
    color: white;
    background-color: darkGray;
    border-color: darkGray;
  }
  :active {
    color: white;
    background-color: darkGray;
    border-color: darkGray;
  }
  :disabled {
    color: white;
    background-color: darkGray;
    border-color: darkGray;
  }
`;
export const darkGrey = css`
  color: darkGrey;
  background-color: white;
  border-color: darkGrey;
  :hover {
    color: white;
    background-color: darkGrey;
    border-color: darkGrey;
  }
  :active {
    color: white;
    background-color: darkGrey;
    border-color: darkGrey;
  }
  :disabled {
    color: white;
    background-color: darkGrey;
    border-color: darkGrey;
  }
`;
export const darkGreen = css`
  color: darkGreen;
  background-color: white;
  border-color: darkGreen;
  :hover {
    color: white;
    background-color: darkGreen;
    border-color: darkGreen;
  }
  :active {
    color: white;
    background-color: darkGreen;
    border-color: darkGreen;
  }
  :disabled {
    color: white;
    background-color: darkGreen;
    border-color: darkGreen;
  }
`;
export const darkKhaki = css`
  color: darkKhaki;
  background-color: white;
  border-color: darkKhaki;
  :hover {
    color: white;
    background-color: darkKhaki;
    border-color: darkKhaki;
  }
  :active {
    color: white;
    background-color: darkKhaki;
    border-color: darkKhaki;
  }
  :disabled {
    color: white;
    background-color: darkKhaki;
    border-color: darkKhaki;
  }
`;
export const darkMagenta = css`
  color: darkMagenta;
  background-color: white;
  border-color: darkMagenta;
  :hover {
    color: white;
    background-color: darkMagenta;
    border-color: darkMagenta;
  }
  :active {
    color: white;
    background-color: darkMagenta;
    border-color: darkMagenta;
  }
  :disabled {
    color: white;
    background-color: darkMagenta;
    border-color: darkMagenta;
  }
`;
export const darkOliveGreen = css`
  color: darkOliveGreen;
  background-color: white;
  border-color: darkOliveGreen;
  :hover {
    color: white;
    background-color: darkOliveGreen;
    border-color: darkOliveGreen;
  }
  :active {
    color: white;
    background-color: darkOliveGreen;
    border-color: darkOliveGreen;
  }
  :disabled {
    color: white;
    background-color: darkOliveGreen;
    border-color: darkOliveGreen;
  }
`;
export const darkOrange = css`
  color: darkOrange;
  background-color: white;
  border-color: darkOrange;
  :hover {
    color: white;
    background-color: darkOrange;
    border-color: darkOrange;
  }
  :active {
    color: white;
    background-color: darkOrange;
    border-color: darkOrange;
  }
  :disabled {
    color: white;
    background-color: darkOrange;
    border-color: darkOrange;
  }
`;
export const darkOrchid = css`
  color: darkOrchid;
  background-color: white;
  border-color: darkOrchid;
  :hover {
    color: white;
    background-color: darkOrchid;
    border-color: darkOrchid;
  }
  :active {
    color: white;
    background-color: darkOrchid;
    border-color: darkOrchid;
  }
  :disabled {
    color: white;
    background-color: darkOrchid;
    border-color: darkOrchid;
  }
`;
export const darkRed = css`
  color: darkRed;
  background-color: white;
  border-color: darkRed;
  :hover {
    color: white;
    background-color: darkRed;
    border-color: darkRed;
  }
  :active {
    color: white;
    background-color: darkRed;
    border-color: darkRed;
  }
  :disabled {
    color: white;
    background-color: darkRed;
    border-color: darkRed;
  }
`;
export const darkSalmon = css`
  color: darkSalmon;
  background-color: white;
  border-color: darkSalmon;
  :hover {
    color: white;
    background-color: darkSalmon;
    border-color: darkSalmon;
  }
  :active {
    color: white;
    background-color: darkSalmon;
    border-color: darkSalmon;
  }
  :disabled {
    color: white;
    background-color: darkSalmon;
    border-color: darkSalmon;
  }
`;
export const darkSeaGreen = css`
  color: darkSeaGreen;
  background-color: white;
  border-color: darkSeaGreen;
  :hover {
    color: white;
    background-color: darkSeaGreen;
    border-color: darkSeaGreen;
  }
  :active {
    color: white;
    background-color: darkSeaGreen;
    border-color: darkSeaGreen;
  }
  :disabled {
    color: white;
    background-color: darkSeaGreen;
    border-color: darkSeaGreen;
  }
`;
export const darkSlateBlue = css`
  color: darkSlateBlue;
  background-color: white;
  border-color: darkSlateBlue;
  :hover {
    color: white;
    background-color: darkSlateBlue;
    border-color: darkSlateBlue;
  }
  :active {
    color: white;
    background-color: darkSlateBlue;
    border-color: darkSlateBlue;
  }
  :disabled {
    color: white;
    background-color: darkSlateBlue;
    border-color: darkSlateBlue;
  }
`;
export const darkSlateGray = css`
  color: darkSlateGray;
  background-color: white;
  border-color: darkSlateGray;
  :hover {
    color: white;
    background-color: darkSlateGray;
    border-color: darkSlateGray;
  }
  :active {
    color: white;
    background-color: darkSlateGray;
    border-color: darkSlateGray;
  }
  :disabled {
    color: white;
    background-color: darkSlateGray;
    border-color: darkSlateGray;
  }
`;
export const darkSlateGrey = css`
  color: darkSlateGrey;
  background-color: white;
  border-color: darkSlateGrey;
  :hover {
    color: white;
    background-color: darkSlateGrey;
    border-color: darkSlateGrey;
  }
  :active {
    color: white;
    background-color: darkSlateGrey;
    border-color: darkSlateGrey;
  }
  :disabled {
    color: white;
    background-color: darkSlateGrey;
    border-color: darkSlateGrey;
  }
`;
export const darkTurquoise = css`
  color: darkTurquoise;
  background-color: white;
  border-color: darkTurquoise;
  :hover {
    color: white;
    background-color: darkTurquoise;
    border-color: darkTurquoise;
  }
  :active {
    color: white;
    background-color: darkTurquoise;
    border-color: darkTurquoise;
  }
  :disabled {
    color: white;
    background-color: darkTurquoise;
    border-color: darkTurquoise;
  }
`;
export const darkViolet = css`
  color: darkViolet;
  background-color: white;
  border-color: darkViolet;
  :hover {
    color: white;
    background-color: darkViolet;
    border-color: darkViolet;
  }
  :active {
    color: white;
    background-color: darkViolet;
    border-color: darkViolet;
  }
  :disabled {
    color: white;
    background-color: darkViolet;
    border-color: darkViolet;
  }
`;
export const deepPink = css`
  color: deepPink;
  background-color: white;
  border-color: deepPink;
  :hover {
    color: white;
    background-color: deepPink;
    border-color: deepPink;
  }
  :active {
    color: white;
    background-color: deepPink;
    border-color: deepPink;
  }
  :disabled {
    color: white;
    background-color: deepPink;
    border-color: deepPink;
  }
`;
export const deepSkyBlue = css`
  color: deepSkyBlue;
  background-color: white;
  border-color: deepSkyBlue;
  :hover {
    color: white;
    background-color: deepSkyBlue;
    border-color: deepSkyBlue;
  }
  :active {
    color: white;
    background-color: deepSkyBlue;
    border-color: deepSkyBlue;
  }
  :disabled {
    color: white;
    background-color: deepSkyBlue;
    border-color: deepSkyBlue;
  }
`;
export const dimGray = css`
  color: dimGray;
  background-color: white;
  border-color: dimGray;
  :hover {
    color: white;
    background-color: dimGray;
    border-color: dimGray;
  }
  :active {
    color: white;
    background-color: dimGray;
    border-color: dimGray;
  }
  :disabled {
    color: white;
    background-color: dimGray;
    border-color: dimGray;
  }
`;
export const dimGrey = css`
  color: dimGrey;
  background-color: white;
  border-color: dimGrey;
  :hover {
    color: white;
    background-color: dimGrey;
    border-color: dimGrey;
  }
  :active {
    color: white;
    background-color: dimGrey;
    border-color: dimGrey;
  }
  :disabled {
    color: white;
    background-color: dimGrey;
    border-color: dimGrey;
  }
`;
export const dodgerBlue = css`
  color: dodgerBlue;
  background-color: white;
  border-color: dodgerBlue;
  :hover {
    color: white;
    background-color: dodgerBlue;
    border-color: dodgerBlue;
  }
  :active {
    color: white;
    background-color: dodgerBlue;
    border-color: dodgerBlue;
  }
  :disabled {
    color: white;
    background-color: dodgerBlue;
    border-color: dodgerBlue;
  }
`;
export const fireBrick = css`
  color: fireBrick;
  background-color: white;
  border-color: fireBrick;
  :hover {
    color: white;
    background-color: fireBrick;
    border-color: fireBrick;
  }
  :active {
    color: white;
    background-color: fireBrick;
    border-color: fireBrick;
  }
  :disabled {
    color: white;
    background-color: fireBrick;
    border-color: fireBrick;
  }
`;
export const floralWhite = css`
  color: floralWhite;
  background-color: white;
  border-color: floralWhite;
  :hover {
    color: white;
    background-color: floralWhite;
    border-color: floralWhite;
  }
  :active {
    color: white;
    background-color: floralWhite;
    border-color: floralWhite;
  }
  :disabled {
    color: white;
    background-color: floralWhite;
    border-color: floralWhite;
  }
`;
export const forestGreen = css`
  color: forestGreen;
  background-color: white;
  border-color: forestGreen;
  :hover {
    color: white;
    background-color: forestGreen;
    border-color: forestGreen;
  }
  :active {
    color: white;
    background-color: forestGreen;
    border-color: forestGreen;
  }
  :disabled {
    color: white;
    background-color: forestGreen;
    border-color: forestGreen;
  }
`;
export const fuchsia = css`
  color: fuchsia;
  background-color: white;
  border-color: fuchsia;
  :hover {
    color: white;
    background-color: fuchsia;
    border-color: fuchsia;
  }
  :active {
    color: white;
    background-color: fuchsia;
    border-color: fuchsia;
  }
  :disabled {
    color: white;
    background-color: fuchsia;
    border-color: fuchsia;
  }
`;
export const gainsboro = css`
  color: gainsboro;
  background-color: white;
  border-color: gainsboro;
  :hover {
    color: white;
    background-color: gainsboro;
    border-color: gainsboro;
  }
  :active {
    color: white;
    background-color: gainsboro;
    border-color: gainsboro;
  }
  :disabled {
    color: white;
    background-color: gainsboro;
    border-color: gainsboro;
  }
`;
export const ghostWhite = css`
  color: ghostWhite;
  background-color: white;
  border-color: ghostWhite;
  :hover {
    color: white;
    background-color: ghostWhite;
    border-color: ghostWhite;
  }
  :active {
    color: white;
    background-color: ghostWhite;
    border-color: ghostWhite;
  }
  :disabled {
    color: white;
    background-color: ghostWhite;
    border-color: ghostWhite;
  }
`;
export const gold = css`
  color: gold;
  background-color: white;
  border-color: gold;
  :hover {
    color: white;
    background-color: gold;
    border-color: gold;
  }
  :active {
    color: white;
    background-color: gold;
    border-color: gold;
  }
  :disabled {
    color: white;
    background-color: gold;
    border-color: gold;
  }
`;
export const goldenRod = css`
  color: goldenRod;
  background-color: white;
  border-color: goldenRod;
  :hover {
    color: white;
    background-color: goldenRod;
    border-color: goldenRod;
  }
  :active {
    color: white;
    background-color: goldenRod;
    border-color: goldenRod;
  }
  :disabled {
    color: white;
    background-color: goldenRod;
    border-color: goldenRod;
  }
`;
export const gray = css`
  color: gray;
  background-color: white;
  border-color: gray;
  :hover {
    color: white;
    background-color: gray;
    border-color: gray;
  }
  :active {
    color: white;
    background-color: gray;
    border-color: gray;
  }
  :disabled {
    color: white;
    background-color: gray;
    border-color: gray;
  }
`;
export const grey = css`
  color: grey;
  background-color: white;
  border-color: grey;
  :hover {
    color: white;
    background-color: grey;
    border-color: grey;
  }
  :active {
    color: white;
    background-color: grey;
    border-color: grey;
  }
  :disabled {
    color: white;
    background-color: grey;
    border-color: grey;
  }
`;
export const green = css`
  color: green;
  background-color: white;
  border-color: green;
  :hover {
    color: white;
    background-color: green;
    border-color: green;
  }
  :active {
    color: white;
    background-color: green;
    border-color: green;
  }
  :disabled {
    color: white;
    background-color: green;
    border-color: green;
  }
`;
export const greenYellow = css`
  color: greenYellow;
  background-color: white;
  border-color: greenYellow;
  :hover {
    color: white;
    background-color: greenYellow;
    border-color: greenYellow;
  }
  :active {
    color: white;
    background-color: greenYellow;
    border-color: greenYellow;
  }
  :disabled {
    color: white;
    background-color: greenYellow;
    border-color: greenYellow;
  }
`;
export const honeyDew = css`
  color: honeyDew;
  background-color: white;
  border-color: honeyDew;
  :hover {
    color: white;
    background-color: honeyDew;
    border-color: honeyDew;
  }
  :active {
    color: white;
    background-color: honeyDew;
    border-color: honeyDew;
  }
  :disabled {
    color: white;
    background-color: honeyDew;
    border-color: honeyDew;
  }
`;
export const hotPink = css`
  color: hotPink;
  background-color: white;
  border-color: hotPink;
  :hover {
    color: white;
    background-color: hotPink;
    border-color: hotPink;
  }
  :active {
    color: white;
    background-color: hotPink;
    border-color: hotPink;
  }
  :disabled {
    color: white;
    background-color: hotPink;
    border-color: hotPink;
  }
`;
export const indianRed = css`
  color: indianRed;
  background-color: white;
  border-color: indianRed;
  :hover {
    color: white;
    background-color: indianRed;
    border-color: indianRed;
  }
  :active {
    color: white;
    background-color: indianRed;
    border-color: indianRed;
  }
  :disabled {
    color: white;
    background-color: indianRed;
    border-color: indianRed;
  }
`;
export const indigo = css`
  color: indigo;
  background-color: white;
  border-color: indigo;
  :hover {
    color: white;
    background-color: indigo;
    border-color: indigo;
  }
  :active {
    color: white;
    background-color: indigo;
    border-color: indigo;
  }
  :disabled {
    color: white;
    background-color: indigo;
    border-color: indigo;
  }
`;
export const ivory = css`
  color: ivory;
  background-color: white;
  border-color: ivory;
  :hover {
    color: white;
    background-color: ivory;
    border-color: ivory;
  }
  :active {
    color: white;
    background-color: ivory;
    border-color: ivory;
  }
  :disabled {
    color: white;
    background-color: ivory;
    border-color: ivory;
  }
`;
export const khaki = css`
  color: khaki;
  background-color: white;
  border-color: khaki;
  :hover {
    color: white;
    background-color: khaki;
    border-color: khaki;
  }
  :active {
    color: white;
    background-color: khaki;
    border-color: khaki;
  }
  :disabled {
    color: white;
    background-color: khaki;
    border-color: khaki;
  }
`;
export const lavender = css`
  color: lavender;
  background-color: white;
  border-color: lavender;
  :hover {
    color: white;
    background-color: lavender;
    border-color: lavender;
  }
  :active {
    color: white;
    background-color: lavender;
    border-color: lavender;
  }
  :disabled {
    color: white;
    background-color: lavender;
    border-color: lavender;
  }
`;
export const lavenderBlush = css`
  color: lavenderBlush;
  background-color: white;
  border-color: lavenderBlush;
  :hover {
    color: white;
    background-color: lavenderBlush;
    border-color: lavenderBlush;
  }
  :active {
    color: white;
    background-color: lavenderBlush;
    border-color: lavenderBlush;
  }
  :disabled {
    color: white;
    background-color: lavenderBlush;
    border-color: lavenderBlush;
  }
`;
export const lawnGreen = css`
  color: lawnGreen;
  background-color: white;
  border-color: lawnGreen;
  :hover {
    color: white;
    background-color: lawnGreen;
    border-color: lawnGreen;
  }
  :active {
    color: white;
    background-color: lawnGreen;
    border-color: lawnGreen;
  }
  :disabled {
    color: white;
    background-color: lawnGreen;
    border-color: lawnGreen;
  }
`;
export const lemonChiffon = css`
  color: lemonChiffon;
  background-color: white;
  border-color: lemonChiffon;
  :hover {
    color: white;
    background-color: lemonChiffon;
    border-color: lemonChiffon;
  }
  :active {
    color: white;
    background-color: lemonChiffon;
    border-color: lemonChiffon;
  }
  :disabled {
    color: white;
    background-color: lemonChiffon;
    border-color: lemonChiffon;
  }
`;
export const lightBlue = css`
  color: lightBlue;
  background-color: white;
  border-color: lightBlue;
  :hover {
    color: white;
    background-color: lightBlue;
    border-color: lightBlue;
  }
  :active {
    color: white;
    background-color: lightBlue;
    border-color: lightBlue;
  }
  :disabled {
    color: white;
    background-color: lightBlue;
    border-color: lightBlue;
  }
`;
export const lightCoral = css`
  color: lightCoral;
  background-color: white;
  border-color: lightCoral;
  :hover {
    color: white;
    background-color: lightCoral;
    border-color: lightCoral;
  }
  :active {
    color: white;
    background-color: lightCoral;
    border-color: lightCoral;
  }
  :disabled {
    color: white;
    background-color: lightCoral;
    border-color: lightCoral;
  }
`;
export const lightCyan = css`
  color: lightCyan;
  background-color: white;
  border-color: lightCyan;
  :hover {
    color: white;
    background-color: lightCyan;
    border-color: lightCyan;
  }
  :active {
    color: white;
    background-color: lightCyan;
    border-color: lightCyan;
  }
  :disabled {
    color: white;
    background-color: lightCyan;
    border-color: lightCyan;
  }
`;
export const lightGoldenRodYellow = css`
  color: lightGoldenRodYellow;
  background-color: white;
  border-color: lightGoldenRodYellow;
  :hover {
    color: white;
    background-color: lightGoldenRodYellow;
    border-color: lightGoldenRodYellow;
  }
  :active {
    color: white;
    background-color: lightGoldenRodYellow;
    border-color: lightGoldenRodYellow;
  }
  :disabled {
    color: white;
    background-color: lightGoldenRodYellow;
    border-color: lightGoldenRodYellow;
  }
`;
export const lightGray = css`
  color: lightGray;
  background-color: white;
  border-color: lightGray;
  :hover {
    color: white;
    background-color: lightGray;
    border-color: lightGray;
  }
  :active {
    color: white;
    background-color: lightGray;
    border-color: lightGray;
  }
  :disabled {
    color: white;
    background-color: lightGray;
    border-color: lightGray;
  }
`;
export const lightGrey = css`
  color: lightGrey;
  background-color: white;
  border-color: lightGrey;
  :hover {
    color: white;
    background-color: lightGrey;
    border-color: lightGrey;
  }
  :active {
    color: white;
    background-color: lightGrey;
    border-color: lightGrey;
  }
  :disabled {
    color: white;
    background-color: lightGrey;
    border-color: lightGrey;
  }
`;
export const lightGreen = css`
  color: lightGreen;
  background-color: white;
  border-color: lightGreen;
  :hover {
    color: white;
    background-color: lightGreen;
    border-color: lightGreen;
  }
  :active {
    color: white;
    background-color: lightGreen;
    border-color: lightGreen;
  }
  :disabled {
    color: white;
    background-color: lightGreen;
    border-color: lightGreen;
  }
`;
export const lightPink = css`
  color: lightPink;
  background-color: white;
  border-color: lightPink;
  :hover {
    color: white;
    background-color: lightPink;
    border-color: lightPink;
  }
  :active {
    color: white;
    background-color: lightPink;
    border-color: lightPink;
  }
  :disabled {
    color: white;
    background-color: lightPink;
    border-color: lightPink;
  }
`;
export const lightSalmon = css`
  color: lightSalmon;
  background-color: white;
  border-color: lightSalmon;
  :hover {
    color: white;
    background-color: lightSalmon;
    border-color: lightSalmon;
  }
  :active {
    color: white;
    background-color: lightSalmon;
    border-color: lightSalmon;
  }
  :disabled {
    color: white;
    background-color: lightSalmon;
    border-color: lightSalmon;
  }
`;
export const lightSeaGreen = css`
  color: lightSeaGreen;
  background-color: white;
  border-color: lightSeaGreen;
  :hover {
    color: white;
    background-color: lightSeaGreen;
    border-color: lightSeaGreen;
  }
  :active {
    color: white;
    background-color: lightSeaGreen;
    border-color: lightSeaGreen;
  }
  :disabled {
    color: white;
    background-color: lightSeaGreen;
    border-color: lightSeaGreen;
  }
`;
export const lightSkyBlue = css`
  color: lightSkyBlue;
  background-color: white;
  border-color: lightSkyBlue;
  :hover {
    color: white;
    background-color: lightSkyBlue;
    border-color: lightSkyBlue;
  }
  :active {
    color: white;
    background-color: lightSkyBlue;
    border-color: lightSkyBlue;
  }
  :disabled {
    color: white;
    background-color: lightSkyBlue;
    border-color: lightSkyBlue;
  }
`;
export const lightSlateGray = css`
  color: lightSlateGray;
  background-color: white;
  border-color: lightSlateGray;
  :hover {
    color: white;
    background-color: lightSlateGray;
    border-color: lightSlateGray;
  }
  :active {
    color: white;
    background-color: lightSlateGray;
    border-color: lightSlateGray;
  }
  :disabled {
    color: white;
    background-color: lightSlateGray;
    border-color: lightSlateGray;
  }
`;
export const lightSlateGrey = css`
  color: lightSlateGrey;
  background-color: white;
  border-color: lightSlateGrey;
  :hover {
    color: white;
    background-color: lightSlateGrey;
    border-color: lightSlateGrey;
  }
  :active {
    color: white;
    background-color: lightSlateGrey;
    border-color: lightSlateGrey;
  }
  :disabled {
    color: white;
    background-color: lightSlateGrey;
    border-color: lightSlateGrey;
  }
`;
export const lightSteelBlue = css`
  color: lightSteelBlue;
  background-color: white;
  border-color: lightSteelBlue;
  :hover {
    color: white;
    background-color: lightSteelBlue;
    border-color: lightSteelBlue;
  }
  :active {
    color: white;
    background-color: lightSteelBlue;
    border-color: lightSteelBlue;
  }
  :disabled {
    color: white;
    background-color: lightSteelBlue;
    border-color: lightSteelBlue;
  }
`;
export const lightYellow = css`
  color: lightYellow;
  background-color: white;
  border-color: lightYellow;
  :hover {
    color: white;
    background-color: lightYellow;
    border-color: lightYellow;
  }
  :active {
    color: white;
    background-color: lightYellow;
    border-color: lightYellow;
  }
  :disabled {
    color: white;
    background-color: lightYellow;
    border-color: lightYellow;
  }
`;
export const lime = css`
  color: lime;
  background-color: white;
  border-color: lime;
  :hover {
    color: white;
    background-color: lime;
    border-color: lime;
  }
  :active {
    color: white;
    background-color: lime;
    border-color: lime;
  }
  :disabled {
    color: white;
    background-color: lime;
    border-color: lime;
  }
`;
export const limeGreen = css`
  color: limeGreen;
  background-color: white;
  border-color: limeGreen;
  :hover {
    color: white;
    background-color: limeGreen;
    border-color: limeGreen;
  }
  :active {
    color: white;
    background-color: limeGreen;
    border-color: limeGreen;
  }
  :disabled {
    color: white;
    background-color: limeGreen;
    border-color: limeGreen;
  }
`;
export const linen = css`
  color: linen;
  background-color: white;
  border-color: linen;
  :hover {
    color: white;
    background-color: linen;
    border-color: linen;
  }
  :active {
    color: white;
    background-color: linen;
    border-color: linen;
  }
  :disabled {
    color: white;
    background-color: linen;
    border-color: linen;
  }
`;
export const magenta = css`
  color: magenta;
  background-color: white;
  border-color: magenta;
  :hover {
    color: white;
    background-color: magenta;
    border-color: magenta;
  }
  :active {
    color: white;
    background-color: magenta;
    border-color: magenta;
  }
  :disabled {
    color: white;
    background-color: magenta;
    border-color: magenta;
  }
`;
export const maroon = css`
  color: maroon;
  background-color: white;
  border-color: maroon;
  :hover {
    color: white;
    background-color: maroon;
    border-color: maroon;
  }
  :active {
    color: white;
    background-color: maroon;
    border-color: maroon;
  }
  :disabled {
    color: white;
    background-color: maroon;
    border-color: maroon;
  }
`;
export const mediumAquaMarine = css`
  color: mediumAquaMarine;
  background-color: white;
  border-color: mediumAquaMarine;
  :hover {
    color: white;
    background-color: mediumAquaMarine;
    border-color: mediumAquaMarine;
  }
  :active {
    color: white;
    background-color: mediumAquaMarine;
    border-color: mediumAquaMarine;
  }
  :disabled {
    color: white;
    background-color: mediumAquaMarine;
    border-color: mediumAquaMarine;
  }
`;
export const mediumBlue = css`
  color: mediumBlue;
  background-color: white;
  border-color: mediumBlue;
  :hover {
    color: white;
    background-color: mediumBlue;
    border-color: mediumBlue;
  }
  :active {
    color: white;
    background-color: mediumBlue;
    border-color: mediumBlue;
  }
  :disabled {
    color: white;
    background-color: mediumBlue;
    border-color: mediumBlue;
  }
`;
export const mediumOrchid = css`
  color: mediumOrchid;
  background-color: white;
  border-color: mediumOrchid;
  :hover {
    color: white;
    background-color: mediumOrchid;
    border-color: mediumOrchid;
  }
  :active {
    color: white;
    background-color: mediumOrchid;
    border-color: mediumOrchid;
  }
  :disabled {
    color: white;
    background-color: mediumOrchid;
    border-color: mediumOrchid;
  }
`;
export const mediumPurple = css`
  color: mediumPurple;
  background-color: white;
  border-color: mediumPurple;
  :hover {
    color: white;
    background-color: mediumPurple;
    border-color: mediumPurple;
  }
  :active {
    color: white;
    background-color: mediumPurple;
    border-color: mediumPurple;
  }
  :disabled {
    color: white;
    background-color: mediumPurple;
    border-color: mediumPurple;
  }
`;
export const mediumSeaGreen = css`
  color: mediumSeaGreen;
  background-color: white;
  border-color: mediumSeaGreen;
  :hover {
    color: white;
    background-color: mediumSeaGreen;
    border-color: mediumSeaGreen;
  }
  :active {
    color: white;
    background-color: mediumSeaGreen;
    border-color: mediumSeaGreen;
  }
  :disabled {
    color: white;
    background-color: mediumSeaGreen;
    border-color: mediumSeaGreen;
  }
`;
export const mediumSlateBlue = css`
  color: mediumSlateBlue;
  background-color: white;
  border-color: mediumSlateBlue;
  :hover {
    color: white;
    background-color: mediumSlateBlue;
    border-color: mediumSlateBlue;
  }
  :active {
    color: white;
    background-color: mediumSlateBlue;
    border-color: mediumSlateBlue;
  }
  :disabled {
    color: white;
    background-color: mediumSlateBlue;
    border-color: mediumSlateBlue;
  }
`;
export const mediumSpringGreen = css`
  color: mediumSpringGreen;
  background-color: white;
  border-color: mediumSpringGreen;
  :hover {
    color: white;
    background-color: mediumSpringGreen;
    border-color: mediumSpringGreen;
  }
  :active {
    color: white;
    background-color: mediumSpringGreen;
    border-color: mediumSpringGreen;
  }
  :disabled {
    color: white;
    background-color: mediumSpringGreen;
    border-color: mediumSpringGreen;
  }
`;
export const mediumTurquoise = css`
  color: mediumTurquoise;
  background-color: white;
  border-color: mediumTurquoise;
  :hover {
    color: white;
    background-color: mediumTurquoise;
    border-color: mediumTurquoise;
  }
  :active {
    color: white;
    background-color: mediumTurquoise;
    border-color: mediumTurquoise;
  }
  :disabled {
    color: white;
    background-color: mediumTurquoise;
    border-color: mediumTurquoise;
  }
`;
export const mediumVioletRed = css`
  color: mediumVioletRed;
  background-color: white;
  border-color: mediumVioletRed;
  :hover {
    color: white;
    background-color: mediumVioletRed;
    border-color: mediumVioletRed;
  }
  :active {
    color: white;
    background-color: mediumVioletRed;
    border-color: mediumVioletRed;
  }
  :disabled {
    color: white;
    background-color: mediumVioletRed;
    border-color: mediumVioletRed;
  }
`;
export const midnightBlue = css`
  color: midnightBlue;
  background-color: white;
  border-color: midnightBlue;
  :hover {
    color: white;
    background-color: midnightBlue;
    border-color: midnightBlue;
  }
  :active {
    color: white;
    background-color: midnightBlue;
    border-color: midnightBlue;
  }
  :disabled {
    color: white;
    background-color: midnightBlue;
    border-color: midnightBlue;
  }
`;
export const mintCream = css`
  color: mintCream;
  background-color: white;
  border-color: mintCream;
  :hover {
    color: white;
    background-color: mintCream;
    border-color: mintCream;
  }
  :active {
    color: white;
    background-color: mintCream;
    border-color: mintCream;
  }
  :disabled {
    color: white;
    background-color: mintCream;
    border-color: mintCream;
  }
`;
export const mistyRose = css`
  color: mistyRose;
  background-color: white;
  border-color: mistyRose;
  :hover {
    color: white;
    background-color: mistyRose;
    border-color: mistyRose;
  }
  :active {
    color: white;
    background-color: mistyRose;
    border-color: mistyRose;
  }
  :disabled {
    color: white;
    background-color: mistyRose;
    border-color: mistyRose;
  }
`;
export const moccasin = css`
  color: moccasin;
  background-color: white;
  border-color: moccasin;
  :hover {
    color: white;
    background-color: moccasin;
    border-color: moccasin;
  }
  :active {
    color: white;
    background-color: moccasin;
    border-color: moccasin;
  }
  :disabled {
    color: white;
    background-color: moccasin;
    border-color: moccasin;
  }
`;
export const navajoWhite = css`
  color: navajoWhite;
  background-color: white;
  border-color: navajoWhite;
  :hover {
    color: white;
    background-color: navajoWhite;
    border-color: navajoWhite;
  }
  :active {
    color: white;
    background-color: navajoWhite;
    border-color: navajoWhite;
  }
  :disabled {
    color: white;
    background-color: navajoWhite;
    border-color: navajoWhite;
  }
`;
export const navy = css`
  color: navy;
  background-color: white;
  border-color: navy;
  :hover {
    color: white;
    background-color: navy;
    border-color: navy;
  }
  :active {
    color: white;
    background-color: navy;
    border-color: navy;
  }
  :disabled {
    color: white;
    background-color: navy;
    border-color: navy;
  }
`;
export const oldLace = css`
  color: oldLace;
  background-color: white;
  border-color: oldLace;
  :hover {
    color: white;
    background-color: oldLace;
    border-color: oldLace;
  }
  :active {
    color: white;
    background-color: oldLace;
    border-color: oldLace;
  }
  :disabled {
    color: white;
    background-color: oldLace;
    border-color: oldLace;
  }
`;
export const olive = css`
  color: olive;
  background-color: white;
  border-color: olive;
  :hover {
    color: white;
    background-color: olive;
    border-color: olive;
  }
  :active {
    color: white;
    background-color: olive;
    border-color: olive;
  }
  :disabled {
    color: white;
    background-color: olive;
    border-color: olive;
  }
`;
export const oliveDrab = css`
  color: oliveDrab;
  background-color: white;
  border-color: oliveDrab;
  :hover {
    color: white;
    background-color: oliveDrab;
    border-color: oliveDrab;
  }
  :active {
    color: white;
    background-color: oliveDrab;
    border-color: oliveDrab;
  }
  :disabled {
    color: white;
    background-color: oliveDrab;
    border-color: oliveDrab;
  }
`;
export const orange = css`
  color: orange;
  background-color: white;
  border-color: orange;
  :hover {
    color: white;
    background-color: orange;
    border-color: orange;
  }
  :active {
    color: white;
    background-color: orange;
    border-color: orange;
  }
  :disabled {
    color: white;
    background-color: orange;
    border-color: orange;
  }
`;
export const orangeRed = css`
  color: orangeRed;
  background-color: white;
  border-color: orangeRed;
  :hover {
    color: white;
    background-color: orangeRed;
    border-color: orangeRed;
  }
  :active {
    color: white;
    background-color: orangeRed;
    border-color: orangeRed;
  }
  :disabled {
    color: white;
    background-color: orangeRed;
    border-color: orangeRed;
  }
`;
export const orchid = css`
  color: orchid;
  background-color: white;
  border-color: orchid;
  :hover {
    color: white;
    background-color: orchid;
    border-color: orchid;
  }
  :active {
    color: white;
    background-color: orchid;
    border-color: orchid;
  }
  :disabled {
    color: white;
    background-color: orchid;
    border-color: orchid;
  }
`;
export const paleGoldenRod = css`
  color: paleGoldenRod;
  background-color: white;
  border-color: paleGoldenRod;
  :hover {
    color: white;
    background-color: paleGoldenRod;
    border-color: paleGoldenRod;
  }
  :active {
    color: white;
    background-color: paleGoldenRod;
    border-color: paleGoldenRod;
  }
  :disabled {
    color: white;
    background-color: paleGoldenRod;
    border-color: paleGoldenRod;
  }
`;
export const paleGreen = css`
  color: paleGreen;
  background-color: white;
  border-color: paleGreen;
  :hover {
    color: white;
    background-color: paleGreen;
    border-color: paleGreen;
  }
  :active {
    color: white;
    background-color: paleGreen;
    border-color: paleGreen;
  }
  :disabled {
    color: white;
    background-color: paleGreen;
    border-color: paleGreen;
  }
`;
export const paleTurquoise = css`
  color: paleTurquoise;
  background-color: white;
  border-color: paleTurquoise;
  :hover {
    color: white;
    background-color: paleTurquoise;
    border-color: paleTurquoise;
  }
  :active {
    color: white;
    background-color: paleTurquoise;
    border-color: paleTurquoise;
  }
  :disabled {
    color: white;
    background-color: paleTurquoise;
    border-color: paleTurquoise;
  }
`;
export const paleVioletRed = css`
  color: paleVioletRed;
  background-color: white;
  border-color: paleVioletRed;
  :hover {
    color: white;
    background-color: paleVioletRed;
    border-color: paleVioletRed;
  }
  :active {
    color: white;
    background-color: paleVioletRed;
    border-color: paleVioletRed;
  }
  :disabled {
    color: white;
    background-color: paleVioletRed;
    border-color: paleVioletRed;
  }
`;
export const papayaWhip = css`
  color: papayaWhip;
  background-color: white;
  border-color: papayaWhip;
  :hover {
    color: white;
    background-color: papayaWhip;
    border-color: papayaWhip;
  }
  :active {
    color: white;
    background-color: papayaWhip;
    border-color: papayaWhip;
  }
  :disabled {
    color: white;
    background-color: papayaWhip;
    border-color: papayaWhip;
  }
`;
export const peachPuff = css`
  color: peachPuff;
  background-color: white;
  border-color: peachPuff;
  :hover {
    color: white;
    background-color: peachPuff;
    border-color: peachPuff;
  }
  :active {
    color: white;
    background-color: peachPuff;
    border-color: peachPuff;
  }
  :disabled {
    color: white;
    background-color: peachPuff;
    border-color: peachPuff;
  }
`;
export const peru = css`
  color: peru;
  background-color: white;
  border-color: peru;
  :hover {
    color: white;
    background-color: peru;
    border-color: peru;
  }
  :active {
    color: white;
    background-color: peru;
    border-color: peru;
  }
  :disabled {
    color: white;
    background-color: peru;
    border-color: peru;
  }
`;
export const pink = css`
  color: pink;
  background-color: white;
  border-color: pink;
  :hover {
    color: white;
    background-color: pink;
    border-color: pink;
  }
  :active {
    color: white;
    background-color: pink;
    border-color: pink;
  }
  :disabled {
    color: white;
    background-color: pink;
    border-color: pink;
  }
`;
export const plum = css`
  color: plum;
  background-color: white;
  border-color: plum;
  :hover {
    color: white;
    background-color: plum;
    border-color: plum;
  }
  :active {
    color: white;
    background-color: plum;
    border-color: plum;
  }
  :disabled {
    color: white;
    background-color: plum;
    border-color: plum;
  }
`;
export const powderBlue = css`
  color: powderBlue;
  background-color: white;
  border-color: powderBlue;
  :hover {
    color: white;
    background-color: powderBlue;
    border-color: powderBlue;
  }
  :active {
    color: white;
    background-color: powderBlue;
    border-color: powderBlue;
  }
  :disabled {
    color: white;
    background-color: powderBlue;
    border-color: powderBlue;
  }
`;
export const purple = css`
  color: purple;
  background-color: white;
  border-color: purple;
  :hover {
    color: white;
    background-color: purple;
    border-color: purple;
  }
  :active {
    color: white;
    background-color: purple;
    border-color: purple;
  }
  :disabled {
    color: white;
    background-color: purple;
    border-color: purple;
  }
`;
export const rebeccaPurple = css`
  color: rebeccaPurple;
  background-color: white;
  border-color: rebeccaPurple;
  :hover {
    color: white;
    background-color: rebeccaPurple;
    border-color: rebeccaPurple;
  }
  :active {
    color: white;
    background-color: rebeccaPurple;
    border-color: rebeccaPurple;
  }
  :disabled {
    color: white;
    background-color: rebeccaPurple;
    border-color: rebeccaPurple;
  }
`;
export const red = css`
  color: red;
  background-color: white;
  border-color: red;
  :hover {
    color: white;
    background-color: red;
    border-color: red;
  }
  :active {
    color: white;
    background-color: red;
    border-color: red;
  }
  :disabled {
    color: white;
    background-color: red;
    border-color: red;
  }
`;
export const rosyBrown = css`
  color: rosyBrown;
  background-color: white;
  border-color: rosyBrown;
  :hover {
    color: white;
    background-color: rosyBrown;
    border-color: rosyBrown;
  }
  :active {
    color: white;
    background-color: rosyBrown;
    border-color: rosyBrown;
  }
  :disabled {
    color: white;
    background-color: rosyBrown;
    border-color: rosyBrown;
  }
`;
export const royalBlue = css`
  color: royalBlue;
  background-color: white;
  border-color: royalBlue;
  :hover {
    color: white;
    background-color: royalBlue;
    border-color: royalBlue;
  }
  :active {
    color: white;
    background-color: royalBlue;
    border-color: royalBlue;
  }
  :disabled {
    color: white;
    background-color: royalBlue;
    border-color: royalBlue;
  }
`;
export const saddleBrown = css`
  color: saddleBrown;
  background-color: white;
  border-color: saddleBrown;
  :hover {
    color: white;
    background-color: saddleBrown;
    border-color: saddleBrown;
  }
  :active {
    color: white;
    background-color: saddleBrown;
    border-color: saddleBrown;
  }
  :disabled {
    color: white;
    background-color: saddleBrown;
    border-color: saddleBrown;
  }
`;
export const salmon = css`
  color: salmon;
  background-color: white;
  border-color: salmon;
  :hover {
    color: white;
    background-color: salmon;
    border-color: salmon;
  }
  :active {
    color: white;
    background-color: salmon;
    border-color: salmon;
  }
  :disabled {
    color: white;
    background-color: salmon;
    border-color: salmon;
  }
`;
export const sandyBrown = css`
  color: sandyBrown;
  background-color: white;
  border-color: sandyBrown;
  :hover {
    color: white;
    background-color: sandyBrown;
    border-color: sandyBrown;
  }
  :active {
    color: white;
    background-color: sandyBrown;
    border-color: sandyBrown;
  }
  :disabled {
    color: white;
    background-color: sandyBrown;
    border-color: sandyBrown;
  }
`;
export const seaGreen = css`
  color: seaGreen;
  background-color: white;
  border-color: seaGreen;
  :hover {
    color: white;
    background-color: seaGreen;
    border-color: seaGreen;
  }
  :active {
    color: white;
    background-color: seaGreen;
    border-color: seaGreen;
  }
  :disabled {
    color: white;
    background-color: seaGreen;
    border-color: seaGreen;
  }
`;
export const seaShell = css`
  color: seaShell;
  background-color: white;
  border-color: seaShell;
  :hover {
    color: white;
    background-color: seaShell;
    border-color: seaShell;
  }
  :active {
    color: white;
    background-color: seaShell;
    border-color: seaShell;
  }
  :disabled {
    color: white;
    background-color: seaShell;
    border-color: seaShell;
  }
`;
export const sienna = css`
  color: sienna;
  background-color: white;
  border-color: sienna;
  :hover {
    color: white;
    background-color: sienna;
    border-color: sienna;
  }
  :active {
    color: white;
    background-color: sienna;
    border-color: sienna;
  }
  :disabled {
    color: white;
    background-color: sienna;
    border-color: sienna;
  }
`;
export const silver = css`
  color: silver;
  background-color: white;
  border-color: silver;
  :hover {
    color: white;
    background-color: silver;
    border-color: silver;
  }
  :active {
    color: white;
    background-color: silver;
    border-color: silver;
  }
  :disabled {
    color: white;
    background-color: silver;
    border-color: silver;
  }
`;
export const skyBlue = css`
  color: skyBlue;
  background-color: white;
  border-color: skyBlue;
  :hover {
    color: white;
    background-color: skyBlue;
    border-color: skyBlue;
  }
  :active {
    color: white;
    background-color: skyBlue;
    border-color: skyBlue;
  }
  :disabled {
    color: white;
    background-color: skyBlue;
    border-color: skyBlue;
  }
`;
export const slateBlue = css`
  color: slateBlue;
  background-color: white;
  border-color: slateBlue;
  :hover {
    color: white;
    background-color: slateBlue;
    border-color: slateBlue;
  }
  :active {
    color: white;
    background-color: slateBlue;
    border-color: slateBlue;
  }
  :disabled {
    color: white;
    background-color: slateBlue;
    border-color: slateBlue;
  }
`;
export const slateGray = css`
  color: slateGray;
  background-color: white;
  border-color: slateGray;
  :hover {
    color: white;
    background-color: slateGray;
    border-color: slateGray;
  }
  :active {
    color: white;
    background-color: slateGray;
    border-color: slateGray;
  }
  :disabled {
    color: white;
    background-color: slateGray;
    border-color: slateGray;
  }
`;
export const slateGrey = css`
  color: slateGrey;
  background-color: white;
  border-color: slateGrey;
  :hover {
    color: white;
    background-color: slateGrey;
    border-color: slateGrey;
  }
  :active {
    color: white;
    background-color: slateGrey;
    border-color: slateGrey;
  }
  :disabled {
    color: white;
    background-color: slateGrey;
    border-color: slateGrey;
  }
`;
export const snow = css`
  color: snow;
  background-color: white;
  border-color: snow;
  :hover {
    color: white;
    background-color: snow;
    border-color: snow;
  }
  :active {
    color: white;
    background-color: snow;
    border-color: snow;
  }
  :disabled {
    color: white;
    background-color: snow;
    border-color: snow;
  }
`;
export const springGreen = css`
  color: springGreen;
  background-color: white;
  border-color: springGreen;
  :hover {
    color: white;
    background-color: springGreen;
    border-color: springGreen;
  }
  :active {
    color: white;
    background-color: springGreen;
    border-color: springGreen;
  }
  :disabled {
    color: white;
    background-color: springGreen;
    border-color: springGreen;
  }
`;
export const steelBlue = css`
  color: steelBlue;
  background-color: white;
  border-color: steelBlue;
  :hover {
    color: white;
    background-color: steelBlue;
    border-color: steelBlue;
  }
  :active {
    color: white;
    background-color: steelBlue;
    border-color: steelBlue;
  }
  :disabled {
    color: white;
    background-color: steelBlue;
    border-color: steelBlue;
  }
`;
export const tan = css`
  color: tan;
  background-color: white;
  border-color: tan;
  :hover {
    color: white;
    background-color: tan;
    border-color: tan;
  }
  :active {
    color: white;
    background-color: tan;
    border-color: tan;
  }
  :disabled {
    color: white;
    background-color: tan;
    border-color: tan;
  }
`;
export const teal = css`
  color: teal;
  background-color: white;
  border-color: teal;
  :hover {
    color: white;
    background-color: teal;
    border-color: teal;
  }
  :active {
    color: white;
    background-color: teal;
    border-color: teal;
  }
  :disabled {
    color: white;
    background-color: teal;
    border-color: teal;
  }
`;
export const thistle = css`
  color: thistle;
  background-color: white;
  border-color: thistle;
  :hover {
    color: white;
    background-color: thistle;
    border-color: thistle;
  }
  :active {
    color: white;
    background-color: thistle;
    border-color: thistle;
  }
  :disabled {
    color: white;
    background-color: thistle;
    border-color: thistle;
  }
`;
export const tomato = css`
  color: tomato;
  background-color: white;
  border-color: tomato;
  :hover {
    color: white;
    background-color: tomato;
    border-color: tomato;
  }
  :active {
    color: white;
    background-color: tomato;
    border-color: tomato;
  }
  :disabled {
    color: white;
    background-color: tomato;
    border-color: tomato;
  }
`;
export const turquoise = css`
  color: turquoise;
  background-color: white;
  border-color: turquoise;
  :hover {
    color: white;
    background-color: turquoise;
    border-color: turquoise;
  }
  :active {
    color: white;
    background-color: turquoise;
    border-color: turquoise;
  }
  :disabled {
    color: white;
    background-color: turquoise;
    border-color: turquoise;
  }
`;
export const violet = css`
  color: violet;
  background-color: white;
  border-color: violet;
  :hover {
    color: white;
    background-color: violet;
    border-color: violet;
  }
  :active {
    color: white;
    background-color: violet;
    border-color: violet;
  }
  :disabled {
    color: white;
    background-color: violet;
    border-color: violet;
  }
`;
export const wheat = css`
  color: wheat;
  background-color: white;
  border-color: wheat;
  :hover {
    color: white;
    background-color: wheat;
    border-color: wheat;
  }
  :active {
    color: white;
    background-color: wheat;
    border-color: wheat;
  }
  :disabled {
    color: white;
    background-color: wheat;
    border-color: wheat;
  }
`;
export const white = css`
  color: white;
  background-color: white;
  border-color: white;
  :hover {
    color: white;
    background-color: white;
    border-color: white;
  }
  :active {
    color: white;
    background-color: white;
    border-color: white;
  }
  :disabled {
    color: white;
    background-color: white;
    border-color: white;
  }
`;
export const whiteSmoke = css`
  color: whiteSmoke;
  background-color: white;
  border-color: whiteSmoke;
  :hover {
    color: white;
    background-color: whiteSmoke;
    border-color: whiteSmoke;
  }
  :active {
    color: white;
    background-color: whiteSmoke;
    border-color: whiteSmoke;
  }
  :disabled {
    color: white;
    background-color: whiteSmoke;
    border-color: whiteSmoke;
  }
`;
export const yellow = css`
  color: yellow;
  background-color: white;
  border-color: yellow;
  :hover {
    color: white;
    background-color: yellow;
    border-color: yellow;
  }
  :active {
    color: white;
    background-color: yellow;
    border-color: yellow;
  }
  :disabled {
    color: white;
    background-color: yellow;
    border-color: yellow;
  }
`;
export const yellowGreen = css`
  color: yellowGreen;
  background-color: white;
  border-color: yellowGreen;
  :hover {
    color: white;
    background-color: yellowGreen;
    border-color: yellowGreen;
  }
  :active {
    color: white;
    background-color: yellowGreen;
    border-color: yellowGreen;
  }
  :disabled {
    color: white;
    background-color: yellowGreen;
    border-color: yellowGreen;
  }
`;
