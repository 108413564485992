import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import styled from "styled-components";
import api from "../../../config/api";
import schemas from "../../../config/schemas";
import http from "../../../services/httpService";
import AppBody from "../../common/AppBody";
import AppButton from "../../common/AppButton";
import Form from "../../form/form";
import Loading from "../../common/loading";
import Page from "../../common/Page";
import Navigation from "./navigation";
import Users from "./users";

class browser extends Form {
  state = {
    data: {
      search: "",
    },
    users: [],
    errors: {},
    firstLoad: true,
  };
  schema = {
    search: schemas.search,
  };

  source = http.source();
  unmounting = false;

  async getUsers() {
    try {
      if (!this.state.firstLoad) this.setState({ firstLoad: true });
      const { data: users } = await http.get(api.getUser, {
        cancelToken: this.source.token,
      });
      this.setState({
        firstLoad: false,
        users: users,
      });
    } catch (ex) {
      if (this.unmounting) return;
      this.setState({
        firstLoad: false,
      });
    }
  }
  async componentDidMount() {
    this.getUsers();
  }
  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }

  doSubmit = async () => {};

  renderPageContent() {
    const page = this.props.match.params.page;

    if (page == null || page === "popular")
      return <h2 className="text-center text-muted">Coming soon...</h2>;

    if (page === "artists" || page === "users")
      return <Users search={this.state.data.search} type={page} />;

    if (page === "arts")
      return <h2 className="text-center text-muted">Coming soon...</h2>;

    if (page === "posts")
      return <h2 className="text-center text-muted">Coming soon...</h2>;

    return "";
  }
  render() {
    return (
      <Page title="Browser">
        <Helmet></Helmet>
        <AppBody>
          <Loading visible={this.state.firstLoad} />

          <div className="container-fluid mt-2 px-2 px-md-4">
            {!this.state.firstLoad && (
              <div className="row m-0 justify-content-center">
                <form
                  onSubmit={this.handleSubmit}
                  className="col-lg-8 px-0 mb-2"
                >
                  <div className="input-group flex-nowrap">
                    {this.renderInput("search", "Search")}
                    <span
                      className="input-group-text p-0 bg-none"
                      id="addon-wrapping"
                    >
                      <SearchButton
                        type="submit"
                        color="rebeccaPurple"
                        filled
                        outlined
                        className="h-100"
                        id="search"
                      >
                        <i className="fas fa-search" aria-hidden="true" />
                      </SearchButton>
                    </span>
                  </div>
                </form>

                <div className="col-12"></div>
                <div className="col-md-auto col-12 shadow">
                  <Navigation />
                </div>
                <hr className="d-block d-md-none my-2" />
                <div className="col shadow ms-0 ms-md-2 mt-0 mt-md-2 position-relative">
                  {this.renderPageContent()}
                </div>
              </div>
            )}
          </div>
        </AppBody>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});

const SearchButton = styled(AppButton)`
  padding: 0.2rem 1rem !important;
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;
export default connect(mapStateToProps)(browser);
