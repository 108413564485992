const badgesStyles = {
  artist: {
    bgColor: "primary",
    title: "Artist",
    content: "artist",
  },
  user: {
    bgColor: "grey",
    title: "User",
    content: "user",
  },
  approved: {
    bgColor: "lightBlue",
    title: "Approved user",
  },
  vip: {
    bgColor: "appGold",
    title: "VIP user",
    content: (
      <>
        <i className="fas fa-crown"></i> VIP
      </>
    ),
  },
  supporter: {
    bgColor: "appGold",
    title: "Supporter",
    content: (
      <>
        <i className="fas fa-hand-holding-usd"></i> Supporter
      </>
    ),
  },
};
export default badgesStyles;
