import React from "react";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Form from "../../form/form";
import Page from "../../common/Page";
import Loading from "../../common/loading";
import http from "../../../services/httpService";
import api from "../../../config/api";
import schemas from "../../../config/schemas";
import Badge from "../../common/badge";
import AppBody from "../../common/AppBody";
import ProfileBanner from "../../profile/profileBanner";
import Avatar from "../../profile/avatar";
import ProfileNav from "../../profile/profileNav";
import ProfliePageContainer from "../../profile/ProfliePageContainer";
import styled from "styled-components";
import FollowButton from "../../profile/followButton";
import MessageButton from "../../profile/messageButton";
import { device } from "../../../config/styles/devicesStyle";
import { socketFeatures, useSocket } from "../../../features/socket/socket";
import Posts from "./posts";
import Arts from "./arts";
import IsApproved from "../../common/IsApproved";
import About from "./about";
import { getPrettyDate } from "../../../libs/dateFunctions";

class profile extends Form {
  state = {
    user: {},
    errors: {},
    loading: true,
    firstLoad: true,
    newPost: false,
  };
  source = http.source();
  unmounting = false;
  socket = useSocket();

  async getUser() {
    try {
      if (!this.state.firstLoad) this.setState({ firstLoad: true });
      const username = this.props.match.params.username;
      const { error } = Joi.validate(username, schemas.username);

      if (error) window.location = "/404";

      const { data: user } = await http.get(api.getUser + username, {
        cancelToken: this.source.token,
      });

      if (this.props.currentUser._id === user._id) user.isOnline = true;

      this.setState({
        firstLoad: false,
        loading: false,
        user: user,
      });
      if (this.props.currentUser && this.socket) {
        socketFeatures("SUBTODOC", "profile-" + user._id);

        this.socket.on("docChange", (data) => {
          this.dataUpdate(data);
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        window.location = "/404";
      }
    }
  }
  async componentDidMount() {
    this.getUser();
  }
  dataUpdate = (response) => {
    const { document, documentId, updateAction } = response;
    const user = { ...this.state.user };
    /** Profile update */
    if (this.unmounting) return;
    if (document === "user" && documentId === user._id) {
      switch (updateAction) {
        case "setOnline":
          user.isOnline = true;
          break;
        case "setOffline":
          user.isOnline = false;
          break;

        default:
          break;
      }
      this.setState({ user: user });
    }
    return;
  };
  componentDidUpdate(prevProps) {
    if (this.unmounting) return;

    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.getUser();
    }
  }
  componentWillUnmount() {
    this.unmounting = true;
    this.setState({
      firstLoad: false,
    });
    if (this.props.currentUser) {
      socketFeatures("UNSUBTODOC", "profile-" + this.state.user._id);
    }
    this.source.cancel();
  }
  renderPageContent(user) {
    if (user.username !== this.props.match.params.username) return "";

    let page = this.props.match.params.page;
    const { art } = this.props.match.params;
    const accuntType = this.state.user.accountType;

    if (!page) {
      page = accuntType === "artist" ? "arts" : "posts";
    }

    if (page === "arts")
      return <Arts arts={user.arts} userId={user._id} artId={art} />;

    if (page === "posts") return <Posts posts={user.posts} userId={user._id} />;

    if (page === "about") return <About user={user} />;

    return "";
  }
  render() {
    const { accountType, username } = this.state.user;
    const user = this.state.user;
    const followers = (user.followers && user.followers.length) || "0";
    const following = (user.following && user.following.length) || "0";
    return (
      <Page title="Profile">
        <AppBody>
          <Loading
            visible={this.state.firstLoad}
            style={{ backgroundColor: "rgba(255,255,255,0)" }}
          />

          <div className="container p-0 shadow">
            {!this.state.firstLoad && (
              <section>
                <div className="row m-0">
                  <ProfileBanner user={user} />
                </div>
                <div className="row m-0 px-md-4 px-0">
                  <Avatar user={user} />
                  <div className="col-md mt-0 mt-md-2">
                    <div className="row m-0">
                      <ButtonContainer className="col-auto order-md-2 me-0 me-md-2">
                        <FollowButton user={user} />
                      </ButtonContainer>
                      <div className="col-md order-1">
                        <p className="m-0">
                          <span className="fs-3 position-relative">
                            @{username}
                            <OnlineIndicator
                              bg={user.isOnline ? "success" : "danger"}
                              title={user.isOnline ? "Online" : "Offline"}
                              className="p-1 position-absolute"
                            >
                              {" "}
                            </OnlineIndicator>
                          </span>
                          {this.props.currentUser.username === "artisle" &&
                            user.lastOnline &&
                            " " + getPrettyDate(user.lastOnline)}

                          <IsApproved
                            type="approved"
                            approved={user.isApproved}
                          />
                        </p>
                        <Badge type={accountType} popular={true} />

                        <Badge type="vip" />
                        <Badge type="supporter" />
                      </div>

                      <ButtonContainer className="col-auto ms-auto order-md-2 text-end">
                        <MessageButton user={user} />
                      </ButtonContainer>
                    </div>
                  </div>
                </div>
                <div className="row m-0 mt-2">
                  <div className="col-auto">{followers} followers</div>
                  <div className="col-auto p-0">|</div>
                  <div className="col-auto">{following} following</div>
                  <div className="col">Support</div>
                </div>
                <div className="row m-0 mt-2">
                  <ProfileNav user={user} />
                </div>
                <ProfliePageContainer>
                  {this.renderPageContent(this.state.user)}
                </ProfliePageContainer>
              </section>
            )}
          </div>
        </AppBody>
      </Page>
    );
  }
}

const ButtonContainer = styled.div`
  padding: 0 !important;
  display: flex;
  @media ${device.mobileM} {
    margin-top: -65px;
  }
`;

const OnlineIndicator = styled(Badge)`
  top: 50%;
  left: -0.8rem;
`;
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});
export default connect(mapStateToProps)(profile);
