import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { validImage } from "../../../libs/image";
import AppButton from "../../common/AppButton";
import Alert from "../../form/alert";

const ImageUpload = ({ form }) => {
  const [error, setError] = useState();
  const [images, setImages] = useState([]);

  useEffect(() => {
    const currentImages = [...form.state.images];
    if (currentImages && currentImages.length > 0) {
      setImages(currentImages);
    }
  }, [form.state.images]);

  const onDrop = ({ currentTarget: input }) => {
    if (!input.files || input.files.length < 1) return;
    let formImages = [...form.state.images];
    const allowed = 5 - formImages.length;

    for (
      let index = 0;
      index < input.files.length && index < allowed;
      index++
    ) {
      const element = input.files[index];
      if (validImage(element, setError)) formImages.push(element);
    }

    form.setState({ images: formImages });
    setImages([...images, ...formImages]);
    input.value = "";
  };
  const onDelete = (key) => {
    let formImages = [...form.state.images];
    let currentImage = [...images];

    formImages.splice(key, 1);
    currentImage.splice(key, 1);

    form.setState({ images: formImages });
    setImages(currentImage);
  };

  return (
    <div className="col-12">
      <input
        type="file"
        id="images"
        accept="image/png, image/webp, image/jpeg"
        onChange={onDrop}
        className="d-none"
        multiple
      />
      {error && <Alert title="Oups" message={error} type="danger" />}
      <Container className="row">
        {images.length < 1 && (
          <h4 className="text-muted text-center mt-2">
            Select at least one image
          </h4>
        )}

        {images &&
          images.map((image, key) => {
            return (
              <ImageContainer className="col-auto mt-2" key={key}>
                <img
                  alt={image.name}
                  src={URL.createObjectURL(image)}
                  className="img-fluid"
                />
                <DeleteBtn onClick={() => onDelete(key)}>
                  <i className="fas fa-times"></i>
                </DeleteBtn>
              </ImageContainer>
            );
          })}
      </Container>
      <UploadLabel className="px-3 py-2" htmlFor="images">
        Select images
      </UploadLabel>

      <NextBtn
        title="next"
        color="rebeccaPurple"
        outlined
        disabled={images.length < 1}
        onClick={() => {
          form.setState({ currentPage: 2 });
        }}
      />
    </div>
  );
};
const Container = styled.div`
  justify-content: center;
  margin: 0;
  margin-top: 1rem;
  border: 1px solid #dee2e6;
`;
const ImageContainer = styled.div`
  position: relative;
  img {
    max-height: 350px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #dee2e6;
    padding: 0.3rem;
  }
`;
const DeleteBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #dee2e6;
  display: flex;
  i {
    color: brown;
    font-size: 20px;
    margin: auto;
  }
`;
const UploadLabel = styled.label`
  color: rebeccaPurple;
  background-color: white;
  border: 2px solid rebeccaPurple;
  text-transform: uppercase !important;
  margin: 1rem auto;
  display: block;
  width: fit-content;
  :hover {
    color: white;
    background-color: rebeccaPurple;
    border-color: rebeccaPurple;
  }
`;
const NextBtn = styled(AppButton)`
  margin: 1rem auto;
  display: flex;
`;
export default ImageUpload;
