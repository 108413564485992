import { store } from "../../features/store";
import { addNotification } from "../userSlice";
import { socketAddress } from "../../config";
import io from "socket.io-client";

let socket = false;
export function initSocket() {
  socket = io.connect(socketAddress, {
    extraHeaders: {
      "x-auth-token": localStorage.getItem("userToken-artisle"),
    },
  });
  socket.on("notification", (notification) => {
    store.dispatch(addNotification(notification));
  });
}
export function useSocket() {
  return socket;
}
export function socketFeatures(feature, data = false) {
  switch (feature) {
    case "SUBTODOC":
      if (!data) break;
      if (socket) socket.emit("subscribeToDb", data);
      break;
    case "UNSUBTODOC":
      if (!data) break;
      if (socket) socket.emit("unsubscribeToDb", data);

      break;
    default:
    // code block
  }
}
// eslint-disable-next-line
export default {
  initSocket,
  socketFeatures,
};
