import React from "react";

const ImageView = ({ children, style, className }) => {
  const openImg = () => {
    const wrapper = document.getElementById("imageViewWrapper");
    wrapper.classList.contains("d-none") && wrapper.classList.remove("d-none");
    const ImgView = document.getElementById("imageView");
    if (ImgView) ImgView.src = children.props.src;
  };

  return (
    <div style={style} className={className} onClick={openImg}>
      {children}
    </div>
  );
};

export default ImageView;
