import React, { Fragment } from "react";
import Alert from "./alert";

const Input = ({
  name,
  label,
  error,
  prepend,
  append,
  col = "col-md-9 col-8",
  className,
  required = false,
  ...rest
}) => {
  const classList = className
    ? "form-floating mb-3 " + className
    : "form-floating mb-3";
  return (
    <Fragment>
      <div className={classList}>
        <input
          className="form-control"
          id={name}
          name={name}
          required={required}
          {...rest}
        />
        <label htmlFor={name} required={required}>
          {label}
        </label>
        {error && <Alert title="Oops" type="danger" message={error} />}
      </div>
    </Fragment>
  );
};

export default Input;
