const size = {
  mobileS: "575.98px",
  mobileM: "767.98px",
  mobileL: "991.98px",
  tablet: "1199.98px",
  desktop: "1399.98px",
  desktopXL: "2559.98px",
};
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopXL: `(max-width: ${size.desktopXL})`,
};
