import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "../../common/infiniteScroll";
import AppButton from "../../common/AppButton";
import ArtCard from "../../common/artCard";

const Arts = ({ arts, userId, artId = false }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const history = useHistory();

  function renderArts(arts) {
    if (!arts) return "No arts to display";
    if (arts.length < 1) return "No arts to display";
    return (
      <InfiniteScroll
        items={arts}
        chunkLength={9}
        display={(art) => {
          let classList = "col-md-6 col-lg-4 p-1 order-2";
          if (artId && artId === art) classList = "col-12 p-0 order-1";

          return (
            <div className={classList} id={art} key={art}>
              <ArtCard artId={art} full={artId === art} />
            </div>
          );
        }}
        end={() => {
          return (
            <div className="col-12 order-3">
              <h5 className="text-center text-muted">No more arts</h5>
            </div>
          );
        }}
      />
    );
  }

  return (
    <>
      {currentUser && currentUser._id === userId && (
        <div className="col col-md-8 col-xl-6 text-center mt-2 mx-auto">
          <AppButton
            color="dimGrey"
            title="New Art"
            filled
            outlined
            onClick={() => history.push("/new-art")}
          />
        </div>
      )}
      <div className="col-12"></div>

      <div className="row m-0 p-0 overflow-hidden pt-3">{renderArts(arts)}</div>
    </>
  );
};

export default Arts;
