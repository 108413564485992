import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { siteAddress } from "../../config";
import NavItem from "./navItem";
import UserInfo from "./userInfo";
import UserNavigator from "./userNavigation";
import styled from "styled-components";

const NavBar = () => {
  const [display, setDisplay] = useState("");
  const currentUser = useSelector((state) => state.currentUser.data);

  const collapse = () => {
    if (display) {
      setDisplay("");
    } else {
      setDisplay("active");
    }
  };

  return (
    <div className="position-relative">
      <NavContainer className="App-navbar">
        <div className="row m-0 h-100 align-items-center">
          <div className="navList">
            <NavLink to="/" exact={true} className="p-0 col-auto text-center">
              <img
                src={siteAddress + "assets/icon-art.png"}
                alt="Logo"
                height="40px"
                style={{ marginTop: "5px" }}
              />
            </NavLink>
            <div className="text-white d-flex">
              <span className="display-6 my-auto">Artisle</span>
            </div>
          </div>
          {currentUser ? (
            <>
              <UserInfo className="d-flex d-lg-none top-0" />

              <UserNavigator />
            </>
          ) : (
            <>
              <button
                className={
                  "navbar-toggler d-block d-md-none text-white che " + display
                }
                type="button"
                onClick={collapse}
              >
                <i className={"fas fa-bars che openMenu " + display}></i>
              </button>
              <div
                className={
                  "d-none d-md-flex navItems justify-content-center justify-content-md-end " +
                  display
                }
              >
                <button
                  className={
                    "navbar-toggler d-block d-md-none text-white che " + display
                  }
                  type="button"
                  onClick={collapse}
                >
                  <i
                    className={"fas fas fa-times che closeMenu " + display}
                  ></i>
                </button>

                <NavItem to="/" exact={true}>
                  Home
                </NavItem>
                <NavItem to="/login">Login</NavItem>
                <NavItem to="/register">Register</NavItem>
                <NavItem to="/about-us">Contributors</NavItem>
              </div>
            </>
          )}
        </div>
      </NavContainer>
    </div>
  );
};

const NavContainer = styled.nav`
  position: fixed;
  z-index: 1000;
  top: 0px;
  background-color: var(--color-darkBlue);
  height: 150px;
  background-image: ${'url("' + siteAddress + 'assets/navbg.png");'};
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: bottom;
  height: 80px;
  width: 100%;
  max-width: 1920px;
`;
export default NavBar;
