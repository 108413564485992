import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../features/userSlice";
import { useState } from "react";
import styled, { css } from "styled-components";
import { device } from "../../config/styles/devicesStyle";
import api from "../../config/api";
import http from "../../services/httpService";
import Alert from "../form/alert";
import { siteAddress } from "../../config";
import { validImage } from "../../libs/image";

const ProfileBanner = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [deleted, setDeleted] = useState();
  const [error, setError] = useState();

  const [newBanner, setBanner] = useState();

  const onFileChange = ({ currentTarget: input }) => {
    if (!input.files[0]) return setError("Something went wrong!");

    if (!validImage(input.files[0])) return;

    setDeleted(false);
    setBanner(URL.createObjectURL(input.files[0]));
    setFile(input.files[0]);
  };

  const handleSubmit = async (e) => {
    try {
      if (!validImage(file)) return;

      const formData = new FormData();
      formData.append("banner", file);
      const { data: newImage } = await http.post(api.uploadBanner, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setDeleted(false);
      setFile(false);
      setError(false);

      dispatch(updateUser({ ...currentUser, banner: newImage }));
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
      }
    }
  };
  const deleteBanner = async (e) => {
    try {
      if (!window.confirm(`Are you sure to delete this banner?`)) return;
      if (!currentUser.banner) return setDeleted(true);

      const { data: response } = await http.post(api.deleteBanner);

      if (response === "OK") {
        setDeleted(true);
        dispatch(updateUser({ ...currentUser, banner: "" }));
      } else {
        setError("Something went wrong!");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setError("Something went wrong!");
      } else {
        setError("Something went wrong!");
      }
    }
  };
  if (user._id === currentUser._id) {
    return (
      <>
        <Banner
          banner={user.banner}
          newBanner={newBanner}
          deleted={deleted}
          className="col-12"
        >
          <BannerEdit htmlFor="banner">
            <i className="fas fa-images"></i> Change picture
          </BannerEdit>
          {(currentUser.banner || newBanner) && (
            <BannerDelete type="submit" onClick={deleteBanner}>
              <i className="fas fa-trash-alt"></i>
            </BannerDelete>
          )}
          {file && (
            <BannerSubmit type="submit" disabled={!file} onClick={handleSubmit}>
              <i className="fas fa-save"></i>
            </BannerSubmit>
          )}
          {error && <Alert title="Oups" message={error} type="danger" />}
        </Banner>
        <input
          type="file"
          id="banner"
          accept="image/png, image/webp, image/jpeg"
          onChange={onFileChange}
          className="d-none"
        />
      </>
    );
  } else {
    return <Banner banner={user.banner} className="col-12"></Banner>;
  }
};

const Banner = styled.div`
  padding: 0;
  position: relative;
  background-color: antiquewhite;
  background-size: cover;
  background-position: center;
  height: 200px;
  ${({ banner, newBanner }) =>
    banner || newBanner
      ? css`
          background-image: ${({ banner }) =>
            banner &&
            'url("' +
              siteAddress +
              "images/userImages/banners/" +
              banner +
              '")'};
          background-image: ${({ newBanner }) =>
            newBanner && 'url("' + newBanner + '")'};

          height: 500px;
          @media ${device.desktop} {
            height: 431.8px !important;
          }
          @media ${device.tablet} {
            height: 393.6px !important;
          }
          @media ${device.mobileL} {
            height: 252.7px !important;
          }
          @media ${device.mobileM} {
            height: 204.5px !important;
          }
          @media ${device.mobileS} {
            height: calc(100vw * 0.378) !important;
          }
        `
      : css`
          @media ${device.desktop} {
            height: 200px !important;
          }
          @media ${device.mobileL} {
            height: 120px !important;
          }
          @media ${device.mobileS} {
            height: 100px !important;
          }
        `}
  background-image: ${({ deleted }) => deleted && "none"};
  :hover > button,
  :hover > label,
  :focus > button,
  :focus > label {
    display: block;
  }
`;
const baseButton = css`
  display: none;
  font-size: 14pt;
  border-radius: 0.5rem;
  padding: 0.2rem 0.8rem;
  right: 15px;
  bottom: 5px;
  background-color: #bfbfbf;
  opacity: 0.7;
  position: absolute;
  text-align: center;
  &:hover {
    opacity: 1;
  }
  @media ${device.mobileM} {
    font-size: 9pt;
    padding: 0.2rem;
    border-radius: 0.2rem;
  }
`;
const BannerEdit = styled.label`
  ${baseButton}
`;
const BannerSubmit = styled.button`
  ${baseButton}
  bottom: 45px;
  right: 70px;
  font-size: 14pt;
  padding: 0.2rem 1rem;
  background-color: darkslateblue;
  color: white;
  border: none;
  @media ${device.mobileM} {
    bottom: 35px;
    font-size: 14pt;
    padding: 0.2rem 0.8rem;
  }
`;
const BannerDelete = styled.button`
  ${baseButton}
  bottom: 45px;
  right: 15px;
  font-size: 14pt;
  padding: 0.2rem 1rem;
  background-color: brown;
  color: white;
  border: none;
  @media ${device.mobileM} {
    bottom: 35px;
    font-size: 14pt;
    padding: 0.2rem 0.8rem;
  }
`;
export default ProfileBanner;
