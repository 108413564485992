import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../config/api";
import { getPrettyDate } from "../../libs/dateFunctions";
import http from "../../services/httpService";
import Avatar from "./Avatar";

const CommentItem = ({ comment, postId }) => {
  const { message, createdAt, author } = comment;
  const currentUser = useSelector((state) => state.currentUser.data);
  const onDelete = async () => {
    try {
      if (!postId || !comment) return;
      if (!window.confirm(`Are you sure to delete this comment?`)) return;
      const data = {
        comment,
        postId,
      };

      await http.post(api.deleteComment, data);
      toast.success("Comment deleted");
    } catch (ex) {}
  };
  if (message == null) return "";
  return (
    <Container>
      <CommentAvater size="30" img={author.avatar} />
      <Item>
        <span
          className="w-100 d-block"
          style={{ lineHeight: "6pt", paddingTop: "6px" }}
        >
          <AuthorItem to="/">{author.username}</AuthorItem>
        </span>

        {message}
      </Item>
      <DateItem>{createdAt && getPrettyDate(createdAt)}</DateItem>
      {currentUser._id === author._id && (
        <EditDropDownBox className="dropdown">
          <EditDropDownBtn
            className="btn btn-light dropdown-toggle"
            type="button"
            id={`ddm-${1}`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></EditDropDownBtn>
          <ul className="dropdown-menu" aria-labelledby={`ddm-${1}`}>
            <li>
              <span className="dropdown-item cursor-pointer">Edit</span>
            </li>
            <li onClick={onDelete}>
              <span className="dropdown-item">Delete</span>
            </li>
          </ul>
        </EditDropDownBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  position: relative;
`;
const Item = styled.div`
  padding: 0.5rem 0.8rem;
  background-color: #00000012;
  border-radius: 1rem;
  flex: 1;
`;
const CommentAvater = styled(Avatar)`
  margin-top: 6px;
`;
const DateItem = styled.span`
  position: absolute;
  font-size: 7pt;
  bottom: 0;
  right: 10px;
`;
const AuthorItem = styled(NavLink)`
  text-decoration: none;
`;
const EditDropDownBox = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  > * {
    cursor: pointer !important;
  }
  ul {
    padding: 0 !important;
    inset: -10px auto auto 0px !important;
  }
  span {
    font-size: 8pt;
    padding: 0.25rem 1rem;
  }
`;
const EditDropDownBtn = styled.button`
  background-color: transparent !important;
  border: none;
  :hover {
    background-color: transparent !important;
  }
`;
export default CommentItem;
