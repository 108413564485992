import React from "react";

import { Helmet } from "react-helmet-async";
import AppBody from "../common/AppBody";
import Form from "../form/form";
import Page from "../common/Page";

class pageNotFound extends Form {
  render() {
    return (
      <Page title="Page not found">
        <Helmet></Helmet>
        <AppBody>
          <section>
            <div className="container-fluid">
              <div className="row m-0">
                <div className="col"></div>
                <div className="col-auto">Page not found</div>
                <div className="col"></div>
              </div>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}

export default pageNotFound;
