import React from "react";
import styled from "styled-components";
import { device } from "../../config/styles/devicesStyle";

const Container = styled.div`
  position: relative;
  background-color: rgb(22, 22, 22);
  justify-content: center;
  text-align: center;
  color: white;
  margin: 0;
`;
const Author = styled.div`
  text-align: right;
  position: absolute;
  bottom: 0;
  padding: 0;
  @media ${device.mobileL} {
    text-align: center;
    position: relative;
  }
`;
const Footer = (props) => {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <Container className="row">
      <div className="col-md-9 p-4 pb-1">
        <h2 className="mb-1">Artisle - The online art network</h2>
        <p className="m-0" style={{ color: "grey" }}></p>

        <button
          type="button"
          className="btn btn-purple px-5 py-3 mb-4 d-none"
          data-bs-toggle="modal"
          data-bs-target="#contactModal"
        >
          Online Form
        </button>

        <h5 className="fs-2 m-0">
          <i className="fab fa-facebook-f px-2"></i>
          <a
            href="https://www.instagram.com/artisle.online/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <i className="fab fa-instagram px-2"></i>
          </a>
          <i className="fab fa-twitter px-2"></i>
          <i className="fab fa-discord px-2"></i>
        </h5>
      </div>
      <div className="col-md-10 ">
        <small>Ⓒ 2021 - {year} | Artisle | All Rights Reserved</small>
      </div>
      <Author>
        Designed by:
        <a
          href="https://zaiintech.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white pe-4"
        >
          <img
            src="https://bayleaf-janitorial.com/public/images/ztechLogo.png"
            alt="ZaiinTech"
            className="zLogo"
            height="20px"
          />
        </a>
      </Author>
    </Container>
  );
};

export default Footer;
