import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({
  children,
  className = "px-3 text-center navLink",
  disabled,
  ...props
}) => {
  if (disabled) className = className + " disabled";
  return (
    <NavLink {...props} className={className} style={{ height: "max-content" }}>
      <li className="nav-item">{children}</li>
    </NavLink>
  );
};

export default NavItem;
