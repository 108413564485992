import Joi from "joi-browser";

const schemas = {
  search: Joi.string().label("Search"),
  accountType: Joi.string().required().label("Account type"),
  categories: Joi.array().items(Joi.string()).label("Category"),
  username: Joi.string()
    .min(6)
    .max(25)
    .regex(/^([a-zA-Z0-9]+([_-]?[a-zA-Z0-9]{1,}){0,2})[a-zA-Z0-9]?$/)
    .required()
    .label("Username")
    .options({
      language: {
        string: {
          regex: {
            base: "is invalid",
          },
        },
      },
    }),
  email: Joi.string()
    .regex(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    .required()
    .label("Email")
    .options({
      language: {
        string: {
          regex: {
            base: "is invalid",
          },
        },
      },
    }),
  gender: Joi.string().required().label("gender"),
  password: Joi.string()
    .regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
    .required()
    .label("Password")
    .options({
      language: {
        string: {
          regex: {
            base: "Must be at least 8 character, including at least 1 capital, 1 number, 1 special character",
          },
        },
      },
    }),
  confirmPassword: Joi.string().label("Confirm Password"),
  bio: Joi.string().max(500).allow(""),
};
export default schemas;
