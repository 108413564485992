import React, { Fragment } from "react";

const Switch = ({
  name,
  label,
  error,
  prepend,
  append,
  col = "col-md-9 col-8",
  className,
  required = false,
  ...rest
}) => {
  const classList = className
    ? "form-check form-switch " + className
    : "form-check form-switch";
  return (
    <Fragment>
      <div className={classList}>
        <input
          className="form-check-input"
          type="checkbox"
          id={name}
          name={name}
          {...rest}
        />
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      </div>
    </Fragment>
  );
};

export default Switch;
