import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import api from "../../config/api";
import { device } from "../../config/styles/devicesStyle";
import { updateUser } from "../../features/userSlice";
import httpService from "../../services/httpService";
import AppButton from "../common/AppButton";

const FollowButton = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const dispatch = useDispatch();
  const [following, setFollowing] = useState(false);

  useEffect(() => {
    if (!currentUser.following) return;

    const following = [...currentUser.following];

    if (following.includes(user._id.toString())) setFollowing(true);
  }, [currentUser.following, user._id]);

  if (!user || !user.username) return null;
  if (user.username === currentUser.username) return null;

  const handleClick = async () => {
    try {
      const { data: response } = await httpService.put(api.followUser, {
        username: user.username,
      });
      dispatch(updateUser({ ...currentUser, following: response }));
      if (response.includes(user._id.toString())) setFollowing(true);
      else setFollowing(false);
    } catch (ex) {}
  };

  return (
    <Button
      title={following ? "Following" : "Follow"}
      color="rebeccaPurple"
      outlined={following}
      onClick={handleClick}
      disabled={!currentUser}
    />
  );
};

const Button = styled(AppButton)`
  @media ${device.mobileS} {
    margin: auto;
    padding: 0.3rem 0.5rem !important;
    font-size: 1rem !important;
  }
`;

export default FollowButton;
