const colors = {
  primary: "rebeccapurple",
  primaryContra: "white",

  appGold: "#c19403",
  goldOpa: "#c19503d2",

  aliceBlue: "aliceBlue",
  antiqueWhite: "antiqueWhite",
  aqua: "aqua",
  aquamarine: "aquamarine",
  azure: "azure",
  beige: "beige",
  bisque: "bisque",
  black: "black",
  blanchedAlmond: "blanchedAlmond",
  blue: "blue",
  blueViolet: "blueViolet",
  brown: "brown",
  burlyWood: "burlyWood",
  cadetBlue: "cadetBlue",
  chartreuse: "chartreuse",
  chocolate: "chocolate",
  coral: "coral",
  cornflowerBlue: "cornflowerBlue",
  cornsilk: "cornsilk",
  crimson: "crimson",
  cyan: "cyan",
  darkBlue: "darkBlue",
  darkCyan: "darkCyan",
  darkGoldenRod: "darkGoldenRod",
  darkGray: "darkGray",
  darkGrey: "darkGrey",
  darkGreen: "darkGreen",
  darkKhaki: "darkKhaki",
  darkMagenta: "darkMagenta",
  darkOliveGreen: "darkOliveGreen",
  darkOrange: "darkOrange",
  darkOrchid: "darkOrchid",
  darkRed: "darkRed",
  darkSalmon: "darkSalmon",
  darkSeaGreen: "darkSeaGreen",
  darkSlateBlue: "darkSlateBlue",
  darkSlateGray: "darkSlateGray",
  darkSlateGrey: "darkSlateGrey",
  darkTurquoise: "darkTurquoise",
  darkViolet: "darkViolet",
  deepPink: "deepPink",
  deepSkyBlue: "deepSkyBlue",
  dimGray: "dimGray",
  dimGrey: "dimGrey",
  dodgerBlue: "dodgerBlue",
  fireBrick: "fireBrick",
  floralWhite: "floralWhite",
  forestGreen: "forestGreen",
  fuchsia: "fuchsia",
  gainsboro: "gainsboro",
  ghostWhite: "ghostWhite",
  gold: "gold",
  goldenRod: "goldenRod",
  gray: "gray",
  grey: "grey",
  green: "green",
  greenYellow: "greenYellow",
  honeyDew: "honeyDew",
  hotPink: "hotPink",
  indianRed: "indianRed",
  indigo: "indigo",
  ivory: "ivory",
  khaki: "khaki",
  lavender: "lavender",
  lavenderBlush: "lavenderBlush",
  lawnGreen: "lawnGreen",
  lemonChiffon: "lemonChiffon",
  lightBlue: "lightBlue",
  lightCoral: "lightCoral",
  lightCyan: "lightCyan",
  lightGoldenRodYellow: "lightGoldenRodYellow",
  lightGray: "lightGray",
  lightGrey: "lightGrey",
  lightGreen: "lightGreen",
  lightPink: "lightPink",
  lightSalmon: "lightSalmon",
  lightSeaGreen: "lightSeaGreen",
  lightSkyBlue: "lightSkyBlue",
  lightSlateGray: "lightSlateGray",
  lightSlateGrey: "lightSlateGrey",
  lightSteelBlue: "lightSteelBlue",
  lightYellow: "lightYellow",
  lime: "lime",
  limeGreen: "limeGreen",
  linen: "linen",
  magenta: "magenta",
  maroon: "maroon",
  mediumAquaMarine: "mediumAquaMarine",
  mediumBlue: "mediumBlue",
  mediumOrchid: "mediumOrchid",
  mediumPurple: "mediumPurple",
  mediumSeaGreen: "mediumSeaGreen",
  mediumSlateBlue: "mediumSlateBlue",
  mediumSpringGreen: "mediumSpringGreen",
  mediumTurquoise: "mediumTurquoise",
  mediumVioletRed: "mediumVioletRed",
  midnightBlue: "midnightBlue",
  mintCream: "mintCream",
  mistyRose: "mistyRose",
  moccasin: "moccasin",
  navajoWhite: "navajoWhite",
  navy: "navy",
  oldLace: "oldLace",
  olive: "olive",
  oliveDrab: "oliveDrab",
  orange: "orange",
  orangeRed: "orangeRed",
  orchid: "orchid",
  paleGoldenRod: "paleGoldenRod",
  paleGreen: "paleGreen",
  paleTurquoise: "paleTurquoise",
  paleVioletRed: "paleVioletRed",
  papayaWhip: "papayaWhip",
  peachPuff: "peachPuff",
  peru: "peru",
  pink: "pink",
  plum: "plum",
  powderBlue: "powderBlue",
  purple: "purple",
  rebeccaPurple: "rebeccaPurple",
  red: "red",
  rosyBrown: "rosyBrown",
  royalBlue: "royalBlue",
  saddleBrown: "saddleBrown",
  salmon: "salmon",
  sandyBrown: "sandyBrown",
  seaGreen: "seaGreen",
  seaShell: "seaShell",
  sienna: "sienna",
  silver: "silver",
  skyBlue: "skyBlue",
  slateBlue: "slateBlue",
  slateGray: "slateGray",
  slateGrey: "slateGrey",
  snow: "snow",
  springGreen: "springGreen",
  steelBlue: "steelBlue",
  tan: "tan",
  teal: "teal",
  thistle: "thistle",
  tomato: "tomato",
  turquoise: "turquoise",
  violet: "violet",
  wheat: "wheat",
  white: "white",
  whiteSmoke: "whiteSmoke",
  yellow: "yellow",
  yellowGreen: "yellowGreen",
};
export default colors;
