import React, { Fragment } from "react";
import Alert from "./alert";
const Select = ({
  children,
  name,
  label,
  error,
  value = "",
  onChange,
  required,
  ...rest
}) => {
  return (
    <Fragment>
      <div className="form-floating mb-3">
        <select
          className="form-select"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          {...rest}
        >
          {children}
        </select>
        <label htmlFor={name} required={required}>
          {label}
        </label>
        {error && <Alert title="Oops" type="danger" message={error} />}
      </div>
    </Fragment>
  );
};

export default Select;
