import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { siteAddress } from "../../config";
import { device } from "../../config/styles/devicesStyle";
import EditDropDown from "./editDropDown";
import Avatar from "./Avatar";
import Carousel from "./Carousel";

import { useSelector } from "react-redux";
import httpService from "../../services/httpService";
import api from "../../config/api";
import { socketFeatures, useSocket } from "../../features/socket/socket";
import { likeFunction } from "../../libs/likeFunction";
import { getPrettyDate } from "../../libs/dateFunctions";
import { css } from "styled-components";
import Loading from "./loading";
import ShareButton from "./shareButton";
import Comments from "./comments";

const PostCard = ({ postId }) => {
  const [currentPost, setCurrentPost] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.currentUser.data);
  const mounted = useRef(false);
  const stateRef = useRef();
  stateRef.current = currentPost;
  const socket = useSocket();

  useEffect(() => {
    mounted.current = true;
    getPost();

    if (socket && currentUser && currentPost) {
      socketFeatures("SUBTODOC", "post-" + postId);
      if (currentPost.type === "art" && currentPost.art)
        socketFeatures("SUBTODOC", "art-" + currentPost.art._id);
      socket.on("docChange", (data) => {
        dataUpdate(data);
      });
    }
    return () => {
      source.cancel();
      mounted.current = false;
      if (currentUser && currentPost) {
        socketFeatures("UNSUBTODOC", "post-" + currentPost._id);
        if (currentPost.type === "art" && currentPost.art)
          socketFeatures("UNSUBTODOC", "art-" + currentPost.art._id);
      }
      if (socket) socket.removeAllListeners("docChange");
    };
    // eslint-disable-next-line
  }, [postId, currentPost.type]);
  const source = httpService.source();

  const getPost = async () => {
    try {
      const { data: post } = await httpService.get(api.getPost + postId, {
        cancelToken: source.token,
      });
      setCurrentPost(post);
      setLoading(false);
    } catch (ex) {
      if (!mounted.current) return;
      setCurrentPost(false);
      setLoading(false);
    }
  };
  const dataUpdate = (response) => {
    const { document, documentId, updateAction, updateDocumentTo } = response;
    /** Post update */
    if (!mounted.current) return;
    if (document === "post" && documentId === postId) {
      if (updateAction === "updateDocumentTo") {
        setCurrentPost(updateDocumentTo);
      }
      /** Add comment */
      if (updateAction === "addComment") {
        const post = JSON.parse(JSON.stringify(stateRef.current));
        post.comments.reverse();
        post.comments.push(updateDocumentTo);
        setCurrentPost(post);
      }
      /** DeleteComment */
      if (updateAction === "deleteComment") {
        const post = JSON.parse(JSON.stringify(stateRef.current));
        const updatedComments = post.comments.filter(
          (comment) => comment._id !== updateDocumentTo
        );

        post.comments = updatedComments.reverse();
        setCurrentPost(post);
      }
    }
    /** Art update */
    if (document === "art" && documentId === currentPost.art._id) {
      if (updateAction === "updateDocumentTo") {
        let updatedPost = { ...currentPost };
        updatedPost.art = updateDocumentTo;
        setCurrentPost(updatedPost);
      }
    }
    return;
  };

  const likePost = async () => {
    try {
      if (currentPost.type === "art") {
        await likeFunction(currentUser, currentPost.art, "art");
      }
    } catch (ex) {}
  };
  const prettyDate = useMemo(
    () => getPrettyDate(currentPost.createdAt),
    [currentPost.createdAt]
  );
  const renderImages = (images) => {
    let imagesSrc = [];
    for (let index = 0; index < images.length; index++) {
      const image = images[index];

      imagesSrc.push(
        siteAddress + "images/posts/" + currentPost.art._id + "/" + image
      );
    }
    return imagesSrc;
  };

  if (deleted)
    return (
      <Container className="shadow card">
        <CardText className="my-auto">Post deleted</CardText>
      </Container>
    );
  if (!currentPost && !loading) return "";
  if (!currentPost && loading)
    return (
      <Container className="shadow card">
        <Loading
          style={InProgress}
          visible={true}
          fixed={false}
          lottieStyle={LottieStyle}
        />
      </Container>
    );

  const liked = () => {
    if (!currentUser || !currentPost) return false;
    if (currentPost.type === "art") {
      if (!currentPost.art) return false;
      if (!currentPost.art.likes) return false;

      if (!currentPost.art.likes.includes(currentUser._id)) return false;

      return true;
    } else {
      if (!currentPost.likes) return false;

      if (!currentPost.likes.includes(currentUser._id)) return false;
      return true;
    }
  };
  const ownPost = currentUser
    ? currentUser._id === currentPost.author._id
    : false;

  return (
    <Container className="shadow card">
      {loading && (
        <Loading
          style={InProgress}
          visible={true}
          fixed={false}
          lottieStyle={LottieStyle}
        />
      )}
      {currentPost && currentPost.art && (
        <>
          <Header>
            <Box>
              <Avatar
                img={currentPost.author.avatar}
                alt={currentPost.author.username}
              />
            </Box>
            <Box>
              <UserName to={"/" + currentPost.author.username}>
                {currentPost.author.username}
              </UserName>
              <DateText>{prettyDate}</DateText>
            </Box>
            {currentPost.art && (
              <Box>
                <mark>{currentPost.art.category}</mark>
                <MediumText>{currentPost.art.medium}</MediumText>
                <EditDropDown
                  item={currentPost}
                  deleteAction={setDeleted}
                  deleteinProgress={setLoading}
                />
              </Box>
            )}
          </Header>
          {currentPost.message && (
            <MessageBox>{currentPost.message}</MessageBox>
          )}
          <hr className="m-0" />
          {currentPost.art.title && (
            <>
              <CardText>{currentPost.art.title}</CardText>
            </>
          )}
          {currentPost.art && (
            <Carousel
              id={
                "post-" +
                currentPost.author.username +
                "-" +
                currentPost._id.toString()
              }
              images={renderImages(currentPost.art.pictures)}
            />
          )}

          <ActionButtons>
            <LikeButton
              outlined
              color="rebeccaPurple"
              onClick={!ownPost ? likePost : null}
            >
              <>
                <span>
                  {currentPost && currentPost.art
                    ? currentPost.art.likes.length
                    : currentPost.likes.length}
                </span>{" "}
                <i
                  className={
                    liked() || ownPost ? "fas fa-heart" : "far fa-heart"
                  }
                ></i>
                <span>{liked() ? "Liked" : "Like"}</span>
              </>
            </LikeButton>

            <ShareButton id={currentPost.art._id} />
            <LikeButton>
              <i className="fas fa-star text-gold"></i> <span>Stars</span>
            </LikeButton>
          </ActionButtons>

          {currentPost.commentsEnabled && (
            <PostCardButtom>
              <Comments
                commentsData={currentPost.comments}
                postId={currentPost._id}
              />
            </PostCardButtom>
          )}
        </>
      )}
      {!currentPost.art && (
        <>
          <Header>
            <Box>
              <Avatar
                img={currentPost.author.avatar}
                alt={currentPost.author.username}
              />
            </Box>
            <Box>
              <UserName to={"/" + currentPost.author.username}>
                {currentPost.author.username}
              </UserName>
              <DateText>{prettyDate}</DateText>
            </Box>

            <Box>
              <EditDropDown
                item={currentPost}
                deleteAction={setDeleted}
                deleteinProgress={setLoading}
              />
            </Box>
          </Header>
          <CardText className="my-auto">This Art has been deleted</CardText>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  :first-child {
    margin-top: 1rem !important;
  }
  width: 100%;
  min-height: 150px;
  margin-bottom: 1rem;
  padding: 0.1rem;

  border: 1px solid #dee2e6;
  img {
    width: 100%;
  }
`;
const Header = styled.div`
  padding: 0.4rem 0.6rem;
  display: flex;
`;
const Box = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  :last-child {
    font-size: 13pt;
    min-width: 1px;
    min-height: 32px;
    margin: auto 0;
    margin-left: auto;
  }
  mark {
    color: rebeccapurple;
    font-weight: 600 !important;
  }
`;
const UserName = styled(NavLink)`
  font-size: 14pt;
  font-weight: 300 !important;
  margin-left: 0.4rem;
  color: inherit;
  text-decoration: none;
`;
const DateText = styled.span`
  font-size: 9pt;
  color: grey;
  margin-left: 0.4rem;
`;
const MediumText = styled.span`
  font-size: 9pt;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150%;
  position: absolute;
  bottom: -9pt;
  right: 0;
`;
const MessageBox = styled.p`
  padding: 0.4rem;
  margin: 0;
`;
const CardText = styled.p`
  padding: 0.4rem 70px;
  padding-bottom: 0;
  text-align: center;
  color: #6c757d !important;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #bfc2c6;
  padding: 0.1rem 0;
  font-size: 1rem;
`;
const PostCardButtom = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #bfc2c6;
`;
const LikeButton = styled.button`
  background: none;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  margin: 0 !important;

  color: dimgray;
  :hover {
    background-color: #ecf0f4;
  }
  i {
    margin: auto;
    margin-right: 2px;
  }
  @media ${device.mobileM} {
    span:last-child {
      display: none;
    }
    padding: 0.4rem 1.2rem;
  }
`;
const InProgress = css`
  background-color: #ffffff98 !important;
  min-height: auto !important;
`;
const LottieStyle = css`
  top: 0 !important;
  height: 100% !important;
  width: auto !important;
  margin-top: 0 !important;
`;
export default PostCard;
