import { css } from "styled-components";

export const aliceBlue = css`
  color: black;
  background-color: aliceBlue;
  border-color: black;
  :hover {
    color: aliceBlue;
    background-color: aliceBlue;
    border-color: aliceBlue;
  }
  :active {
    color: aliceBlue;
    background-color: aliceBlue;
    border-color: aliceBlue;
  }
`;
export const antiqueWhite = css`
  color: white;
  background-color: antiqueWhite;
  border-color: antiqueWhite;
  :hover {
    color: antiqueWhite;
    background-color: antiqueWhite;
    border-color: antiqueWhite;
  }
  :active {
    color: antiqueWhite;
    background-color: antiqueWhite;
    border-color: antiqueWhite;
  }
`;
export const aqua = css`
  color: white;
  background-color: aqua;
  border-color: aqua;
  :hover {
    color: aqua;
    background-color: aqua;
    border-color: aqua;
  }
  :active {
    color: aqua;
    background-color: aqua;
    border-color: aqua;
  }
`;
export const aquamarine = css`
  color: white;
  background-color: aquamarine;
  border-color: aquamarine;
  :hover {
    color: aquamarine;
    background-color: aquamarine;
    border-color: aquamarine;
  }
  :active {
    color: aquamarine;
    background-color: aquamarine;
    border-color: aquamarine;
  }
`;
export const azure = css`
  color: white;
  background-color: azure;
  border-color: azure;
  :hover {
    color: azure;
    background-color: azure;
    border-color: azure;
  }
  :active {
    color: azure;
    background-color: azure;
    border-color: azure;
  }
`;
export const beige = css`
  color: white;
  background-color: beige;
  border-color: beige;
  :hover {
    color: beige;
    background-color: beige;
    border-color: beige;
  }
  :active {
    color: beige;
    background-color: beige;
    border-color: beige;
  }
`;
export const bisque = css`
  color: white;
  background-color: bisque;
  border-color: bisque;
  :hover {
    color: bisque;
    background-color: bisque;
    border-color: bisque;
  }
  :active {
    color: bisque;
    background-color: bisque;
    border-color: bisque;
  }
`;
export const black = css`
  color: white;
  background-color: black;
  border-color: black;
  :hover {
    color: black;
    background-color: black;
    border-color: black;
  }
  :active {
    color: black;
    background-color: black;
    border-color: black;
  }
`;
export const blanchedAlmond = css`
  color: white;
  background-color: blanchedAlmond;
  border-color: blanchedAlmond;
  :hover {
    color: blanchedAlmond;
    background-color: blanchedAlmond;
    border-color: blanchedAlmond;
  }
  :active {
    color: blanchedAlmond;
    background-color: blanchedAlmond;
    border-color: blanchedAlmond;
  }
`;
export const blue = css`
  color: white;
  background-color: blue;
  border-color: blue;
  :hover {
    color: blue;
    background-color: blue;
    border-color: blue;
  }
  :active {
    color: blue;
    background-color: blue;
    border-color: blue;
  }
`;
export const blueViolet = css`
  color: white;
  background-color: blueViolet;
  border-color: blueViolet;
  :hover {
    color: blueViolet;
    background-color: blueViolet;
    border-color: blueViolet;
  }
  :active {
    color: blueViolet;
    background-color: blueViolet;
    border-color: blueViolet;
  }
`;
export const brown = css`
  color: white;
  background-color: brown;
  border-color: brown;
  :hover {
    color: brown;
    background-color: brown;
    border-color: brown;
  }
  :active {
    color: brown;
    background-color: brown;
    border-color: brown;
  }
`;
export const burlyWood = css`
  color: white;
  background-color: burlyWood;
  border-color: burlyWood;
  :hover {
    color: burlyWood;
    background-color: burlyWood;
    border-color: burlyWood;
  }
  :active {
    color: burlyWood;
    background-color: burlyWood;
    border-color: burlyWood;
  }
`;
export const cadetBlue = css`
  color: white;
  background-color: cadetBlue;
  border-color: cadetBlue;
  :hover {
    color: cadetBlue;
    background-color: cadetBlue;
    border-color: cadetBlue;
  }
  :active {
    color: cadetBlue;
    background-color: cadetBlue;
    border-color: cadetBlue;
  }
`;
export const chartreuse = css`
  color: white;
  background-color: chartreuse;
  border-color: chartreuse;
  :hover {
    color: chartreuse;
    background-color: chartreuse;
    border-color: chartreuse;
  }
  :active {
    color: chartreuse;
    background-color: chartreuse;
    border-color: chartreuse;
  }
`;
export const chocolate = css`
  color: white;
  background-color: chocolate;
  border-color: chocolate;
  :hover {
    color: chocolate;
    background-color: chocolate;
    border-color: chocolate;
  }
  :active {
    color: chocolate;
    background-color: chocolate;
    border-color: chocolate;
  }
`;
export const coral = css`
  color: white;
  background-color: coral;
  border-color: coral;
  :hover {
    color: coral;
    background-color: coral;
    border-color: coral;
  }
  :active {
    color: coral;
    background-color: coral;
    border-color: coral;
  }
`;
export const cornflowerBlue = css`
  color: white;
  background-color: cornflowerBlue;
  border-color: cornflowerBlue;
  :hover {
    color: cornflowerBlue;
    background-color: cornflowerBlue;
    border-color: cornflowerBlue;
  }
  :active {
    color: cornflowerBlue;
    background-color: cornflowerBlue;
    border-color: cornflowerBlue;
  }
`;
export const cornsilk = css`
  color: white;
  background-color: cornsilk;
  border-color: cornsilk;
  :hover {
    color: cornsilk;
    background-color: cornsilk;
    border-color: cornsilk;
  }
  :active {
    color: cornsilk;
    background-color: cornsilk;
    border-color: cornsilk;
  }
`;
export const crimson = css`
  color: white;
  background-color: crimson;
  border-color: crimson;
  :hover {
    color: crimson;
    background-color: crimson;
    border-color: crimson;
  }
  :active {
    color: crimson;
    background-color: crimson;
    border-color: crimson;
  }
`;
export const cyan = css`
  color: white;
  background-color: cyan;
  border-color: cyan;
  :hover {
    color: cyan;
    background-color: cyan;
    border-color: cyan;
  }
  :active {
    color: cyan;
    background-color: cyan;
    border-color: cyan;
  }
`;
export const darkBlue = css`
  color: white;
  background-color: darkBlue;
  border-color: darkBlue;
  :hover {
    color: darkBlue;
    background-color: darkBlue;
    border-color: darkBlue;
  }
  :active {
    color: darkBlue;
    background-color: darkBlue;
    border-color: darkBlue;
  }
`;
export const darkCyan = css`
  color: white;
  background-color: darkCyan;
  border-color: darkCyan;
  :hover {
    color: darkCyan;
    background-color: darkCyan;
    border-color: darkCyan;
  }
  :active {
    color: darkCyan;
    background-color: darkCyan;
    border-color: darkCyan;
  }
`;
export const darkGoldenRod = css`
  color: white;
  background-color: darkGoldenRod;
  border-color: darkGoldenRod;
  :hover {
    color: darkGoldenRod;
    background-color: darkGoldenRod;
    border-color: darkGoldenRod;
  }
  :active {
    color: darkGoldenRod;
    background-color: darkGoldenRod;
    border-color: darkGoldenRod;
  }
`;
export const darkGray = css`
  color: white;
  background-color: darkGray;
  border-color: darkGray;
  :hover {
    color: darkGray;
    background-color: darkGray;
    border-color: darkGray;
  }
  :active {
    color: darkGray;
    background-color: darkGray;
    border-color: darkGray;
  }
`;
export const darkGrey = css`
  color: white;
  background-color: darkGrey;
  border-color: darkGrey;
  :hover {
    color: darkGrey;
    background-color: darkGrey;
    border-color: darkGrey;
  }
  :active {
    color: darkGrey;
    background-color: darkGrey;
    border-color: darkGrey;
  }
`;
export const darkGreen = css`
  color: white;
  background-color: darkGreen;
  border-color: darkGreen;
  :hover {
    color: darkGreen;
    background-color: darkGreen;
    border-color: darkGreen;
  }
  :active {
    color: darkGreen;
    background-color: darkGreen;
    border-color: darkGreen;
  }
`;
export const darkKhaki = css`
  color: white;
  background-color: darkKhaki;
  border-color: darkKhaki;
  :hover {
    color: darkKhaki;
    background-color: darkKhaki;
    border-color: darkKhaki;
  }
  :active {
    color: darkKhaki;
    background-color: darkKhaki;
    border-color: darkKhaki;
  }
`;
export const darkMagenta = css`
  color: white;
  background-color: darkMagenta;
  border-color: darkMagenta;
  :hover {
    color: darkMagenta;
    background-color: darkMagenta;
    border-color: darkMagenta;
  }
  :active {
    color: darkMagenta;
    background-color: darkMagenta;
    border-color: darkMagenta;
  }
`;
export const darkOliveGreen = css`
  color: white;
  background-color: darkOliveGreen;
  border-color: darkOliveGreen;
  :hover {
    color: darkOliveGreen;
    background-color: darkOliveGreen;
    border-color: darkOliveGreen;
  }
  :active {
    color: darkOliveGreen;
    background-color: darkOliveGreen;
    border-color: darkOliveGreen;
  }
`;
export const darkOrange = css`
  color: white;
  background-color: darkOrange;
  border-color: darkOrange;
  :hover {
    color: darkOrange;
    background-color: darkOrange;
    border-color: darkOrange;
  }
  :active {
    color: darkOrange;
    background-color: darkOrange;
    border-color: darkOrange;
  }
`;
export const darkOrchid = css`
  color: white;
  background-color: darkOrchid;
  border-color: darkOrchid;
  :hover {
    color: darkOrchid;
    background-color: darkOrchid;
    border-color: darkOrchid;
  }
  :active {
    color: darkOrchid;
    background-color: darkOrchid;
    border-color: darkOrchid;
  }
`;
export const darkRed = css`
  color: white;
  background-color: darkRed;
  border-color: darkRed;
  :hover {
    color: darkRed;
    background-color: darkRed;
    border-color: darkRed;
  }
  :active {
    color: darkRed;
    background-color: darkRed;
    border-color: darkRed;
  }
`;
export const darkSalmon = css`
  color: white;
  background-color: darkSalmon;
  border-color: darkSalmon;
  :hover {
    color: darkSalmon;
    background-color: darkSalmon;
    border-color: darkSalmon;
  }
  :active {
    color: darkSalmon;
    background-color: darkSalmon;
    border-color: darkSalmon;
  }
`;
export const darkSeaGreen = css`
  color: white;
  background-color: darkSeaGreen;
  border-color: darkSeaGreen;
  :hover {
    color: darkSeaGreen;
    background-color: darkSeaGreen;
    border-color: darkSeaGreen;
  }
  :active {
    color: darkSeaGreen;
    background-color: darkSeaGreen;
    border-color: darkSeaGreen;
  }
`;
export const darkSlateBlue = css`
  color: white;
  background-color: darkSlateBlue;
  border-color: darkSlateBlue;
  :hover {
    color: darkSlateBlue;
    background-color: darkSlateBlue;
    border-color: darkSlateBlue;
  }
  :active {
    color: darkSlateBlue;
    background-color: darkSlateBlue;
    border-color: darkSlateBlue;
  }
`;
export const darkSlateGray = css`
  color: white;
  background-color: darkSlateGray;
  border-color: darkSlateGray;
  :hover {
    color: darkSlateGray;
    background-color: darkSlateGray;
    border-color: darkSlateGray;
  }
  :active {
    color: darkSlateGray;
    background-color: darkSlateGray;
    border-color: darkSlateGray;
  }
`;
export const darkSlateGrey = css`
  color: white;
  background-color: darkSlateGrey;
  border-color: darkSlateGrey;
  :hover {
    color: darkSlateGrey;
    background-color: darkSlateGrey;
    border-color: darkSlateGrey;
  }
  :active {
    color: darkSlateGrey;
    background-color: darkSlateGrey;
    border-color: darkSlateGrey;
  }
`;
export const darkTurquoise = css`
  color: white;
  background-color: darkTurquoise;
  border-color: darkTurquoise;
  :hover {
    color: darkTurquoise;
    background-color: darkTurquoise;
    border-color: darkTurquoise;
  }
  :active {
    color: darkTurquoise;
    background-color: darkTurquoise;
    border-color: darkTurquoise;
  }
`;
export const darkViolet = css`
  color: white;
  background-color: darkViolet;
  border-color: darkViolet;
  :hover {
    color: darkViolet;
    background-color: darkViolet;
    border-color: darkViolet;
  }
  :active {
    color: darkViolet;
    background-color: darkViolet;
    border-color: darkViolet;
  }
`;
export const deepPink = css`
  color: white;
  background-color: deepPink;
  border-color: deepPink;
  :hover {
    color: deepPink;
    background-color: deepPink;
    border-color: deepPink;
  }
  :active {
    color: deepPink;
    background-color: deepPink;
    border-color: deepPink;
  }
`;
export const deepSkyBlue = css`
  color: white;
  background-color: deepSkyBlue;
  border-color: deepSkyBlue;
  :hover {
    color: deepSkyBlue;
    background-color: deepSkyBlue;
    border-color: deepSkyBlue;
  }
  :active {
    color: deepSkyBlue;
    background-color: deepSkyBlue;
    border-color: deepSkyBlue;
  }
`;
export const dimGray = css`
  color: white;
  background-color: dimGray;
  border-color: dimGray;
  :hover {
    color: dimGray;
    background-color: dimGray;
    border-color: dimGray;
  }
  :active {
    color: dimGray;
    background-color: dimGray;
    border-color: dimGray;
  }
`;
export const dimGrey = css`
  color: white;
  background-color: dimGrey;
  border-color: dimGrey;
  :hover {
    color: dimGrey;
    background-color: dimGrey;
    border-color: dimGrey;
  }
  :active {
    color: dimGrey;
    background-color: dimGrey;
    border-color: dimGrey;
  }
`;
export const dodgerBlue = css`
  color: white;
  background-color: dodgerBlue;
  border-color: dodgerBlue;
  :hover {
    color: dodgerBlue;
    background-color: dodgerBlue;
    border-color: dodgerBlue;
  }
  :active {
    color: dodgerBlue;
    background-color: dodgerBlue;
    border-color: dodgerBlue;
  }
`;
export const fireBrick = css`
  color: white;
  background-color: fireBrick;
  border-color: fireBrick;
  :hover {
    color: fireBrick;
    background-color: fireBrick;
    border-color: fireBrick;
  }
  :active {
    color: fireBrick;
    background-color: fireBrick;
    border-color: fireBrick;
  }
`;
export const floralWhite = css`
  color: white;
  background-color: floralWhite;
  border-color: floralWhite;
  :hover {
    color: floralWhite;
    background-color: floralWhite;
    border-color: floralWhite;
  }
  :active {
    color: floralWhite;
    background-color: floralWhite;
    border-color: floralWhite;
  }
`;
export const forestGreen = css`
  color: white;
  background-color: forestGreen;
  border-color: forestGreen;
  :hover {
    color: forestGreen;
    background-color: forestGreen;
    border-color: forestGreen;
  }
  :active {
    color: forestGreen;
    background-color: forestGreen;
    border-color: forestGreen;
  }
`;
export const fuchsia = css`
  color: white;
  background-color: fuchsia;
  border-color: fuchsia;
  :hover {
    color: fuchsia;
    background-color: fuchsia;
    border-color: fuchsia;
  }
  :active {
    color: fuchsia;
    background-color: fuchsia;
    border-color: fuchsia;
  }
`;
export const gainsboro = css`
  color: white;
  background-color: gainsboro;
  border-color: gainsboro;
  :hover {
    color: gainsboro;
    background-color: gainsboro;
    border-color: gainsboro;
  }
  :active {
    color: gainsboro;
    background-color: gainsboro;
    border-color: gainsboro;
  }
`;
export const ghostWhite = css`
  color: white;
  background-color: ghostWhite;
  border-color: ghostWhite;

  :active {
    color: ghostWhite;
    background-color: ghostWhite;
    border-color: ghostWhite;
  }
`;
export const gold = css`
  color: white;
  background-color: gold;
  border-color: gold;
  :hover {
    color: gold;
    background-color: gold;
    border-color: gold;
  }
  :active {
    color: gold;
    background-color: gold;
    border-color: gold;
  }
`;
export const goldenRod = css`
  color: white;
  background-color: goldenRod;
  border-color: goldenRod;
  :hover {
    color: goldenRod;
    background-color: goldenRod;
    border-color: goldenRod;
  }
  :active {
    color: goldenRod;
    background-color: goldenRod;
    border-color: goldenRod;
  }
`;
export const gray = css`
  color: white;
  background-color: gray;
  border-color: gray;
  :hover {
    color: gray;
    background-color: gray;
    border-color: gray;
  }
  :active {
    color: gray;
    background-color: gray;
    border-color: gray;
  }
`;
export const grey = css`
  color: white;
  background-color: grey;
  border-color: grey;
  :hover {
    color: grey;
    background-color: grey;
    border-color: grey;
  }
  :active {
    color: grey;
    background-color: grey;
    border-color: grey;
  }
`;
export const green = css`
  color: white;
  background-color: green;
  border-color: green;
  :hover {
    color: green;
    background-color: green;
    border-color: green;
  }
  :active {
    color: green;
    background-color: green;
    border-color: green;
  }
`;
export const greenYellow = css`
  color: white;
  background-color: greenYellow;
  border-color: greenYellow;
  :hover {
    color: greenYellow;
    background-color: greenYellow;
    border-color: greenYellow;
  }
  :active {
    color: greenYellow;
    background-color: greenYellow;
    border-color: greenYellow;
  }
`;
export const honeyDew = css`
  color: white;
  background-color: honeyDew;
  border-color: honeyDew;
  :hover {
    color: honeyDew;
    background-color: honeyDew;
    border-color: honeyDew;
  }
  :active {
    color: honeyDew;
    background-color: honeyDew;
    border-color: honeyDew;
  }
`;
export const hotPink = css`
  color: white;
  background-color: hotPink;
  border-color: hotPink;
  :hover {
    color: hotPink;
    background-color: hotPink;
    border-color: hotPink;
  }
  :active {
    color: hotPink;
    background-color: hotPink;
    border-color: hotPink;
  }
`;
export const indianRed = css`
  color: white;
  background-color: indianRed;
  border-color: indianRed;
  :hover {
    color: indianRed;
    background-color: indianRed;
    border-color: indianRed;
  }
  :active {
    color: indianRed;
    background-color: indianRed;
    border-color: indianRed;
  }
`;
export const indigo = css`
  color: white;
  background-color: indigo;
  border-color: indigo;
  :hover {
    color: indigo;
    background-color: indigo;
    border-color: indigo;
  }
  :active {
    color: indigo;
    background-color: indigo;
    border-color: indigo;
  }
`;
export const ivory = css`
  color: white;
  background-color: ivory;
  border-color: ivory;
  :hover {
    color: ivory;
    background-color: ivory;
    border-color: ivory;
  }
  :active {
    color: ivory;
    background-color: ivory;
    border-color: ivory;
  }
`;
export const khaki = css`
  color: white;
  background-color: khaki;
  border-color: khaki;
  :hover {
    color: khaki;
    background-color: khaki;
    border-color: khaki;
  }
  :active {
    color: khaki;
    background-color: khaki;
    border-color: khaki;
  }
`;
export const lavender = css`
  color: white;
  background-color: lavender;
  border-color: lavender;
  :hover {
    color: lavender;
    background-color: lavender;
    border-color: lavender;
  }
  :active {
    color: lavender;
    background-color: lavender;
    border-color: lavender;
  }
`;
export const lavenderBlush = css`
  color: white;
  background-color: lavenderBlush;
  border-color: lavenderBlush;
  :hover {
    color: lavenderBlush;
    background-color: lavenderBlush;
    border-color: lavenderBlush;
  }
  :active {
    color: lavenderBlush;
    background-color: lavenderBlush;
    border-color: lavenderBlush;
  }
`;
export const lawnGreen = css`
  color: white;
  background-color: lawnGreen;
  border-color: lawnGreen;
  :hover {
    color: lawnGreen;
    background-color: lawnGreen;
    border-color: lawnGreen;
  }
  :active {
    color: lawnGreen;
    background-color: lawnGreen;
    border-color: lawnGreen;
  }
`;
export const lemonChiffon = css`
  color: white;
  background-color: lemonChiffon;
  border-color: lemonChiffon;
  :hover {
    color: lemonChiffon;
    background-color: lemonChiffon;
    border-color: lemonChiffon;
  }
  :active {
    color: lemonChiffon;
    background-color: lemonChiffon;
    border-color: lemonChiffon;
  }
`;
export const lightBlue = css`
  color: white;
  background-color: lightBlue;
  border-color: lightBlue;
  :hover {
    color: lightBlue;
    background-color: lightBlue;
    border-color: lightBlue;
  }
  :active {
    color: lightBlue;
    background-color: lightBlue;
    border-color: lightBlue;
  }
`;
export const lightCoral = css`
  color: white;
  background-color: lightCoral;
  border-color: lightCoral;
  :hover {
    color: lightCoral;
    background-color: lightCoral;
    border-color: lightCoral;
  }
  :active {
    color: lightCoral;
    background-color: lightCoral;
    border-color: lightCoral;
  }
`;
export const lightCyan = css`
  color: white;
  background-color: lightCyan;
  border-color: lightCyan;
  :hover {
    color: lightCyan;
    background-color: lightCyan;
    border-color: lightCyan;
  }
  :active {
    color: lightCyan;
    background-color: lightCyan;
    border-color: lightCyan;
  }
`;
export const lightGoldenRodYellow = css`
  color: white;
  background-color: lightGoldenRodYellow;
  border-color: lightGoldenRodYellow;
  :hover {
    color: lightGoldenRodYellow;
    background-color: lightGoldenRodYellow;
    border-color: lightGoldenRodYellow;
  }
  :active {
    color: lightGoldenRodYellow;
    background-color: lightGoldenRodYellow;
    border-color: lightGoldenRodYellow;
  }
`;
export const lightGray = css`
  color: white;
  background-color: lightGray;
  border-color: lightGray;
  :hover {
    color: lightGray;
    background-color: lightGray;
    border-color: lightGray;
  }
  :active {
    color: lightGray;
    background-color: lightGray;
    border-color: lightGray;
  }
`;
export const lightGrey = css`
  color: white;
  background-color: lightGrey;
  border-color: lightGrey;
  :hover {
    color: lightGrey;
    background-color: lightGrey;
    border-color: lightGrey;
  }
  :active {
    color: lightGrey;
    background-color: lightGrey;
    border-color: lightGrey;
  }
`;
export const lightGreen = css`
  color: white;
  background-color: lightGreen;
  border-color: lightGreen;
  :hover {
    color: lightGreen;
    background-color: lightGreen;
    border-color: lightGreen;
  }
  :active {
    color: lightGreen;
    background-color: lightGreen;
    border-color: lightGreen;
  }
`;
export const lightPink = css`
  color: white;
  background-color: lightPink;
  border-color: lightPink;
  :hover {
    color: lightPink;
    background-color: lightPink;
    border-color: lightPink;
  }
  :active {
    color: lightPink;
    background-color: lightPink;
    border-color: lightPink;
  }
`;
export const lightSalmon = css`
  color: white;
  background-color: lightSalmon;
  border-color: lightSalmon;
  :hover {
    color: lightSalmon;
    background-color: lightSalmon;
    border-color: lightSalmon;
  }
  :active {
    color: lightSalmon;
    background-color: lightSalmon;
    border-color: lightSalmon;
  }
`;
export const lightSeaGreen = css`
  color: white;
  background-color: lightSeaGreen;
  border-color: lightSeaGreen;
  :hover {
    color: lightSeaGreen;
    background-color: lightSeaGreen;
    border-color: lightSeaGreen;
  }
  :active {
    color: lightSeaGreen;
    background-color: lightSeaGreen;
    border-color: lightSeaGreen;
  }
`;
export const lightSkyBlue = css`
  color: white;
  background-color: lightSkyBlue;
  border-color: lightSkyBlue;
  :hover {
    color: lightSkyBlue;
    background-color: lightSkyBlue;
    border-color: lightSkyBlue;
  }
  :active {
    color: lightSkyBlue;
    background-color: lightSkyBlue;
    border-color: lightSkyBlue;
  }
`;
export const lightSlateGray = css`
  color: white;
  background-color: lightSlateGray;
  border-color: lightSlateGray;
  :hover {
    color: lightSlateGray;
    background-color: lightSlateGray;
    border-color: lightSlateGray;
  }
  :active {
    color: lightSlateGray;
    background-color: lightSlateGray;
    border-color: lightSlateGray;
  }
`;
export const lightSlateGrey = css`
  color: white;
  background-color: lightSlateGrey;
  border-color: lightSlateGrey;
  :hover {
    color: lightSlateGrey;
    background-color: lightSlateGrey;
    border-color: lightSlateGrey;
  }
  :active {
    color: lightSlateGrey;
    background-color: lightSlateGrey;
    border-color: lightSlateGrey;
  }
`;
export const lightSteelBlue = css`
  color: white;
  background-color: lightSteelBlue;
  border-color: lightSteelBlue;
  :hover {
    color: lightSteelBlue;
    background-color: lightSteelBlue;
    border-color: lightSteelBlue;
  }
  :active {
    color: lightSteelBlue;
    background-color: lightSteelBlue;
    border-color: lightSteelBlue;
  }
`;
export const lightYellow = css`
  color: white;
  background-color: lightYellow;
  border-color: lightYellow;
  :hover {
    color: lightYellow;
    background-color: lightYellow;
    border-color: lightYellow;
  }
  :active {
    color: lightYellow;
    background-color: lightYellow;
    border-color: lightYellow;
  }
`;
export const lime = css`
  color: white;
  background-color: lime;
  border-color: lime;
  :hover {
    color: lime;
    background-color: lime;
    border-color: lime;
  }
  :active {
    color: lime;
    background-color: lime;
    border-color: lime;
  }
`;
export const limeGreen = css`
  color: white;
  background-color: limeGreen;
  border-color: limeGreen;
  :hover {
    color: limeGreen;
    background-color: limeGreen;
    border-color: limeGreen;
  }
  :active {
    color: limeGreen;
    background-color: limeGreen;
    border-color: limeGreen;
  }
`;
export const linen = css`
  color: white;
  background-color: linen;
  border-color: linen;
  :hover {
    color: linen;
    background-color: linen;
    border-color: linen;
  }
  :active {
    color: linen;
    background-color: linen;
    border-color: linen;
  }
`;
export const magenta = css`
  color: white;
  background-color: magenta;
  border-color: magenta;
  :hover {
    color: magenta;
    background-color: magenta;
    border-color: magenta;
  }
  :active {
    color: magenta;
    background-color: magenta;
    border-color: magenta;
  }
`;
export const maroon = css`
  color: white;
  background-color: maroon;
  border-color: maroon;
  :hover {
    color: maroon;
    background-color: maroon;
    border-color: maroon;
  }
  :active {
    color: maroon;
    background-color: maroon;
    border-color: maroon;
  }
`;
export const mediumAquaMarine = css`
  color: white;
  background-color: mediumAquaMarine;
  border-color: mediumAquaMarine;
  :hover {
    color: mediumAquaMarine;
    background-color: mediumAquaMarine;
    border-color: mediumAquaMarine;
  }
  :active {
    color: mediumAquaMarine;
    background-color: mediumAquaMarine;
    border-color: mediumAquaMarine;
  }
`;
export const mediumBlue = css`
  color: white;
  background-color: mediumBlue;
  border-color: mediumBlue;
  :hover {
    color: mediumBlue;
    background-color: mediumBlue;
    border-color: mediumBlue;
  }
  :active {
    color: mediumBlue;
    background-color: mediumBlue;
    border-color: mediumBlue;
  }
`;
export const mediumOrchid = css`
  color: white;
  background-color: mediumOrchid;
  border-color: mediumOrchid;
  :hover {
    color: mediumOrchid;
    background-color: mediumOrchid;
    border-color: mediumOrchid;
  }
  :active {
    color: mediumOrchid;
    background-color: mediumOrchid;
    border-color: mediumOrchid;
  }
`;
export const mediumPurple = css`
  color: white;
  background-color: mediumPurple;
  border-color: mediumPurple;
  :hover {
    color: mediumPurple;
    background-color: mediumPurple;
    border-color: mediumPurple;
  }
  :active {
    color: mediumPurple;
    background-color: mediumPurple;
    border-color: mediumPurple;
  }
`;
export const mediumSeaGreen = css`
  color: white;
  background-color: mediumSeaGreen;
  border-color: mediumSeaGreen;
  :hover {
    color: mediumSeaGreen;
    background-color: mediumSeaGreen;
    border-color: mediumSeaGreen;
  }
  :active {
    color: mediumSeaGreen;
    background-color: mediumSeaGreen;
    border-color: mediumSeaGreen;
  }
`;
export const mediumSlateBlue = css`
  color: white;
  background-color: mediumSlateBlue;
  border-color: mediumSlateBlue;
  :hover {
    color: mediumSlateBlue;
    background-color: mediumSlateBlue;
    border-color: mediumSlateBlue;
  }
  :active {
    color: mediumSlateBlue;
    background-color: mediumSlateBlue;
    border-color: mediumSlateBlue;
  }
`;
export const mediumSpringGreen = css`
  color: white;
  background-color: mediumSpringGreen;
  border-color: mediumSpringGreen;
  :hover {
    color: mediumSpringGreen;
    background-color: mediumSpringGreen;
    border-color: mediumSpringGreen;
  }
  :active {
    color: mediumSpringGreen;
    background-color: mediumSpringGreen;
    border-color: mediumSpringGreen;
  }
`;
export const mediumTurquoise = css`
  color: white;
  background-color: mediumTurquoise;
  border-color: mediumTurquoise;
  :hover {
    color: mediumTurquoise;
    background-color: mediumTurquoise;
    border-color: mediumTurquoise;
  }
  :active {
    color: mediumTurquoise;
    background-color: mediumTurquoise;
    border-color: mediumTurquoise;
  }
`;
export const mediumVioletRed = css`
  color: white;
  background-color: mediumVioletRed;
  border-color: mediumVioletRed;
  :hover {
    color: mediumVioletRed;
    background-color: mediumVioletRed;
    border-color: mediumVioletRed;
  }
  :active {
    color: mediumVioletRed;
    background-color: mediumVioletRed;
    border-color: mediumVioletRed;
  }
`;
export const midnightBlue = css`
  color: white;
  background-color: midnightBlue;
  border-color: midnightBlue;
  :hover {
    color: midnightBlue;
    background-color: midnightBlue;
    border-color: midnightBlue;
  }
  :active {
    color: midnightBlue;
    background-color: midnightBlue;
    border-color: midnightBlue;
  }
`;
export const mintCream = css`
  color: white;
  background-color: mintCream;
  border-color: mintCream;
  :hover {
    color: mintCream;
    background-color: mintCream;
    border-color: mintCream;
  }
  :active {
    color: mintCream;
    background-color: mintCream;
    border-color: mintCream;
  }
`;
export const mistyRose = css`
  color: white;
  background-color: mistyRose;
  border-color: mistyRose;
  :hover {
    color: mistyRose;
    background-color: mistyRose;
    border-color: mistyRose;
  }
  :active {
    color: mistyRose;
    background-color: mistyRose;
    border-color: mistyRose;
  }
`;
export const moccasin = css`
  color: white;
  background-color: moccasin;
  border-color: moccasin;
  :hover {
    color: moccasin;
    background-color: moccasin;
    border-color: moccasin;
  }
  :active {
    color: moccasin;
    background-color: moccasin;
    border-color: moccasin;
  }
`;
export const navajoWhite = css`
  color: white;
  background-color: navajoWhite;
  border-color: navajoWhite;
  :hover {
    color: navajoWhite;
    background-color: navajoWhite;
    border-color: navajoWhite;
  }
  :active {
    color: navajoWhite;
    background-color: navajoWhite;
    border-color: navajoWhite;
  }
`;
export const navy = css`
  color: white;
  background-color: navy;
  border-color: navy;
  :hover {
    color: navy;
    background-color: navy;
    border-color: navy;
  }
  :active {
    color: navy;
    background-color: navy;
    border-color: navy;
  }
`;
export const oldLace = css`
  color: white;
  background-color: oldLace;
  border-color: oldLace;
  :hover {
    color: oldLace;
    background-color: oldLace;
    border-color: oldLace;
  }
  :active {
    color: oldLace;
    background-color: oldLace;
    border-color: oldLace;
  }
`;
export const olive = css`
  color: white;
  background-color: olive;
  border-color: olive;
  :hover {
    color: olive;
    background-color: olive;
    border-color: olive;
  }
  :active {
    color: olive;
    background-color: olive;
    border-color: olive;
  }
`;
export const oliveDrab = css`
  color: white;
  background-color: oliveDrab;
  border-color: oliveDrab;
  :hover {
    color: oliveDrab;
    background-color: oliveDrab;
    border-color: oliveDrab;
  }
  :active {
    color: oliveDrab;
    background-color: oliveDrab;
    border-color: oliveDrab;
  }
`;
export const orange = css`
  color: white;
  background-color: orange;
  border-color: orange;
  :hover {
    color: orange;
    background-color: orange;
    border-color: orange;
  }
  :active {
    color: orange;
    background-color: orange;
    border-color: orange;
  }
`;
export const orangeRed = css`
  color: white;
  background-color: orangeRed;
  border-color: orangeRed;
  :hover {
    color: orangeRed;
    background-color: orangeRed;
    border-color: orangeRed;
  }
  :active {
    color: orangeRed;
    background-color: orangeRed;
    border-color: orangeRed;
  }
`;
export const orchid = css`
  color: white;
  background-color: orchid;
  border-color: orchid;
  :hover {
    color: orchid;
    background-color: orchid;
    border-color: orchid;
  }
  :active {
    color: orchid;
    background-color: orchid;
    border-color: orchid;
  }
`;
export const paleGoldenRod = css`
  color: white;
  background-color: paleGoldenRod;
  border-color: paleGoldenRod;
  :hover {
    color: paleGoldenRod;
    background-color: paleGoldenRod;
    border-color: paleGoldenRod;
  }
  :active {
    color: paleGoldenRod;
    background-color: paleGoldenRod;
    border-color: paleGoldenRod;
  }
`;
export const paleGreen = css`
  color: white;
  background-color: paleGreen;
  border-color: paleGreen;
  :hover {
    color: paleGreen;
    background-color: paleGreen;
    border-color: paleGreen;
  }
  :active {
    color: paleGreen;
    background-color: paleGreen;
    border-color: paleGreen;
  }
`;
export const paleTurquoise = css`
  color: white;
  background-color: paleTurquoise;
  border-color: paleTurquoise;
  :hover {
    color: paleTurquoise;
    background-color: paleTurquoise;
    border-color: paleTurquoise;
  }
  :active {
    color: paleTurquoise;
    background-color: paleTurquoise;
    border-color: paleTurquoise;
  }
`;
export const paleVioletRed = css`
  color: white;
  background-color: paleVioletRed;
  border-color: paleVioletRed;
  :hover {
    color: paleVioletRed;
    background-color: paleVioletRed;
    border-color: paleVioletRed;
  }
  :active {
    color: paleVioletRed;
    background-color: paleVioletRed;
    border-color: paleVioletRed;
  }
`;
export const papayaWhip = css`
  color: white;
  background-color: papayaWhip;
  border-color: papayaWhip;
  :hover {
    color: papayaWhip;
    background-color: papayaWhip;
    border-color: papayaWhip;
  }
  :active {
    color: papayaWhip;
    background-color: papayaWhip;
    border-color: papayaWhip;
  }
`;
export const peachPuff = css`
  color: white;
  background-color: peachPuff;
  border-color: peachPuff;
  :hover {
    color: peachPuff;
    background-color: peachPuff;
    border-color: peachPuff;
  }
  :active {
    color: peachPuff;
    background-color: peachPuff;
    border-color: peachPuff;
  }
`;
export const peru = css`
  color: white;
  background-color: peru;
  border-color: peru;
  :hover {
    color: peru;
    background-color: peru;
    border-color: peru;
  }
  :active {
    color: peru;
    background-color: peru;
    border-color: peru;
  }
`;
export const pink = css`
  color: white;
  background-color: pink;
  border-color: pink;
  :hover {
    color: pink;
    background-color: pink;
    border-color: pink;
  }
  :active {
    color: pink;
    background-color: pink;
    border-color: pink;
  }
`;
export const plum = css`
  color: white;
  background-color: plum;
  border-color: plum;
  :hover {
    color: plum;
    background-color: plum;
    border-color: plum;
  }
  :active {
    color: plum;
    background-color: plum;
    border-color: plum;
  }
`;
export const powderBlue = css`
  color: white;
  background-color: powderBlue;
  border-color: powderBlue;
  :hover {
    color: powderBlue;
    background-color: powderBlue;
    border-color: powderBlue;
  }
  :active {
    color: powderBlue;
    background-color: powderBlue;
    border-color: powderBlue;
  }
`;
export const purple = css`
  color: white;
  background-color: purple;
  border-color: purple;
  :hover {
    color: purple;
    background-color: purple;
    border-color: purple;
  }
  :active {
    color: purple;
    background-color: purple;
    border-color: purple;
  }
`;
export const rebeccaPurple = css`
  color: white;
  background-color: rebeccaPurple;
  border-color: rebeccaPurple;
  :hover {
    color: white;
    background-color: #5a2292;
    border-color: #5a2292;
  }
  :active {
    color: white;
    background-color: #5a2292;
    border-color: #5a2292;
  }
`;
export const red = css`
  color: white;
  background-color: red;
  border-color: red;
  :hover {
    color: red;
    background-color: red;
    border-color: red;
  }
  :active {
    color: red;
    background-color: red;
    border-color: red;
  }
`;
export const rosyBrown = css`
  color: white;
  background-color: rosyBrown;
  border-color: rosyBrown;
  :hover {
    color: rosyBrown;
    background-color: rosyBrown;
    border-color: rosyBrown;
  }
  :active {
    color: rosyBrown;
    background-color: rosyBrown;
    border-color: rosyBrown;
  }
`;
export const royalBlue = css`
  color: white;
  background-color: royalBlue;
  border-color: royalBlue;
  :hover {
    color: royalBlue;
    background-color: royalBlue;
    border-color: royalBlue;
  }
  :active {
    color: royalBlue;
    background-color: royalBlue;
    border-color: royalBlue;
  }
`;
export const saddleBrown = css`
  color: white;
  background-color: saddleBrown;
  border-color: saddleBrown;
  :hover {
    color: saddleBrown;
    background-color: saddleBrown;
    border-color: saddleBrown;
  }
  :active {
    color: saddleBrown;
    background-color: saddleBrown;
    border-color: saddleBrown;
  }
`;
export const salmon = css`
  color: white;
  background-color: salmon;
  border-color: salmon;
  :hover {
    color: salmon;
    background-color: salmon;
    border-color: salmon;
  }
  :active {
    color: salmon;
    background-color: salmon;
    border-color: salmon;
  }
`;
export const sandyBrown = css`
  color: white;
  background-color: sandyBrown;
  border-color: sandyBrown;
  :hover {
    color: sandyBrown;
    background-color: sandyBrown;
    border-color: sandyBrown;
  }
  :active {
    color: sandyBrown;
    background-color: sandyBrown;
    border-color: sandyBrown;
  }
`;
export const seaGreen = css`
  color: white;
  background-color: seaGreen;
  border-color: seaGreen;
  :hover {
    color: seaGreen;
    background-color: seaGreen;
    border-color: seaGreen;
  }
  :active {
    color: seaGreen;
    background-color: seaGreen;
    border-color: seaGreen;
  }
`;
export const seaShell = css`
  color: white;
  background-color: seaShell;
  border-color: seaShell;
  :hover {
    color: seaShell;
    background-color: seaShell;
    border-color: seaShell;
  }
  :active {
    color: seaShell;
    background-color: seaShell;
    border-color: seaShell;
  }
`;
export const sienna = css`
  color: white;
  background-color: sienna;
  border-color: sienna;
  :hover {
    color: sienna;
    background-color: sienna;
    border-color: sienna;
  }
  :active {
    color: sienna;
    background-color: sienna;
    border-color: sienna;
  }
`;
export const silver = css`
  color: white;
  background-color: silver;
  border-color: silver;
  :hover {
    color: silver;
    background-color: silver;
    border-color: silver;
  }
  :active {
    color: silver;
    background-color: silver;
    border-color: silver;
  }
`;
export const skyBlue = css`
  color: white;
  background-color: skyBlue;
  border-color: skyBlue;
  :hover {
    color: skyBlue;
    background-color: skyBlue;
    border-color: skyBlue;
  }
  :active {
    color: skyBlue;
    background-color: skyBlue;
    border-color: skyBlue;
  }
`;
export const slateBlue = css`
  color: white;
  background-color: slateBlue;
  border-color: slateBlue;
  :hover {
    color: slateBlue;
    background-color: slateBlue;
    border-color: slateBlue;
  }
  :active {
    color: slateBlue;
    background-color: slateBlue;
    border-color: slateBlue;
  }
`;
export const slateGray = css`
  color: white;
  background-color: slateGray;
  border-color: slateGray;
  :hover {
    color: slateGray;
    background-color: slateGray;
    border-color: slateGray;
  }
  :active {
    color: slateGray;
    background-color: slateGray;
    border-color: slateGray;
  }
`;
export const slateGrey = css`
  color: white;
  background-color: slateGrey;
  border-color: slateGrey;
  :hover {
    color: slateGrey;
    background-color: slateGrey;
    border-color: slateGrey;
  }
  :active {
    color: slateGrey;
    background-color: slateGrey;
    border-color: slateGrey;
  }
`;
export const snow = css`
  color: white;
  background-color: snow;
  border-color: snow;
  :hover {
    color: snow;
    background-color: snow;
    border-color: snow;
  }
  :active {
    color: snow;
    background-color: snow;
    border-color: snow;
  }
`;
export const springGreen = css`
  color: white;
  background-color: springGreen;
  border-color: springGreen;
  :hover {
    color: springGreen;
    background-color: springGreen;
    border-color: springGreen;
  }
  :active {
    color: springGreen;
    background-color: springGreen;
    border-color: springGreen;
  }
`;
export const steelBlue = css`
  color: white;
  background-color: steelBlue;
  border-color: steelBlue;
  :hover {
    color: steelBlue;
    background-color: steelBlue;
    border-color: steelBlue;
  }
  :active {
    color: steelBlue;
    background-color: steelBlue;
    border-color: steelBlue;
  }
`;
export const tan = css`
  color: white;
  background-color: tan;
  border-color: tan;
  :hover {
    color: tan;
    background-color: tan;
    border-color: tan;
  }
  :active {
    color: tan;
    background-color: tan;
    border-color: tan;
  }
`;
export const teal = css`
  color: white;
  background-color: teal;
  border-color: teal;
  :hover {
    color: teal;
    background-color: teal;
    border-color: teal;
  }
  :active {
    color: teal;
    background-color: teal;
    border-color: teal;
  }
`;
export const thistle = css`
  color: white;
  background-color: thistle;
  border-color: thistle;
  :hover {
    color: thistle;
    background-color: thistle;
    border-color: thistle;
  }
  :active {
    color: thistle;
    background-color: thistle;
    border-color: thistle;
  }
`;
export const tomato = css`
  color: white;
  background-color: tomato;
  border-color: tomato;
  :hover {
    color: tomato;
    background-color: tomato;
    border-color: tomato;
  }
  :active {
    color: tomato;
    background-color: tomato;
    border-color: tomato;
  }
`;
export const turquoise = css`
  color: white;
  background-color: turquoise;
  border-color: turquoise;
  :hover {
    color: turquoise;
    background-color: turquoise;
    border-color: turquoise;
  }
  :active {
    color: turquoise;
    background-color: turquoise;
    border-color: turquoise;
  }
`;
export const violet = css`
  color: white;
  background-color: violet;
  border-color: violet;
  :hover {
    color: violet;
    background-color: violet;
    border-color: violet;
  }
  :active {
    color: violet;
    background-color: violet;
    border-color: violet;
  }
`;
export const wheat = css`
  color: white;
  background-color: wheat;
  border-color: wheat;
  :hover {
    color: wheat;
    background-color: wheat;
    border-color: wheat;
  }
  :active {
    color: wheat;
    background-color: wheat;
    border-color: wheat;
  }
`;
export const white = css`
  color: white;
  background-color: white;
  border-color: white;
  :hover {
    color: white;
    background-color: white;
    border-color: white;
  }
  :active {
    color: white;
    background-color: white;
    border-color: white;
  }
`;
export const whiteSmoke = css`
  color: white;
  background-color: whiteSmoke;
  border-color: whiteSmoke;
  :hover {
    color: whiteSmoke;
    background-color: whiteSmoke;
    border-color: whiteSmoke;
  }
  :active {
    color: whiteSmoke;
    background-color: whiteSmoke;
    border-color: whiteSmoke;
  }
`;
export const yellow = css`
  color: white;
  background-color: yellow;
  border-color: yellow;
  :hover {
    color: yellow;
    background-color: yellow;
    border-color: yellow;
  }
  :active {
    color: yellow;
    background-color: yellow;
    border-color: yellow;
  }
`;
export const yellowGreen = css`
  color: white;
  background-color: yellowGreen;
  border-color: yellowGreen;
  :hover {
    color: yellowGreen;
    background-color: yellowGreen;
    border-color: yellowGreen;
  }
  :active {
    color: yellowGreen;
    background-color: yellowGreen;
    border-color: yellowGreen;
  }
`;
