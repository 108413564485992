import React from "react";
import Lottie from "lottie-react";
import loading from "../../animations/loadingAnimation.json";
import styled from "styled-components";

const Loading = ({
  visible = false,
  style,
  showLottie = true,
  fixed = true,
  lottieStyle = false,
}) => {
  if (!visible) return null;
  return (
    <Container className="pt-4 d-flex" customStyle={style}>
      {showLottie && (
        <Animation
          animationData={loading}
          loop={true}
          className="mx-auto mt-4"
          $fixed={fixed}
          $style={lottieStyle}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  z-index: 1090;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  min-height: 20rem;
  background-color: white;
  ${({ customStyle }) => customStyle && customStyle}
`;
const Animation = styled(Lottie)`
  width: 10rem;
  position: ${({ $fixed }) => ($fixed ? "fixed" : "absolute")};
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  ${({ $style }) => $style && $style}
`;
export default Loading;
