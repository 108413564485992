import React from "react";
import styled from "styled-components";

const AppBody = ({ children, ...rest }) => {
  return (
    <Body className="col" {...rest}>
      <main>{children}</main>
    </Body>
  );
};

const Body = styled.div`
  padding: 0;
  z-index: 100;
`;
export default AppBody;
