export const validImage = (image, setError) => {
  if (!image) {
    setError("Something went wrong!");
    return false;
  }

  //multiple extensions
  let imgExtension = image.name.split(".");
  imgExtension = imgExtension[imgExtension.length - 1];

  //allowed file extensions
  const extensions = ["jpg", "png", "svg", "webp", "jpeg", "tiff"];
  if (!extensions.includes(imgExtension.toLowerCase())) {
    setError("This format is not supported :(");
    return false;
  }

  //file size max 5mb
  const imgSize = parseFloat(image.size / 1e6).toFixed(2);

  if (imgSize > 5) {
    setError("Maximum 5MB size is allowed :/");
    return false;
  }
  return true;
};
