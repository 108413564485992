import React from "react";
import styled from "styled-components";
import { device } from "../../config/styles/devicesStyle";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { siteAddress } from "../../config";
import { toast } from "react-toastify";

const ShareButton = ({ id }) => {
  const copyUrl = () => {
    navigator.clipboard.writeText(`${siteAddress}api/arts/preview/${id}`);
    toast.info("Copied to clipboard");
  };
  return (
    <ShareDropDownBox>
      <ShareDropDownBtn
        type="button"
        id={`dropdownMenuButton-${id}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fas fa-share"></i> <span>Share</span>
      </ShareDropDownBtn>
      <ul
        className="dropdown-menu"
        aria-labelledby={`dropdownMenuButton-${id}`}
      >
        <li>
          <span className="dropdown-item" role="button">
            <Icon className="fas fa-share" width={32}></Icon>{" "}
            <span> Share art</span>
          </span>
        </li>
        <hr className="my-1" />
        <li>
          <span
            className="dropdown-item"
            role="button"
            onClick={() => copyUrl()}
          >
            <Icon className="fas fa-link" width={32}></Icon>{" "}
            <span>Copy link</span>
          </span>
        </li>
        <li>
          <span className="dropdown-item">
            <FacebookShareButton url={`${siteAddress}api/arts/preview/${id}`}>
              <FacebookIcon size={32} />
              <span> Facebook</span>
            </FacebookShareButton>
          </span>
        </li>
      </ul>
    </ShareDropDownBox>
  );
};
const ShareDropDownBox = styled.div`
  position: relative;
`;
const ShareDropDownBtn = styled.button`
  background: none;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  margin: 0 !important;

  color: dimgray;
  a {
    color: inherit !important;
    text-decoration: none;
  }
  :hover {
    background-color: #ecf0f4;
    color: SlateGrey;
  }
  i {
    margin: auto;
    margin-right: 2px;
  }
  @media ${device.mobileM} {
    span:last-child {
      display: none;
    }
    padding: 0.4rem 1.2rem;
  }
`;
const Icon = styled.i`
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 6px;
`;

export default ShareButton;
