import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: false,
  edit: false,
  notifications: [],
};

export const userSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.data = action.payload;
    },
    startEdit: (state) => {
      state.edit = true;
    },
    stopEdit: (state) => {
      state.edit = false;
    },
    addNotification: (state, action) => {
      state.notifications = [...state.notifications, action];
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, startEdit, stopEdit, addNotification } =
  userSlice.actions;

export default userSlice.reducer;
