import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import styled, { css } from "styled-components";
import { device } from "../../config/styles/devicesStyle";
import api from "../../config/api";
import http from "../../services/httpService";
import Alert from "../form/alert";
import { updateUser } from "../../features/userSlice";
import { siteAddress } from "../../config";
import { validImage } from "../../libs/image";

const Avatar = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [deleted, setDeleted] = useState();

  const [newAvatar, setAvatar] = useState();

  const onFileChange = ({ currentTarget: input }) => {
    try {
      if (!input.files[0]) return setError("Something went wrong!");

      if (!validImage(input.files[0])) return;

      setDeleted(false);
      setAvatar(URL.createObjectURL(input.files[0]));
      setFile(input.files[0]);
    } catch (error) {
      alert("Oups");
      setError("Something went wrong!");
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (!validImage(file)) return;

      const formData = new FormData();
      formData.append("avatar", file);
      const { data: newImage } = await http.post(api.uploadAvatar, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setDeleted(false);
      setFile(false);
      setError(false);

      dispatch(updateUser({ ...currentUser, avatar: newImage }));
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
      }
    }
  };
  const handleDelete = async (e) => {
    try {
      if (!window.confirm(`Are you sure to delete this avatar?`)) return;
      if (!currentUser.avatar) return setDeleted(true);

      const { data: response } = await http.post(api.deleteAvatar);

      if (response === "OK") {
        setDeleted(true);
        dispatch(updateUser({ ...currentUser, avatar: "" }));
      } else {
        setError("Something went wrong!");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setError("Something went wrong!");
      } else {
        setError("Something went wrong!");
      }
    }
  };

  if (user._id === currentUser._id) {
    return (
      <>
        <Image
          img={user.avatar}
          newAvatar={newAvatar}
          deleted={deleted}
          className="col-auto"
        >
          <AvatarEdit htmlFor="avatar">
            <i className="fas fa-pencil-alt"></i>
          </AvatarEdit>
          {file && (
            <AvatarSubmit type="submit" disabled={!file} onClick={handleSubmit}>
              <i className="fas fa-save"></i>
            </AvatarSubmit>
          )}
          {(currentUser.avatar || newAvatar) && (
            <DeleteButton type="submit" onClick={handleDelete}>
              <i className="fas fa-trash-alt"></i>
            </DeleteButton>
          )}
          {error && <Alert title="Oups" message={error} type="danger" />}
        </Image>

        <input
          type="file"
          id="avatar"
          accept="image/png, image/webp, image/jpeg"
          onChange={onFileChange}
          className="d-none"
        />
      </>
    );
  } else {
    return <Image img={user.avatar} className="col-auto"></Image>;
  }
};

const Image = styled.div`
  position: relative;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  margin-top: -100px;
  background-image: ${'url("' + siteAddress + 'assets/blank-avatar.png");'};
  background-image: ${({ img }) =>
    img && 'url("' + siteAddress + "images/userImages/avatars/" + img + '");'};
  background-image: ${({ newAvatar }) =>
    newAvatar && 'url("' + newAvatar + '")'};
  background-image: ${({ deleted }) => deleted && "none"};
  background-size: cover;
  background-position: center;
  background-color: white;
  .alert {
    position: absolute;
    left: -25px;
    bottom: -20px;
    padding: 2px 40px 2px 10px;
    margin: 0;
    width: max-content;
  }
  .alert button {
    padding: 5px 10px;
  }
  :hover > button,
  :hover > label,
  :focus > button,
  :focus > label {
    display: block;
  }
  @media ${device.mobileM} {
    height: 150px;
    width: 150px;
    margin-top: -75px;
    .alert {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
const baseButton = css`
  display: none;
  width: 40px;
  height: 40px;
  font-size: 18pt;
  border-radius: 50%;
  right: 0;
  bottom: 10%;
  padding: 0;
  background-color: #bfbfbf;
  opacity: 0.7;
  position: absolute;
  text-align: center;
  &:hover {
    opacity: 1;
  }
`;
const AvatarEdit = styled.label`
  ${baseButton}
`;
const AvatarSubmit = styled.button`
  ${baseButton}
  top: 10%;
  border: none;
`;
const DeleteButton = styled.button`
  ${baseButton}
  left: 0;
  right: auto;
  border: none;
  font-size: 15pt;
  color: white;
  background-color: brown;
`;

export default Avatar;
