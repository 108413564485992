import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../../../features/userSlice";
import api from "../../../config/api";
import schemas from "../../../config/schemas";
import http from "../../../services/httpService";
import Form from "../../form/form";
import Loading from "../../common/loading";
import { toast } from "react-toastify";

class bioSetting extends Form {
  state = {
    data: {
      bio: "",
    },
    errors: {},
    loading: false,
  };
  schema = {
    bio: schemas.bio,
  };

  source = http.source();
  unmounting = false;

  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }
  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser.bio && !this.state.emptyData) {
      this.setData({ categories: currentUser.bio });
    }
  }
  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (currentUser !== prevProps.currentUser) {
      if (!this.state.emptyData && currentUser.bio) {
        this.setData({ bio: currentUser.bio });
      }
    }
  }

  setData(d) {
    const emptyData = JSON.parse(JSON.stringify(d));
    const data = JSON.parse(JSON.stringify(d));
    this.setState({ data: data, emptyData });
  }

  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      const bio = this.state.data.bio;

      const data = {
        field: "bio",
        value: bio,
      };
      const { data: user } = await http.put(api.updateUser, data, {
        cancelToken: this.source.token,
      });

      this.props.updateUser(user);
      this.setState({ emptyData: { bio: bio }, loading: false });
    } catch (ex) {
      if (this.unmounting) return;
      if (ex.response && ex.response.status === 400) {
        if (ex.response.data instanceof Object) {
          this.setState({
            errors: { bio: ex.response.data.details[0].message },
            loading: false,
          });
        } else {
          toast.error("Error: " + ex.response.data);
          this.setState({ loading: false });
        }
      }
    }
  };
  render() {
    return (
      <section className="position-relative">
        <Loading
          fixed={false}
          visible={this.state.loading}
          style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
        />
        <form
          onSubmit={this.handleSubmit}
          className="row m-0 justify-content-center mx-auto"
        >
          <h2>Edit Bio</h2>
          <div className="col-12">
            {this.renderTextarea(
              "bio",
              "About",
              true,
              "200px",
              "Write your bio"
            )}
          </div>
          <div className="col-8">
            {this.rendersubmit("Save", this.changed())}
          </div>
        </form>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});
const mapDispatchToProps = { updateUser };
export default connect(mapStateToProps, mapDispatchToProps)(bioSetting);
