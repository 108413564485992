import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ProfileNavitem from "./profileNavitem";

const ProfileNav = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const page = "/" + user.username;
  const ownProfile = currentUser._id === user._id;
  const artist = user.accountType === "artist";
  return (
    <Navbar className="nav m-0">
      {artist ? (
        <>
          <ProfileNavitem
            title="Arts"
            to={page + "/"}
            subtitle={user.arts.length || ""}
          />
          <ProfileNavitem
            title="Posts"
            to={page + "/posts"}
            subtitle={user.posts.length || ""}
          />
        </>
      ) : (
        <ProfileNavitem
          title="Posts"
          to={page + "/"}
          subtitle={user.posts.length || ""}
        />
      )}
      {(ownProfile || user.bio) && (
        <ProfileNavitem title="About" to={page + "/about"} />
      )}
      {artist && (ownProfile || user.collections) && (
        <ProfileNavitem title="Collections" to={page + "/collections"} />
      )}
    </Navbar>
  );
};

const Navbar = styled.nav`
  padding: 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow-x: auto;
  overflow-y: clip;
  padding: 0 0 0.5rem;
  max-width: 100vw;
`;
export default ProfileNav;
