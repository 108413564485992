import styled from "styled-components";
import Badge from "./badge";

const IsApproved = ({ approved, ...rest }) => {
  if (!approved) return "";
  return (
    <Container {...rest}>
      <i className="fas fa-check"></i>
      <span className="visually-hidden">Approved</span>
    </Container>
  );
};

const Container = styled(Badge)`
  ${({ style }) => style && style}
`;
export default IsApproved;
