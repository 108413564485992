import React from "react";

const Page1 = ({ form }) => {
  return (
    <div
      className="row m-0 justify-content-center"
      style={{ minHeight: "500px" }}
    >
      <h2 className="text-center">You are:</h2>
      <div className="col-md-4" style={{ minWidth: "300px" }}>
        {form.renderImageRadio(
          "accountType",
          "artist",
          "An Artist",
          true,
          "artist-radio.png"
        )}
      </div>
      <div className="col-md-4" style={{ minWidth: "300px" }}>
        {form.renderImageRadio(
          "accountType",
          "user",
          "An Art Lover",
          true,
          "artlover-radio.png"
        )}
      </div>
      <div className="col-12 my-1"></div>
      <div className="col-4 col-md-3 col-lg-3"></div>
      <div className="col-4 col-md-3 col-lg-2"></div>
      <div className="col-4 col-md-3 col-lg-3">
        <button
          type="button"
          className="btn btn-purple px-2 py-1 mb-4 float-end"
          disabled={form.validateProperty({
            name: "accountType",
            value: form.state.data.accountType,
          })}
          onClick={() => form.changePage(2)}
        >
          next <i className="fas fa-caret-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Page1;
