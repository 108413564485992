import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Avatar from "../common/Avatar";

const UserInfo = ({ className }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  return (
    <div className={"userInfo text-center text-white px-2 " + className}>
      <div className="container p-0">
        <div className="row m-0">
          <div className="col">{currentUser.ago}</div>
          <div
            className="col-auto cursor-pointer"
            role="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#settingCanvas"
            aria-controls="settingCanvas"
          >
            <Avatar img={currentUser.avatar} borderNone />
          </div>
        </div>
        <div className="row m-0">
          <div className="col text-end">
            <NavLink
              to={"/" + currentUser.username}
              className="text-white text-decoration-none"
            >
              <p className="fw-light m-0">
                @{currentUser && currentUser.username}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
