import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import Form from "../../form/form";
import Page from "../../common/Page";
import AppBody from "../../common/AppBody";
import Loading from "../../common/loading";
import http from "../../../services/httpService";
import api from "../../../config/api";
import Joi from "joi-browser";

import Alert from "../../form/alert";
import PostCardPreview from "../../common/postCardPreview.";
import { difference, siteAddress } from "../../../config";
import { toast } from "react-toastify";

class editArt extends Form {
  state = {
    art: false,
    data: {
      category: "",
      type: "",
      title: "",
      medium: "",
      width: "",
      height: "",
      dimension: "cm",
    },
    images: [],
    errors: {},
    responseError: false,
    loading: true,
  };
  schema = {
    category: Joi.string().required(),
    title: Joi.string().min(3).max(30).required(),
    medium: Joi.string().min(3).max(60).allow(""),
    width: Joi.number().min(0.001).allow(""),
    height: Joi.number().min(0.001).allow(""),
    dimension: Joi.string().valid("cm", "inch"),
  };
  source = http.source();
  unmounting = false;

  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentUser?._id !== prevProps.currentUser?._id)
      this.getArt();
  }
  componentDidMount() {
    if (!this.state.art) this.getArt();
  }
  async getArt() {
    try {
      const artId = this.props.match.params.id;
      const currentUser = this.props.currentUser;
      const { data: art } = await http.get(api.getArt + artId, {
        cancelToken: this.source.token,
      });

      if (currentUser._id !== art.author._id) return (window.location = "/404");

      const data = {
        category: art.category,
        title: art.title,
        medium: art.medium ?? "",
        width: art.width ?? "",
        height: art.height ?? "",
        dimension: art.dimension ?? "cm",
      };

      let images = [...art.pictures];

      images = images.map(
        (image) => `${siteAddress}/images/posts/${artId}/${image}`
      );

      this.setState({ data, images, art, loading: false });
    } catch (ex) {
      return (window.location = "/404");
    }
  }
  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      const dif = difference(this.state.data, this.state.emptyData);
      const data = {
        artId: this.props.match.params.id,
        data: dif,
      };

      await http.put(api.updateArt, data, {
        cancelToken: this.source.token,
      });
      toast.success("Your art has been updated!");
      this.props.history.push("/" + this.props.currentUser.username);
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response.status === 400) {
        this.setState({ responseError: ex.response.data });
        document.getElementById("mainContainer").scrollIntoView();
      }
      console.log(ex.response);
    }
  };

  renderCategories() {
    const user = this.props.currentUser;
    if (!user || !user.categories) return "";
    const options = user.categories.map((category, key) => {
      return (
        <option value={category} key={key + 1}>
          {category}
        </option>
      );
    });
    return options;
  }
  renderPreviewData() {
    const data = { ...this.state.data };
    const user = this.props.currentUser;
    const previewData = {
      _id: "test",
      author: {
        username: user.username,
        avatar: user.avatar,
      },
      createdAt: "Now",
      art: {
        category: data.category,
        title: data.title,
        medium: data.medium,
        width: data.width,
        height: data.height,
        dimension: data.dimension,
        pictures: this.state.images,
      },
    };
    return previewData;
  }
  render() {
    return (
      <Page title="New Post">
        <Helmet></Helmet>
        <AppBody>
          <Loading visible={this.state.loading} />
          {this.state.responseError && (
            <Alert
              title="Error"
              message={this.state.responseError}
              type="danger"
            />
          )}
          <section>
            <div className="container p-0 shadow">
              <form
                onSubmit={this.handleSubmit}
                className="row m-0 px-2 mt-3 justify-content-center mx-auto"
              >
                <h2 className="text-center">Edit art</h2>

                <>
                  <div className="col-md-10">
                    {this.renderFloatingInput("title", "Title", true)}
                  </div>
                  <div className="col-md-6">
                    {this.renderSelect(
                      "category",
                      "Category",
                      true,
                      <>
                        <option value="" disabled>
                          Select Category
                        </option>
                        {this.renderCategories()}
                      </>
                    )}
                  </div>

                  <div className="col-md-4">
                    {this.renderFloatingInput("medium", "Medium")}
                  </div>
                  <div className="col-12"></div>
                  <div className="col-md-3 col-lg-2">
                    {this.renderFloatingInput("width", "Width")}
                  </div>
                  <div className="col-md-3 col-lg-2">
                    {this.renderFloatingInput("height", "Height")}
                  </div>
                  <div className="col-md-3 col-lg-3">
                    {this.renderSelect(
                      "dimension",
                      "Dimension",
                      true,
                      <>
                        <option value="cm">cm</option>
                        <option value="inch">inch</option>
                      </>
                    )}
                  </div>

                  <h4 className="mt-2">Preview:</h4>
                  <hr />

                  <div className="col col-md-8" style={{ maxWidth: "400px" }}>
                    <PostCardPreview
                      post={this.renderPreviewData()}
                      edit={true}
                    />
                  </div>

                  <div className="col-12">
                    {this.rendersubmit("Save", this.changed())}
                  </div>
                </>
              </form>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});

export default connect(mapStateToProps)(editArt);
