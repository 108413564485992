import styled from "styled-components";
import { siteAddress } from "../../config";

const Avatar = styled.div`
  width: ${({ size }) => (size ? size + "px" : "40px")};
  height: ${({ size }) => (size ? size + "px" : "40px")};
  border-radius: 50%;
  display: block;
  border: ${({ borderNone }) => (borderNone ? "none" : "1px solid #dee2e6")};
  background-image: ${'url("' + siteAddress + 'assets/blank-avatar.png");'};
  background-image: ${({ img }) =>
    img && `url("` + siteAddress + `images/userImages/avatars/` + img + `")`};
  background-size: cover;
  background-position: center;
  background-color: #999999;
`;
export default Avatar;
