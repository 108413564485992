import React from "react";
import TopArtistsCard from "../../common/topArtistsCard";

const TopArtists = (props) => {
  return (
    <div className="container">
      <div className="scrolling-wrapper row flex-row flex-md-nowrap flex-wrap mb-2">
        <TopArtistsCard name="paternalpeck" img="user1.jpg" />
        <TopArtistsCard name="lobetrick" img="user2.png" />
        <div className="col-12 d-md-none py-1"></div>
        <TopArtistsCard name="messier81" img="user3.jpg" />
        <TopArtistsCard name="Venesect" img="user4.png" />
        <div className="col-12 d-md-none py-1"></div>
        <TopArtistsCard name="Blubbering" img="user5.jpg" />
        <TopArtistsCard
          name="tendencycurlew sadas asd asdas sa"
          img="user6.jpg"
        />
      </div>
    </div>
  );
};

export default TopArtists;
