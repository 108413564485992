import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { updateUser } from "./features/userSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import home from "./components/pages/home/home";
import aboutUs from "./components/pages/aboutUs";
import register from "./components/pages/register/register";
import getInTouch from "./components/pages/getInTouch";
import http from "./services/httpService";
import auth from "./services/authService";
import login from "./components/pages/login";
import profile from "./components/pages/profile/profile";
import pageNotFound from "./components/pages/pageNotFound";
import styled from "styled-components";
import { device } from "./config/styles/devicesStyle";
import ImageWrapper from "./components/common/ImageWrapper";
import api from "./config/api";
import { initSocket } from "./features/socket/socket";
import newArt from "./components/pages/posts/newArt";
import browser from "./components/pages/browser/browser";
import settings from "./components/pages/settings/settings";
import editArt from "./components/pages/posts/editArt";
import timeline from "./components/pages/timeline/timeline";

class App extends Component {
  source = http.source();
  async componentDidMount() {
    const user = auth.getUser();
    if (this.props.currentUser || !user) return;

    this.props.updateUser(user);
    try {
      const { data: currentUser } = await http.get(api.getCurrentUser, {
        cancelToken: this.source.token,
      });

      if (!currentUser) {
        auth.logout();
        window.location = "/login";
      }

      initSocket();
      this.props.updateUser(currentUser);
    } catch (ex) {
      if (!ex.response) return;
      if (ex.response.status === 404) {
        window.location = "/404";
      } else if (ex.response.status === 401) {
        window.location = "/login";
      }
    }
  }
  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }
  render() {
    return (
      <AppContainer
        className="container-fluid "
        padded={this.props.currentUser && true}
        id="mainContainer"
      >
        <ImageWrapper />

        <ToastContainer position="bottom-right" />
        <Switch>
          <Route path="/about-us" exact component={aboutUs} />

          <Route path="/register" exact component={register} />
          <Route path="/login" exact component={login} />

          <Route path="/get-in-touch" exact component={getInTouch} />
          <Route path="/404" exact component={pageNotFound} />
          <Route path="/new-art" exact component={newArt} />
          <Route path="/edit-art/:id" exact component={editArt} />
          <Route path="/browser/:page?" exact component={browser} />
          <Route path="/settings" exact component={settings} />
          <Route path="/timeline" exact component={timeline} />

          <Route path="/:username/:page?/:art?" exact component={profile} />
          <Route path="/" exact component={home} />
          <Redirect to="/404" />
        </Switch>
      </AppContainer>
    );
  }
}
const AppContainer = styled.div`
  min-height: calc(100vh - 80px) !important;
  max-width: 1920px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${device.mobileL} {
    padding-bottom: ${(props) => (props.padded ? "60px !important" : "0px")};
  }
`;
const mapStateToProps = (state) => ({
  currentUser: state.currentUser.data,
});

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(App);
