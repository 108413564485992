import React from "react";
import auth from "../../services/authService";
import { useSelector } from "react-redux";
import UserInfo from "./userInfo";
import { NavLink } from "react-router-dom";

const SettingsNavigator = (props) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const logout = () => {
    auth.logout();
    window.location = "/";
  };
  return (
    <>
      <UserInfo className="d-none d-lg-flex" />

      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        tabIndex="-1"
        id="settingCanvas"
        aria-labelledby="settingCanvasLabel"
      >
        <div className="offcanvas-header">
          <h5 id="settingCanvastLabel">{currentUser.username}</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item py-3"></li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>
            <li className="list-group-item py-3">An item</li>

            <NavLink to="/settings" className="list-group-item py-3">
              <i className="fas fa-wrench"></i> Settings
            </NavLink>

            <li className="list-group-item py-3" role="button" onClick={logout}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SettingsNavigator;
