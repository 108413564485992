import React from "react";

const Page2 = ({ form }) => {
  const message =
    form.state.data.accountType === "artist"
      ? "You create:"
      : "You are interested in:";
  return (
    <div className="row m-0 justify-content-center mx-auto">
      <h2 className="text-center">{message}</h2>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Painting",
          "Painting",
          true,
          "cat-painting.png"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Drawing",
          "Drawing",
          true,
          "cat-drawing.png"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Sculpture",
          "Sculpture",
          true,
          "cat-scupture.png"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Photography",
          "Photography",
          true,
          "cat-photography.png"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Prints",
          "Prints",
          true,
          "cat-prints.png"
        )}
      </div>
      <div className="w-100 d-none d-lg-block d-xl-block"></div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Digital Art",
          "Digital Art",
          true,
          "cat-digitalart.png"
        )}
      </div>
      <div className="col-4 col-md-3 col-lg-2 p-0">
        {form.renderImageCheckbox(
          "categories",
          "Installation",
          "Installation",
          true,
          "cat-installation.jpg"
        )}
      </div>

      <div className="col-12 my-1"></div>
      <div className="col-4 col-md-3 col-lg-3">
        <button
          type="button"
          className="btn btn-purple px-2 py-1 mb-4"
          onClick={() => form.changePage(1)}
        >
          <i className="fas fa-caret-left"></i> prev
        </button>
      </div>
      <div className="col-4 col-md-3 col-lg-2"></div>
      <div className="col-4 col-md-3 col-lg-3">
        <button
          type="button"
          className="btn btn-purple px-2 py-1 mb-4 float-end"
          disabled={!form.state.data.categories.length}
          onClick={() => form.changePage(3)}
        >
          next <i className="fas fa-caret-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Page2;
