import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const getPrettyDate = (d) => {
  const date = dayjs(d);
  let ago = dayjs().diff(date, "second");
  let text = "";

  if (ago < 1) {
    return `Now`;
  }
  if (ago < 60) {
    text = ago > 1 ? "seconds ago" : "second ago";
    return `${ago} ${text}`;
  }

  ago = parseInt(ago / 60);
  if (ago < 60) {
    text = ago > 1 ? "minutes ago" : "minute ago";
    return `${ago} ${text}`;
  }

  ago = parseInt(ago / 60);
  if (ago < 24) {
    text = ago > 1 ? "hours ago" : "hour ago";
    return `${ago} ${text}`;
  }

  ago = parseInt(ago / 24);
  const time = date.format("HH:mm");
  if (ago < 7) {
    text = ago > 1 ? "days ago" : "day ago";

    return `${ago} ${text} at ${time}`;
  }

  if (date.year() === dayjs().year()) {
    return `${dayjs().format("DD MMM")} at ${time}`;
  }

  return `${dayjs().format("DD MMM YYYY")}`;
};
