import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../config/styles/colors";
import { device } from "../../../config/styles/devicesStyle";

const Navigation = (props) => {
  return (
    <Navbar className="list-group list-group-flush px-2">
      <Navitem to="/browser/popular" className="list-group-item" exact={true}>
        Popular
      </Navitem>
      <Navitem to="/browser/artists" className="list-group-item" exact={true}>
        Artists
      </Navitem>
      <Navitem to="/browser/users" className="list-group-item" exact={true}>
        Users
      </Navitem>
      <Navitem to="/browser/arts" className="list-group-item" exact={true}>
        Arts
      </Navitem>
      <Navitem to="/browser/posts" className="list-group-item" exact={true}>
        Posts
      </Navitem>
    </Navbar>
  );
};

const Navbar = styled.ul`
  @media ${device.mobileM} {
    display: flex;
    flex-direction: row;
    overflow-x: auto;

    margin-top: 0.4rem;
  }
`;

const Navitem = styled(NavLink)`
  @media ${device.mobileM} {
    border: none !important;
  }
  text-transform: uppercase;
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${colors.purple};
  }
  &.list-group-item.active {
    background-color: initial !important;
    color: blue;
    border-color: rgba(0, 0, 0, 0.125) !important;
    border-top: none;
    margin-top: 0;
  }
`;
export default Navigation;
