import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import styled from "styled-components";
import api from "../../../config/api";
import http from "../../../services/httpService";
import loadingAnim from "../../../animations/loadingAnimation.json";
import { NavLink } from "react-router-dom";
import Avatar from "../../common/Avatar";
import Badge from "../../common/badge";
import IsApproved from "../../common/IsApproved";
import { css } from "styled-components";
import Loading from "../../common/loading";
const Users = ({ search, type }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const source = http.source();
  useEffect(() => {
    getUsers();
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const getUsers = async () => {
    try {
      if (!loading) setLoading(true);
      const { data: users } = await http.get(api.getUser, {
        cancelToken: source.token,
      });

      setUsers(users);
      setLoading(false);
    } catch (ex) {
      if (loading) setLoading(false);
    }
  };
  const displayResult = () => {
    let currentUsers = [...users];

    if (type === "users")
      currentUsers = currentUsers.filter((user) => user.accountType === "user");
    if (type === "artists")
      currentUsers = currentUsers.filter(
        (user) => user.accountType === "artist"
      );

    if (search && search !== "")
      currentUsers = currentUsers.filter((user) =>
        user.username.toLowerCase().startsWith(search.toLowerCase().trim())
      );

    if (currentUsers.length > 0)
      return currentUsers.slice(0, 10).map((user, key) => {
        return (
          <li className="list-group-item" key={key}>
            <ListItem>
              <Row>
                <Avatar img={user.avatar} alt={user.username} />

                <NavLink to={"/" + user.username}>{user.username}</NavLink>

                <IsApproved
                  bgColor="lightBlue"
                  title="Approved user"
                  approved={user.isApproved}
                  style={IsApprovedStyle}
                />
              </Row>
              <Row>
                <Badge type={user.accountType} popular={true} />

                <Badge type="vip" />
                <Badge type="supporter" />
              </Row>
            </ListItem>
          </li>
        );
      });
    return "No user found";
  };
  if (loading)
    return (
      <Loading
        style={InProgress}
        visible={true}
        fixed={false}
        lottieStyle={LottieStyle}
      />
    );

  return (
    <div className="row m-0 position-relative">
      <div className="col-12 p-0">
        <ul className="list-group list-group-flush">{displayResult()}</ul>
      </div>
    </div>
  );
};

const InProgress = css`
  background-color: #ffffff98 !important;
  min-height: auto !important;
`;
const LottieStyle = css`
  top: 0 !important;
  height: 100% !important;
  width: auto !important;
  margin-top: 0 !important;
`;
const IsApprovedStyle = css`
  padding: 0.2em 0.4em !important;
  font-size: 8pt !important;
`;
const ListItem = styled.div`
  display: flex;
  font-size: 16pt;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  &:nth-child(2) {
    font-size: 10pt;
    flex-wrap: wrap;
    & > .badge {
      margin-top: 0.4rem;
    }
  }
`;
export default Users;
