import api from "../config/api";
import httpService from "../services/httpService";

export const likeFunction = async (user, item, type) => {
  const currentUser = user;

  if (!currentUser || !item) return;
  if (currentUser._id === item.author._id) return;

  let response = false;
  let updatedItem = { ...item };

  if (type === "art") {
    response = await httpService.post(api.likeArt, { id: item._id });

    //add userId to the list of likes
    if (response.data === 1) {
      updatedItem.likes.push(currentUser._id);
    }
    // remove userId from list o likes
    else if (response.data === -1) {
      updatedItem.likes = updatedItem.likes.filter(
        (userId) => userId !== currentUser._id
      );
    }

    return updatedItem;
  }
};
