import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import AppBody from "../../common/AppBody";
import Page from "../../common/Page";
import TopArtists from "./topArtists";
import TopArts from "./topArts";

class home extends Component {
  render() {
    return (
      <Page title="The online art network">
        <Helmet>
          <meta
            name="description"
            content="Based in London we are a long established company with a broad spectrum of work ranging from industrial factory work to bespoke art gallery lighting, we provide all of your Electrical, Fire, Security and Gas needs. Our services are provided by professionally accredited engineers that are fully qualified and insured. We provide PROFESSIONAL, SAFE, and EFFICIENT service and always complete works ON-TIME and ON-BUDGET."
          />
          <meta name="title" content="Reid - Property Maintenence in London" />
          <meta
            property="og:title"
            content="Reid - Property Maintenence in London"
          />
          <meta
            property="og:description"
            content="Based in London we are a long established company with a broad spectrum of work ranging from industrial factory work to bespoke art gallery lighting, we provide all of your Electrical, Fire, Security and Gas needs. Our services are provided by professionally accredited engineers that are fully qualified and insured. We provide PROFESSIONAL, SAFE, and EFFICIENT service and always complete works ON-TIME and ON-BUDGET."
          />
          <meta
            name="keywords"
            content="electrical, electrician, fire alarm, gas, maintenence, air conditioning, plumbing, boiler repair, fire risk assessment london"
          />
          <meta property="og:url" content="https://reid.zaiintech.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Zaiintech"></meta>
          <link rel="canonical" href="https://reid.zaiintech.com/" />
        </Helmet>
        <AppBody>
          <section>
            <div className="row m-0 text-center w-100">
              <TopArts />
            </div>
          </section>
          <section>
            <div className="row m-0 bg3 mb-2 shadow-hard">
              <div
                className="col-lg-6 d-block d-lg-none"
                style={{ minHeight: "290px" }}
              ></div>
              <div
                className="col-lg-6 py-4 text-center"
                style={{ backgroundColor: "#f7f7f7" }}
              >
                <h1 className="display-3 text-gold">
                  Artisle - The online art network
                </h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  The idea behind Artisle is simple but powerful – to create a
                  social media platform specifically for artists. This will
                  include features to build a professional portfolio, share
                  their work, sell and browse art from other artists. It will be
                  the ultimate destination for anyone who loves art, wants to
                  learn more about it, or wants to purchase original pieces. We
                  believe that everyone has the potential to be an artist, and
                  Artisle will give them the tools and support they need to
                  bring their talent to life and be known as an artist.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="row m-0 my-2 shadow-hard"
              style={{ position: "relative", backgroundColor: "white" }}
            >
              <div className="col-12">
                <h2 className="display-3 text-gold text-center">Top artists</h2>
              </div>
              <div className="col order-md-1 order-2 bg4 mt-auto"></div>
              <div className="col-12 order-md-1 col-md-9 text-center d-flex align-items-center">
                <TopArtists />
              </div>
            </div>
          </section>
        </AppBody>
      </Page>
    );
  }
}

export default home;
