import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AppButton from "../common/AppButton";
import { device } from "../../config/styles/devicesStyle";

const MessageButton = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser.data);

  if (!user || !user.username) return null;
  if (user.username === currentUser.username) return null;
  return <Button color="rebeccaPurple" title="message" disabled />;
};
const Button = styled(AppButton)`
  @media ${device.mobileS} {
    margin: auto;
    padding: 0.2rem 0.5rem !important;
    font-size: 1rem !important;
  }
`;
export default MessageButton;
