import React, { Component } from "react";
import { Fragment } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { chunkArray } from "../../config";
import Loading from "./loading";

class infiniteReverseScroll extends Component {
  state = {
    productsChunks: [],
    currentChunk: 0,
    content: null,
    loading: false,
  };

  unMounting = false;
  componentDidMount() {
    const id = this.props.id;
    if (!id) return;
    const wrappedElement = document.getElementById(id);
    wrappedElement.addEventListener("scroll", this.trackScrolling);
    this.setProductsChunks();
  }

  componentWillUnmount() {
    this.unMounting = true;
    const id = this.props.id;
    if (!id) return;
    const wrappedElement = document.getElementById(id);
    wrappedElement.removeEventListener("scroll", this.trackScrolling);
  }

  componentDidUpdate(prevProps) {
    if (this.unMounting) return;
    const { items } = this.props;
    if (items) {
      if (JSON.stringify(prevProps.items) !== JSON.stringify(items)) {
        this.setProductsChunks();
      }
    }
  }

  setProductsChunks() {
    if (this.unMounting) return;
    const currentItems = [...this.props.items];
    const chunkLength = this.props.chunkLength || 20;
    const productsChunks = chunkArray(currentItems, chunkLength);
    this.setState(
      { productsChunks: productsChunks, currentChunk: 0, content: null },
      () => this.getContent()
    );
  }
  isTop(el) {
    const top = el.scrollHeight - el.clientHeight + el.scrollTop;
    return top <= 0;
  }

  trackScrolling = () => {
    const id = this.props.id;
    if (!id) return;
    const wrappedElement = document.getElementById(id);
    if (this.isTop(wrappedElement)) {
      wrappedElement.removeEventListener("scroll", this.trackScrolling);
      this.loadNextChunk();
    }
  };

  loadNextChunk() {
    if (this.state.loading) return;

    let currentChunk = this.state.currentChunk;

    if (currentChunk >= this.state.productsChunks.length - 1) {
      const end = <Fragment key={0}>{this.props.end()}</Fragment>;

      const content = this.state.content ? [...this.state.content] : [];
      content.push(end);
      this.setState({ content: content });
      return;
    }

    currentChunk++;
    this.setState({ loading: true, currentChunk: currentChunk });
    this.getContent();
  }

  getContent() {
    const { productsChunks } = this.state;
    let chunk = this.state.currentChunk;
    const id = this.props.id;
    if (!id) return;
    const wrappedElement = document.getElementById(id);
    if (productsChunks.length < 1) {
      wrappedElement.removeEventListener("scroll", this.trackScrolling);

      return null;
    }

    let content = "";
    content = productsChunks[chunk].map((product, key) => {
      return this.props.display(product, key);
    });

    if (this.state.content && this.state.content.length > 0 && chunk > 0) {
      content = [...this.state.content, ...content];
    }
    this.setState({ content: content, loading: false });

    wrappedElement.addEventListener("scroll", this.trackScrolling);
  }

  render() {
    const parent = this.props.parent ? this.props.parent : false;
    const content = this.state.content;

    if (parent) {
      return parent(content);
    }
    return (
      <div
        id="infiniteReverseScroll"
        className="row m-0 justify-content-center p-0"
      >
        {this.state.content}
        {this.state.loading && (
          <LoadingContainer className="shadow col-8">
            <p>Loading</p>
            <Loading
              visible={true}
              fixed={false}
              style={LoadingStyle}
              lottieStyle={LottieStyle}
            />
          </LoadingContainer>
        )}
      </div>
    );
  }
}

const LoadingContainer = styled.div`
  position: relative;
  height: 40px;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  p {
    text-align: center;
    line-height: 40px;
  }
`;
const LoadingStyle = css`
  min-height: auto !important;
  background-color: transparent;
`;
const LottieStyle = css`
  top: 0 !important;
  height: 100% !important;
  width: auto !important;
  margin-top: 0 !important;
`;
export default infiniteReverseScroll;
