import React from "react";
import NavItem from "./navItem";
import SettingsNavigator from "./settingsNavigation";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../../config/styles/devicesStyle";

const UserNavigator = () => {
  const notifications =
    useSelector((state) => state.currentUser.notifications.length) || 0;
  const classList =
    "flex-grow-1 flex-lg-grow-0 px-3 text-center navLink mx-md-2";
  return (
    <NavContainer className={"px-md-4"}>
      <NavItem className={classList} to="/timeline" exact={true}>
        <i className="fas fa-stream"></i>
        <div className="d-none d-md-flex menuLabel">
          <span className="mx-auto">Posts</span>
        </div>
      </NavItem>
      <NavItem className={classList} to="/browser">
        <i className="fas fa-search"></i>
        <div className="d-none d-md-flex menuLabel">
          <span className="mx-auto">Browser</span>
        </div>
      </NavItem>

      <NavItem className={classList} to="/d">
        <i className="fas fa-comments"></i>
        <div className="d-none d-md-flex menuLabel">
          <span className="mx-auto">Chats</span>
        </div>
      </NavItem>
      <NavItem className={classList} to="/d">
        <i className="fas fa-bell position-relative">
          {notifications > 0 && (
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {notifications}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </i>
        <div className="d-none d-md-flex menuLabel">
          <span className="mx-auto">Notifications</span>
        </div>
      </NavItem>

      <SettingsNavigator />
    </NavContainer>
  );
};
const NavContainer = styled.div`
  width: auto;
  display: flex;
  margin: 0 auto;
  padding-right: 225px !important;
  z-index: 1060;
  bottom: 0;
  left: 0;
  justify-content: center !important;
  @media ${device.tablet} {
    padding-right: 200px !important;
  }
  @media ${device.mobileL} {
    position: fixed;
    padding: 0 1.5rem !important;
    bottom: 0;
    background-color: rgb(45, 45, 45);
    right: 0;
    .nav-item {
      height: 60px;
      line-height: 60px;
    }
  }
`;
export default UserNavigator;
