import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { siteAddress } from "../../../config";
import { device } from "../../../config/styles/devicesStyle";
import ImageView from "../../common/imageView";

const TopArts = (props) => {
  return (
    <div className="row m-0 text-center w-100 p-0">
      <LeftContent className="col">
        <div className="row m-0 mt-5 bannerL-1">
          <LContentItem className="col-6">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-2.jpg"}
                alt="img"
              />
            </ImageView>
          </LContentItem>
          <LContentItem className="col-6">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-3.jpg"}
                alt="img"
              />
            </ImageView>
          </LContentItem>
        </div>
        <div className="row m-0 mt-5 bannerL-2">
          <LContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-4.jpg"}
                alt="img"
              />
            </ImageView>
          </LContentItem>
          <LContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-5.png"}
                alt="img"
              />
            </ImageView>
          </LContentItem>
          <LContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-6.jpg"}
                alt="img"
              />
            </ImageView>
          </LContentItem>
        </div>
      </LeftContent>
      <RightContent className="col">
        <div className="row m-0 mt-2">
          <div className="col-12 mb-4">
            <h3 className="display-4 mb-4 text-gold">Most popular arts</h3>
            <ImageView className="mx-auto">
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-1.png"}
                alt="img"
              />
            </ImageView>
          </div>
          <RContentItem className="col-6">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-2.jpg"}
                alt="img"
              />
            </ImageView>
          </RContentItem>
          <RContentItem className="col-6">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-3.jpg"}
                alt="img"
              />
            </ImageView>
          </RContentItem>
          <RContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-4.jpg"}
                alt="img"
              />
            </ImageView>
          </RContentItem>
          <RContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-5.png"}
                alt="img"
              />
            </ImageView>
          </RContentItem>
          <RContentItem className="col-4">
            <ImageView>
              <img
                className="img-fluid shadow-hard"
                src={siteAddress + "assets/sample-6.jpg"}
                alt="img"
              />
            </ImageView>
          </RContentItem>
        </div>
      </RightContent>
    </div>
  );
};
const Content = css`
  min-height: 730px;
  padding: 0;
  background-size: cover;
  background-position-x: left;
`;
const RightContent = styled.div`
  ${Content}
  background-image: ${'url("' + siteAddress + 'assets/banner-right.jpg");'};
`;
const LeftContent = styled.div`
  ${Content}
  background-position-x: right;
  background-image: ${'url("' + siteAddress + 'assets/banner-left.jpg");'};
  @media ${device.mobileM} {
    display: none;
  }
`;
const RContentItem = styled.div`
  display: none;
  margin-bottom: 1.5rem !important;
  @media ${device.mobileM} {
    display: flex;
  }
`;
const LContentItem = styled.div``;

export default TopArts;
