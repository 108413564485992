import React, { Component } from "react";
import AppBody from "../../common/AppBody";
import Loading from "../../common/loading";
import Page from "../../common/Page";
import InfiniteScroll from "../../common/infiniteScroll";
import PostCard from "../../common/postCard";
import http from "../../../services/httpService";
import api from "../../../config/api";
import { NavLink } from "react-router-dom";
import TopArtists from "../home/topArtists";

class timeline extends Component {
  state = {
    loading: true,
    posts: [],
  };
  source = http.source();
  unmounting = false;

  async getPosts() {
    try {
      const { data: posts } = await http.get(api.getTimelinePosts, {
        cancelToken: this.source.token,
      });
      this.setState({
        loading: false,
        posts: posts,
      });
    } catch (error) {}
  }

  async componentDidMount() {
    this.getPosts();
  }
  componentWillUnmount() {
    this.unmounting = true;
    this.source.cancel();
  }

  render() {
    const { posts, loading } = this.state;
    return (
      <Page title="Timeline">
        <AppBody>
          <Loading
            visible={loading}
            style={{ backgroundColor: "rgba(255,255,255,0)" }}
          />
          <div className="container p-0 shadow">
            <section>
              <div className="row m-0 justify-content-center">
                {!loading && posts.length < 1 && (
                  <div className="col-12 px-2 py-5">
                    <h3 className="text-center">
                      Follow some{" "}
                      <NavLink to="/browser/artists">artists</NavLink> to see
                      their posts
                    </h3>
                    <div
                      className="row m-0 my-2 text-center"
                      style={{ position: "relative", backgroundColor: "white" }}
                    >
                      <div className="col-12">
                        <h2 className="display-3 text-gold text-center">
                          Top artists
                        </h2>
                      </div>
                      <TopArtists />
                    </div>
                  </div>
                )}
                <div className="col-md-8 col-12">
                  <InfiniteScroll
                    items={posts}
                    chunkLength={9}
                    display={(post) => {
                      return <PostCard key={post} postId={post} />;
                    }}
                    end={() => {
                      return (
                        <div className="col-12 order-3">
                          <h5 className="text-center text-muted">
                            No more posts
                          </h5>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </AppBody>
      </Page>
    );
  }
}

export default timeline;
